import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const AutoLoadImage = ({ apiURL }) => {
  const [image, setImage] = useState(null);
  const filename = 'IBXWd0FYoYZVNwBHjXqy1jnywQi1Qv27nxTVVK9xeXR4hHkm'; // Cambia esto por el nombre que deseas verificar

  useEffect(() => {
    const fetchImage = async () => {
      const jwt = Cookies.get('jwt');
      try {
        const response = await axios.post(apiURL, {
            type: '/scanner/image/check',
            filename: filename
        }, {
            headers: { 
              Authorization: `Bearer ${jwt}`,
              'Content-Type': 'multipart/form-data'
            },
          });
        if (response.data.status === 'success') {
          const imageBase64 = response.data.image;
          const imageUrl = `data:image/jpeg;base64,${imageBase64}`;
          setImage(imageUrl); // Guardar la imagen para mostrarla
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
      }
    };

    fetchImage();
  }, [apiURL]);

  return (
    <div>
      <form>
        <input
          type="file"
          accept="image/*"
          capture="environment"
          onChange={(e) => setImage(URL.createObjectURL(e.target.files[0]))}
          style={{ display: 'none' }} // Ocultarlo si quieres solo carga automática
        />
        {image && <img src={image} alt="Uploaded" />}
      </form>
    </div>
  );
};

export default AutoLoadImage;
