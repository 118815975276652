import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import QRCode from 'qrcode';
import { format, parse } from 'date-fns';
import { es } from 'date-fns/locale';
import JsBarcode from 'jsbarcode';
import FormattedDate from '../../components/FormattedDate';

// Función para generar el código de barras como una URL de datos base64
const generateBarcodeDataURL = (value) => {
   try {
      const canvas = document.createElement('canvas');
      JsBarcode(canvas, value, { format: 'CODE128' });

      return canvas.toDataURL();  // Devuelve la URL base64
   } catch (error) {
      console.error('Error generating barcode:', error);
   }
};

// Registro de fuentes personalizadas
Font.register({
   family: 'Roboto',
   src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf'
});

// Imagen de fondo (logo de la empresa)
const companyLogo = '/static/img/logo_icon.png';

// Estilos para el PDF
const styles = StyleSheet.create({
   page: { padding: 30, flexDirection: 'column', position: 'relative' },
   backgroundImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
      opacity: 0.1,
   },
   receiptContainer: { padding: 5, marginBottom: 3, border: '1px solid #000' },
   header: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 0 },
   titleContainer: { textAlign: 'right' },
   title: { fontSize: 24, color: '#007bff', fontWeight: 'bold' },
   subtitle: { fontSize: 10, color: '#007bff' },
   row: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 },
   column: { width: '30%' },
   label: { fontSize: 10, fontWeight: 'bold', color: '#007bff' },
   text: { fontSize: 10, fontWeight: 'bold', marginBottom: 4 },
   textBlue: { color: '#007bff', fontWeight: 'bold', fontSize: 10 },
   line: { borderBottom: '1px solid #000', marginVertical: 10 },
   signatureContainer: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 },
   signatureText: { fontSize: 12, marginRight: 10 },
   qrImage: { width: 64, height: 64 },
   barCode: { width: 64, height: 22 },
});

// Función para generar el código QR como una URL de datos base64
const generateQRCodeDataURL = async (value) => {
   try {
      return await QRCode.toDataURL(value, { width: 64 });
   } catch (error) {
      console.error("Error generating QR code:", error);
   }
};

// Componente para un recibo individual
const Receipt = ({ data }) => {
   const [qrCodeURL, setQRCodeURL] = useState(null);
   const combustible = data.combustible.split('#');
   const total = data.costo.split('#');

   const [barcodeURL, setBarcodeURL] = useState(null);

   useEffect(() => {
      const url = generateBarcodeDataURL(data.id);
      setBarcodeURL(url);
   }, []);

   const parsedDate = parse(data.since, 'yyyy-MM-dd HH:mm:ss', new Date());

   // Formatea el mes y día de la semana según las reglas especificadas
   const dayOfWeek = format(parsedDate, 'EEEE', { locale: es });
   const formattedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1).toLowerCase();

   const month = format(parsedDate, 'MMMM', { locale: es });
   const formattedMonth = (month === 'enero') ? 'enero' : month.slice(0, 3).charAt(0).toUpperCase() + month.slice(1, 3);

   const dateSince = `${formattedDayOfWeek} ${format(parsedDate, 'd')} de ${formattedMonth}, ${format(parsedDate, 'yyyy')}`;

   useEffect(() => {
      // Generar el código QR
      generateQRCodeDataURL(`https://munity.devbybit.com/combustible/${data.id}`).then((url) => {
         setQRCodeURL(url);
      });
   }, []);

   return (
      <View style={styles.receiptContainer}>
         <Image src={companyLogo} style={styles.backgroundImage} />

         <View style={styles.header}>
            <Image src={companyLogo} style={{ width: 64, height: 64 }} />
            <View style={styles.titleContainer}>
               <Text style={styles.title}>ORDEN DE COMBUSTIBLE</Text>
               <Text style={styles.subtitle}>MUNICIPALIDAD GENERAL OCAMPO - GENERALOCAMPO.COM.AR</Text>
            </View>
            {qrCodeURL && <Image src={qrCodeURL} style={styles.qrImage} />}
         </View>

         <View style={styles.row}>
            <View style={styles.column}>
               <Text>
                  <Text style={styles.textBlue}>Ciudadano: </Text>
                  <Text style={styles.text}>{data.nombre}</Text>
               </Text>
               <Text>
                  <Text style={styles.textBlue}>DNI: </Text>
                  <Text style={styles.text}>{data.dni}</Text>
               </Text>
               <Text>
                  <Text style={styles.textBlue}>Total: </Text>
                  <Text style={styles.text}>${total[0]}</Text>
               </Text>
            </View>
            <View style={styles.column}>
               <Text>
                  <Text style={styles.textBlue}>Combustible: </Text>
                  <Text style={styles.text}>{combustible[0]} ({combustible[1]} Lts)</Text>
               </Text>
               <Text>
                  <Text style={styles.textBlue}>Estacion: </Text>
                  <Text style={styles.text}>{data.estacion}</Text>
               </Text>
            </View>
            <View style={styles.column}>
               <Text>
                  <Text style={styles.textBlue}>ID: </Text>
                  <Text style={styles.text}>{data.id}</Text>
               </Text>
               <Text>
                  <Text style={styles.textBlue}>Fecha: </Text>
                  <Text style={styles.text}>{dateSince}</Text>
               </Text>
               <Text>
                  <Text style={styles.textBlue}>Vencimiento: </Text>
                  <Text style={styles.text}>{data.vencimiento ? data.vencimiento : '--'}</Text>
               </Text>
            </View>
         </View>

         <View style={styles.row}>
            <Text style={styles.signatureText}>FIRMA</Text>
            <Text style={styles.textBlue}>MUNITY.COM.AR</Text>
            {barcodeURL && <Image src={barcodeURL} style={styles.barCode} />}
         </View>
      </View>
   );
};

// Documento PDF
const MyDocument = ({ receipts }) => {
   const pages = [];
   for (let i = 0; i < receipts.length; i += 5) {
      pages.push(receipts.slice(i, i + 5));
   }

   return (
      <Document>
         {pages.map((pageReceipts, pageIndex) => (
            <Page key={pageIndex} style={styles.page}>
               {pageReceipts.map((receipt, index) => (
                  <Receipt key={index} data={receipt} />
               ))}
            </Page>
         ))}
      </Document>
   );
};

const App = ({ config, apiURL }) => {
   const [data, setData] = useState([]);
   const [isLoader, setIsLoader] = useState(true);

   useEffect(() => {
      const listed = JSON.parse(localStorage.getItem('combustible_listed'));
      if (listed) {
         setData(listed);
         setIsLoader(false);
      }
   }, []);

   if (isLoader) {
      return (
         <div className="container container-slim py-4">
            <div className="mb-3">
               <Link to={`${config.uri}`} className="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
            </div>
            <div className="text-center">
               <div className="text-secondary mb-3">Cargando pdf...</div>
               <div className="progress progress-sm">
                  <div className="progress-bar progress-bar-indeterminate"></div>
               </div>
            </div>
         </div>
      );
   }

   return (
      <div className="mt-5">
         <PDFViewer style={{ width: '100%', height: '90vh' }}>
            <MyDocument receipts={data} />
         </PDFViewer>
      </div>
   );
};

export default App;
