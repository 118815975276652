import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { UserContext } from '../components/UserProvider.js';
import { IconUserCircle } from '@tabler/icons-react';
import '@tabler/core/dist/js/tabler.min.js';

const Content = ({ config, apiURL }) => {
  const { userLogged, logoutUser, roles } = useContext(UserContext);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState('profile');
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeTabs = (e, tab) => {
    e.preventDefault();
    setTab(tab);
  };

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    if (isOpen) {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    }
  }, [isOpen, dropdownRef]);

  const lowestPositionRole = Array.isArray(roles) && roles.length > 0 
  ? roles.reduce((lowest, role) => (role.position < lowest.position ? role : lowest))
  : { color: 'default', icon: '', name: 'No role available' }; // Valor predeterminado


  return (
        
        <div className={`profile-details dropdown-dbb ${isOpen ? 'show' : ''}`} ref={dropdownRef}>
          <a href="#" onClick={() => setIsOpen(prevState => !prevState)} className="profile-content">
    <img
      src={`${config.uri}${userLogged.avatar}`}
      alt="profileImg"
      style={{ width: '50px', borderRadius: '50%' }}
    />
    <div className="profile-info">
      <div className="profile_name">
      <span className={`text-${lowestPositionRole.color} me-1`}
          dangerouslySetInnerHTML={{ __html: lowestPositionRole.icon }} // Renderiza el icono como HTML
        />
        {userLogged.username}
      </div>
      <div className="job">
        {lowestPositionRole.name}
      </div>
    </div>
  </a>
          
          {isOpen && (<>
  <div className={`dropdown-menu-dbb ${isOpen ? "show" : ""}`}>
    <div className="row g-2 align-items-center">
      <div className="col-auto dropdown-sidebar">
        <div className="dropdown-sidebar-top hidden">
          <a href="/settings" className={`dropdown-sidebar-item ${tab == 'settings' && "active"}`} onClick={(event) => changeTabs(event, 'settings')}>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-settings"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" /><path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" /></svg>
            <span>Settings</span>
          </a>
          <a href="/notifications" className={`dropdown-sidebar-item ${tab == 'notifications' && "active"}`} onClick={(event) => changeTabs(event, 'notifications')}>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-bell"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
            <span>Notifications</span>
          </a>
          <a href="/coins" className={`dropdown-sidebar-item ${tab == 'coins' && "active"}`} onClick={(event) => changeTabs(event, 'coins')}>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-coins"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z" /><path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4" /><path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z" /><path d="M3 6v10c0 .888 .772 1.45 2 2" /><path d="M3 11c0 .888 .772 1.45 2 2" /></svg>
            <span>Coins</span>
          </a>
          <a href="/switch-account" className={`dropdown-sidebar-item ${tab == 'switch-account' && "active"}`} onClick={(event) => changeTabs(event, 'switch-account')}>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-switch-horizontal"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M16 3l4 4l-4 4" /><path d="M10 7l10 0" /><path d="M8 13l-4 4l4 4" /><path d="M4 17l9 0" /></svg>
            <span>Switch Account</span>
          </a>
        </div>

        <div className="dropdown-sidebar-bottom">
          <a href="/logout" className={`dropdown-sidebar-item ${tab == 'profile' && "active"}`} onClick={(event) => changeTabs(event, 'profile')}>
            <IconUserCircle stroke={1} />
            <span>Perfil</span>
          </a>
        </div>
      </div>

      <div className={`col dropdown-content`}>

        {/* PROFILE TAB */}
        <div className={`${tab == 'profile' ? "" : "hidden"}`}>
          <div className="row align-items-center mb-3">
            <div className="col-auto">
                <span className="avatar avatar-md" style={{backgroundImage: `url(${config.uri}${userLogged.avatar}`}} />
            </div>
            <div className="col font-20">
                {userLogged.name}<br />
            </div>
            <div className="col-auto">
                <a href="#" className="btn btn-icon">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-pencil"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" /><path d="M13.5 6.5l4 4" /></svg>
                </a>
            </div>
          </div>

          <p className="text-secondary font-14 mb-0">ID.</p>
          <p className="font-16">{userLogged.id}</p>

          <p className="text-secondary font-14 mb-0">Miembro desde.</p>
          <p className="font-16">{userLogged.since}</p>
            
          <p className="text-secondary font-14 mb-0">Roles.</p>
          <p className="font-16">
          {Array.isArray(roles) && roles
  .sort((a, b) => parseInt(a.position, 10) - parseInt(b.position, 10))
  .map((item, index) => (
    <span className={`badge bg-${item.color}-lt me-1 mb-1`} key={index}>
      <span className={`text-${item.color} me-1 font-8`}
          dangerouslySetInnerHTML={{ __html: item.icon }} // Renderiza el icono como HTML
        />
      <span className="ms-1">{item.name}</span>
    </span>
  ))}
          </p>

          <hr />

          {/*<div class="three-columns-grid">
            <div>+99</div>
            <div>12</div>
            <div>+99</div>
          </div>*/}

          <button className='btn w-100 mt-3' onClick={() => logoutUser()}>
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" /><path d="M9 12h12l-3 -3" /><path d="M18 15l3 -3" /></svg>
            Log Out
          </button>
        </div>

        {/* SETTINGS TAB */}

        <div className={`${tab == 'settings' ? "" : "hidden"}`}>
  <h2 className="mb-4">My Account</h2>
  <h3 className="card-title">Profile Details</h3>
  <div className="row align-items-center">
    <div className="col-auto"><span className="avatar avatar-xl" style={{backgroundImage: 'url(./static/avatars/000m.jpg)'}} />
    </div>
    <div className="col-auto"><a href="#" className="btn">
        Change avatar
      </a></div>
    <div className="col-auto"><a href="#" className="btn btn-ghost-danger">
        Delete avatar
      </a></div>
  </div>
  <h3 className="card-title mt-4">Business Profile</h3>
  <div className="row g-3">
    <div className="col-md">
      <div className="form-label">Business Name</div>
      <input type="text" className="form-control" defaultValue="Tabler" />
    </div>
    <div className="col-md">
      <div className="form-label">Business ID</div>
      <input type="text" className="form-control" defaultValue="560afc32" />
    </div>
    <div className="col-md">
      <div className="form-label">Location</div>
      <input type="text" className="form-control" defaultValue="Peimei, China" />
    </div>
  </div>
  <h3 className="card-title mt-4">Email</h3>
  <p className="card-subtitle">This contact will be shown to others publicly, so choose it carefully.</p>
  <div>
    <div className="row g-2">
      <div className="col-auto">
        <input type="text" className="form-control w-auto" defaultValue="paweluna@howstuffworks.com" />
      </div>
      <div className="col-auto"><a href="#" className="btn">
          Change
        </a></div>
    </div>
  </div>
  <h3 className="card-title mt-4">Password</h3>
  <p className="card-subtitle">You can set a permanent password if you don't want to use temporary login codes.</p>
  <div>
    <a href="#" className="btn">
      Set new password
    </a>
  </div>
  <h3 className="card-title mt-4">Public profile</h3>
  <p className="card-subtitle">Making your profile public means that anyone on the Dashkit network will be able to find
    you.</p>
  <div>
    <label className="form-check form-switch form-switch-lg">
      <input className="form-check-input" type="checkbox" />
      <span className="form-check-label form-check-label-on">You're currently visible</span>
      <span className="form-check-label form-check-label-off">You're
        currently invisible</span>
    </label>
  </div>
        </div>

{/* NOTIFICATIONS TAB */}

<div className={`${tab == 'notifications' ? "" : "hidden"}`}>
<div className="list-group list-group-flush list-group-hoverable">
  <div className="list-group-item">
    <div className="row align-items-center">
      <div className="col-auto"><span className="status-dot status-dot-animated bg-red d-block" /></div>
      <div className="col text-truncate">
        <a href="#" className="text-body d-block">Example 1</a>
        <div className="d-block text-secondary text-truncate mt-n1">
          Change deprecated html tags to text decoration classes (#29604)
        </div>
      </div>
      <div className="col-auto">
        <a href="#" className="list-group-item-actions">
          {/* Download SVG icon from http://tabler-icons.io/i/star */}
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon text-muted"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg>
        </a>
      </div>
    </div>
  </div>
  <div className="list-group-item">
    <div className="row align-items-center">
      <div className="col-auto"><span className="status-dot d-block" /></div>
      <div className="col text-truncate">
        <a href="#" className="text-body d-block">Example 2</a>
        <div className="d-block text-secondary text-truncate mt-n1">
          justify-content:between ⇒ justify-content:space-between (#29734)
        </div>
      </div>
      <div className="col-auto">
        <a href="#" className="list-group-item-actions show">
          {/* Download SVG icon from http://tabler-icons.io/i/star */}
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon text-yellow"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg>
        </a>
      </div>
    </div>
  </div>
  <div className="list-group-item">
    <div className="row align-items-center">
      <div className="col-auto"><span className="status-dot d-block" /></div>
      <div className="col text-truncate">
        <a href="#" className="text-body d-block">Example 3</a>
        <div className="d-block text-secondary text-truncate mt-n1">
          Update change-version.js (#29736)
        </div>
      </div>
      <div className="col-auto">
        <a href="#" className="list-group-item-actions">
          {/* Download SVG icon from http://tabler-icons.io/i/star */}
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon text-muted"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg>
        </a>
      </div>
    </div>
  </div>
  <div className="list-group-item">
    <div className="row align-items-center">
      <div className="col-auto"><span className="status-dot status-dot-animated bg-green d-block" /></div>
      <div className="col text-truncate">
        <a href="#" className="text-body d-block">Example 4</a>
        <div className="d-block text-secondary text-truncate mt-n1">
          Regenerate package-lock.json (#29730)
        </div>
      </div>
      <div className="col-auto">
        <a href="#" className="list-group-item-actions">
          {/* Download SVG icon from http://tabler-icons.io/i/star */}
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon text-muted"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg>
        </a>
      </div>
    </div>
  </div>
</div>
</div>

{/* COINS TAB */}

<div className={`${tab == 'coins' ? "" : "hidden"}`}>
<div className="card card-sm">
  <div className="card-body">
    <div className="row align-items-center">
      <div className="col-auto">
        <span className="avatar">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-coins"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z" /><path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4" /><path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z" /><path d="M3 6v10c0 .888 .772 1.45 2 2" /><path d="M3 11c0 .888 .772 1.45 2 2" /></svg>
        </span>
      </div>
      <div className="col">
        <div className="font-weight-medium">
            Available coins
        </div>
        <div className="text-secondary">
          $14.566
        </div>
      </div>
      <div className="col-auto">
      </div>
    </div>
  </div>
</div>

<p className="text-secondary font-14 mt-2">
    These coins are the same value as the dollar. These coins can only be used in 'Ultimate' and cannot be withdrawn once purchased.
</p>
<p className="text-secondary font-16 mt-2">
    By containing, purchasing or disposing of coins in your account, you accept the terms and conditions that even qualify you as an adult of +18 years.
</p>
<hr />
<div className="mt-2">
  <label className="form-label">Buy coins</label>
  <div className="row g-2">
    <div className="col">
      <input type="number" className="form-control" placeholder="Enter the quantity to purchase." defaultValue={5} min={1} max={100} />
    </div>
    <div className="col-auto">
      <a href="#" className="btn btn-icon" aria-label="Button">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-paypal"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 13l2.5 0c2.5 0 5 -2.5 5 -5c0 -3 -1.9 -5 -5 -5h-5.5c-.5 0 -1 .5 -1 1l-2 14c0 .5 .5 1 1 1h2.8l1.2 -5c.1 -.6 .4 -1 1 -1zm7.5 -5.8c1.7 1 2.5 2.8 2.5 4.8c0 2.5 -2.5 4.5 -5 4.5h-2.6l-.6 3.6a1 1 0 0 1 -1 .8l-2.7 0a.5 .5 0 0 1 -.5 -.6l.2 -1.4" /></svg>
      </a>
    </div>
  </div>
</div>


</div>

{/* COINS TAB */}

<div className={`${tab == 'switch-account' ? "" : "hidden"}`}>


<p className="text-secondary font-14">Switch Account.</p>
          <div className="card mb-1">
                <div className="memberRow_fe3229 snipcss-pOeOt" aria-expanded="false" role="button" tabIndex={0}>
                    <div className="container_db5049 memberDetails_fe3229">
                    <div className="wrapper_c51b4e avatar_db5049 style-RTeFd" role="img" aria-label="! Alexito2060" aria-hidden="false" id="style-RTeFd"><svg width={24} height={24} viewBox="0 0 24 24" className="mask_c51b4e svg_c51b4e" aria-hidden="true">
                        <foreignObject x={0} y={0} width={24} height={24} mask="url(#svg-mask-avatar-default)">
                            <div className="avatarStack_c51b4e">
                            <img src="https://cdn.discordapp.com/avatars/1130574602033180734/f039f40a0d489734553723c9227b12d1.png" style={{ width: "100% !important" }} alt=" " className="avatar_c51b4e" aria-hidden="true" />
                            </div>
                        </foreignObject>
                        </svg>
                    </div>
                    <div className="textContainer_db5049">
                        <span className="text-sm/semibold_dc00ef name_db5049 style-yFx6x" data-text-variant="text-sm/semibold" id="style-yFx6x">scout_ante</span>
                    </div>
                    </div>
                    <div className="removeButtonContainer_fe3229">
                    <div className="removeButton_fe3229" aria-label="Remove member" role="button" tabIndex={0}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                    </div>
                    </div>
                </div>
          </div>
          <div className="card mb-1">
                <div className="memberRow_fe3229 snipcss-pOeOt" aria-expanded="false" role="button" tabIndex={0}>
                    <div className="container_db5049 memberDetails_fe3229">
                    <div className="wrapper_c51b4e avatar_db5049 style-RTeFd" role="img" aria-label="! Alexito2060" aria-hidden="false" id="style-RTeFd"><svg width={24} height={24} viewBox="0 0 24 24" className="mask_c51b4e svg_c51b4e" aria-hidden="true">
                        <foreignObject x={0} y={0} width={24} height={24} mask="url(#svg-mask-avatar-default)">
                            <div className="avatarStack_c51b4e">
                            <img src="https://cdn.discordapp.com/avatars/294949029152817152/30d93f581dd776b4ed6db0eb9816dbc7.png?size=80" alt=" " className="avatar_c51b4e" aria-hidden="true" />
                            </div>
                        </foreignObject>
                        </svg>
                    </div>
                    <div className="textContainer_db5049">
                        <span className="text-sm/semibold_dc00ef name_db5049 style-yFx6x" data-text-variant="text-sm/semibold" id="style-yFx6x">ruben_artz</span>
                    </div>
                    </div>
                    <div className="removeButtonContainer_fe3229">
                    <div className="removeButton_fe3229" aria-label="Remove member" role="button" tabIndex={0}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                    </div>
                    </div>
                </div>
          </div>

</div>




      </div>
    </div>
  </div>
</>
          )}
        </div>
  );
};

export default Content
