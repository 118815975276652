import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import Swal from 'sweetalert2';
import { parse, format, isThisYear } from 'date-fns';
import { es } from 'date-fns/locale';
import { PermissionsContext } from '../../components/hasPermissions.js';

const Content = ({ config, apiURL, details }) => {
  const { hasPermission } = useContext(PermissionsContext);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  const fetchData = async () => {
    setIsLoader(true); 
    try {
      const jwt = Cookies.get('jwt');
      const configResponse = await axios.post(apiURL, {
        type: '/munity/compras',
        option: '/list',
      }, {
        headers: { Authorization: `Bearer ${jwt}` },
      });
  
      const configData = configResponse.data;

      console.log(configData);
  
      if (configData) {
        localStorage.setItem('compras_list', JSON.stringify(configData.result));
        Cookies.set('compras_list', true, { expires: 1 / 24 });
  
        const matchedData = configData.result.filter(ciudadano => String(ciudadano.id) === String(details));
    
        if (matchedData.length > 0) {
          setData(matchedData);
          setFilteredData(matchedData);
        } else {
          console.error("No matching ciudadano found.");
        }
      } else {
        console.error("No hay datos en la respuesta de la API o no es un array.");
      }
    } catch (error) {
      setData([]); 
    } finally {
      setIsLoader(false);
    }
  };
  

  useEffect(() => {
    const ciudadanos_list = Cookies.get('compras_list');
    const ciudadanosData = JSON.parse(localStorage.getItem('compras_list'));

    if (ciudadanos_list) {
      const matchedData = ciudadanosData.filter(ciudadano => ciudadano.id == details);
      setData(matchedData);
      setFilteredData(matchedData);
      setIsLoader(false);
    } else {
      fetchData();
    }
  }, [details]); 



  if (isLoader) {
    return (
      <div className="container container-slim py-4">
        <div className="mb-3">
        <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
        </div>
        <div className="text-center">
          <div className="text-secondary mb-3">Cargando orden de compras...</div>
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {filteredData.map(item => {
        
        const parsedDate = parse(item.since, 'yyyy-MM-dd HH:mm:ss', new Date());
        const dayOfWeek = format(parsedDate, 'EEEE', { locale: es });
        const formattedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1).toLowerCase();
        const month = format(parsedDate, 'MMMM', { locale: es });
        const formattedMonth = (month === 'enero') ? 'enero' : month.slice(0, 3).charAt(0).toUpperCase() + month.slice(1, 3);
        const includeYear = !isThisYear(parsedDate);
        const formattedYear = includeYear ? `, ${format(parsedDate, 'yyyy')}` : '';
        const dateSince = `${formattedDayOfWeek} ${format(parsedDate, 'd')} de ${formattedMonth}${formattedYear}`;

        const comb = item.art.includes('#') ? item.art.split('#') : [item.art];
        const cant = item.cant.includes('#') ? item.cant.split('#') : [item.cant];

        return (
        <>
          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-body">
                <div className="datagrid">
                  <div className="datagrid-item">
                    <div className="datagrid-title">Nombre</div>
                    <div className="datagrid-content">{item.ciudadano}</div>
                  </div>
                  <div className="datagrid-item">
                    <div className="datagrid-title">DNI</div>
                    <div className="datagrid-content">{item.dni}</div>
                  </div>
                  <div className="datagrid-item">
                    <div className="datagrid-title">Comercio</div>
                    <div className="datagrid-content">{item.comercio}</div>
                  </div>
                  <div className="datagrid-item">
                    <div className="datagrid-title">Autorizacion</div>
                    <div className="datagrid-content">{item.autoriza == '' ? (<span class="badge bg-yellow-lt">Pendiente</span>) : item.autoriza ? (<span class="badge bg-green-lt">Autorizado</span>) : (<span class="badge bg-red-lt">Desautorizado</span>)}</div>
                  </div>
                  <div className="datagrid-item">
                    <div className="datagrid-title">Fecha</div>
                    <div className="datagrid-content"><span className="status status-green">{dateSince}</span></div>
                  </div>
                  <div className="datagrid-item">
                    <div className="datagrid-title">Vencimiento</div>
                    <div className="datagrid-content">{item.vencimiento ?? '--'}</div>
                  </div>
                  <div className="datagrid-item">
                    <div className="datagrid-title">Observacion:</div>
                    <div className="datagrid-content">{item.observacion}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="col-12 mb-3">
            <h3>Informacion de la <b className="text-tabler">Orden</b></h3>
            <div className="row row-cards mb-3">
              <div className="col-sm-6 col-lg-6">
                <div className="card card-sm">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <span className="bg-green text-white avatar">
                          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" /><path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5" /></svg>
                        </span>
                      </div>
                      <div className="col">
                        <div className="font-weight-medium">Costo Total</div>
                        <div className="text-secondary">${item.costo ? item.costo : '0.00'}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-6">
                <div className="card card-sm">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <span className="bg-danger text-white avatar">
                          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-shopping-cart"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 17h-11v-14h-2" /><path d="M6 5l14 1l-1 7h-13" /></svg>
                        </span>
                      </div>
                      <div className="col">
                        <div className="font-weight-medium">Articulos cant.</div>
                        <div className="text-secondary">{comb.length > 0 ? comb.length : 0}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {item.autoriza == '' ? (
            <div className="card mb-3">
              <div className="card-body">
                <div className="row g-3 align-items-center">
                  <a href="#" className="col-auto">
                    <span className="avatar">
                      <span className="badge bg-warning" />
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
                    </span>
                  </a>
                  <div className="col text-truncate">
                    <a href="#" className="text-reset d-block text-truncate">Orden de compra no fue autorizada.</a>
                    <div className="text-secondary text-truncate mt-n1">La orden no se puede imprimir ni descargar.</div>
                  </div>
                </div>
              </div>
            </div>
            ) : (<> </>)}

          </div>

          <hr />
          <div className="card mb-3">
            <div className="card-header">
              <h3 className="card-title">Articulos</h3>
            </div>
            
            <div className="list-group list-group-flush">
            {comb.length > 0 ? comb.map((art, index) => (
              <div className="list-group-item" key={index}>
                <div className="row align-items-center">
                  <div className="col text-truncate">
                    <span className="text-reset d-block">x{cant[index] || cant[0]} | {art}</span>
                  </div>
                </div>
              </div>
            )) : (
              <div className="list-group-item">
                <div className="row align-items-center">
                  <div className="col text-truncate">
                    No hay artículos
                  </div>
                </div>
              </div>
            )}
            </div>
          </div>
        </>
        );
      })}
      
</div>

  );
};

export default Content;
