import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Swal from 'sweetalert2'

const UserContext = createContext();

const UserProvider = ({ config, apiURL, children }) => {
    const cookieUser = Cookies.get('user');
    const cookieroles = Cookies.get('roles');
    const [showPassword, setShowPassword] = useState(false);
    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
          toast.onclick = () => {
            Swal.close();
          };
        }
      });

  const [userLogged, setUserLogged] = useState(() => {
    return cookieUser ? JSON.parse(cookieUser) : null;
  });
  
  const [roles, setRoles] = useState(() => {
    return cookieroles ? JSON.parse(cookieroles) : null;
  });


  const [loading, setLoading] = useState(!userLogged);

  useEffect(() => {
    if (userLogged) {
      fetchLogin(`/verify`, userLogged.id, '', '');
    }
    

    const handleMessage = event => {
      const { token } = event.data;
      if (token) {
        setUserLogged(event.data.user);
        Cookies.set('user', JSON.stringify(event.data.user), { expires: 30 });
        setLoading(false);
        Toast.fire({ icon: "success", title: 'Login was successful.' });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const fetchLogin = async (endpoint, id, username, password) => {
    try {
        const LicenseKeyData = Cookies.get('jwt');
        const response = await axios.post(apiURL, {
          type: '/user/account',
          option: endpoint,
          id: id,
          username: username,
          password: password
        }, {
          headers: {
            Authorization: `Bearer ${LicenseKeyData}`,
          },
        });
        if (response.status === 200 && response.data) {
          if (response.data.valid) {
              setUserLogged(response.data.user);
              Cookies.set('user', JSON.stringify(response.data.user), { expires: 30 });

              setRoles(response.data.roles.list);

              Cookies.set('permissions', JSON.stringify(response.data.roles.permissions), { expires: 30 });
            if (!response.data.type) {
              Toast.fire({ icon: 'success', title: response.data.message });
            }
          } else {
            Toast.fire({ icon: 'error', title: response.data.message });
          }
        } else {
            setUserLogged(null);
            Cookies.remove('user');
            Toast.fire({ icon: 'error', title: 'Error de inicio de sesion. Verifique sus credenciales.' });
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        setUserLogged(null);
        Cookies.remove('user');
        Toast.fire({ icon: 'error', title: 'Se produjo un error durante el inicio de sesión.' });
    }
  };

  const loginUser = async (email, password) => {
    try {
      fetchLogin(`/login`, '', email, password);
    } catch (error) {
      console.error('Error fetching user:', error);
      setUserLogged(null);
      Cookies.remove('user');
    } finally {
      setLoading(false);
    }
  };

  const logoutUser = () => {
    setUserLogged(null);
    Cookies.remove('user');
    Toast.fire({ icon: "success", title: '¡Session cerrada exitosamente!' });
  };

  const verifyUserSession = async () => {
    try {
        const userData = Cookies.get('user');
        if (!userData) {
            console.error('No user data found in cookies');
            return;
        }

        let currentUser;
        try {
            currentUser = JSON.parse(userData);
        } catch (error) {
            console.error('Error parsing userData:', error);
            return;
        }

        if (!currentUser.id) {
            console.error('User ID not found in cookie');
            return;
        }

        fetchLogin(`/verify`, currentUser.id, '', '');
    } catch (error) {
        console.error('Error verifying user session:', error);
    }
};

  useEffect(() => {
    const intervalId = setInterval(verifyUserSession, 15000); // Verifica cada 60 segundos
    return () => clearInterval(intervalId);
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    loginUser(email, password);
  };

  const viewPassword = (e) => {
    e.preventDefault();
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  if (!cookieUser) { // COLOCAR !cookieUser
    return (
    <div className="page page-center">
      <div className="container container-tight py-4">
        <div className="text-center mb-4">
          <div className="navbar-brand navbar-brand-autodark row">
            <div className="col-auto">
              <img src={`${config.logo}`} alt={`${config.name}`} className="rounded" width="64px" />
            </div>
            <div className="col">
              <div className="font-weight-medium">{config.name}</div>
            </div>
          </div>
        </div>
        <div className="card card-md">
          <div className="card-body">
            <h2 className="h2 text-center mb-4">¡Inicio de Session!</h2>
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <div className="mb-3">
                <label className="form-label">Nombre de usuario</label>
                <input 
                  type="email" 
                  className="form-control" 
                  placeholder="Su nombre de usuario." 
                  autoComplete="off" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-2">
                <label className="form-label">
                  Contraseña
                  {/*<span className="form-label-description">
                    <a href="./forgot-password.html">I forgot password</a>
                  </span>*/}
                </label>
                <div className="input-group input-group-flat">
                  <input 
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    className="form-control" 
                    placeholder="Su contraseña." 
                    autoComplete="off" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="input-group-text">
                    <a href="#" className="link-secondary" data-bs-toggle="tooltip" aria-label="Show password" data-bs-original-title="Show password" onClick={(e) => viewPassword(e)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                      </svg>
                    </a>
                  </span>
                </div>
              </div>
              {/*<div className="mb-2">
                <label className="form-check">
                  <input type="checkbox" className="form-check-input" />
                  <span className="form-check-label">Remember me on this device</span>
                </label>
              </div>*/}
              <div className="form-footer">
                <button type="submit" className="btn btn-primary w-100">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-login me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" /><path d="M21 12h-13l3 -3" /><path d="M11 15l-3 -3" /></svg>
                  Iniciar Session
                </button>
              </div>
            </form>
          </div>
        </div>
        {/*<div className="text-center text-secondary mt-3">
          Don't have an account yet? <a href="./sign-up.html" tabIndex={-1}>Sign up</a>
        </div>*/}
      </div>
    </div>
    );
  }

  return (
    <UserContext.Provider value={{ userLogged, loading, loginUser, logoutUser, roles }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };