import React, { createContext } from 'react';
import Cookies from 'js-cookie';

const PermissionsContext = createContext();

const PermissionsProvider = ({ children }) => {
  const hasPermission = (permission) => {
    const permissionsString = Cookies.get('permissions');

    if (!permissionsString) {
      return false;
    }

    let permissions;
    try {
      permissions = JSON.parse(permissionsString); 
    } catch (e) {
      return false;
    }

    const permissionFound = permissions.includes('m.*') || permissions.includes(permission);

    return permissionFound;
  };

  return (
    <PermissionsContext.Provider value={{ hasPermission }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export { PermissionsContext, PermissionsProvider };
