import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';

import Listado from './ciudadanos/Listado.js';
import Agregar from './ciudadanos/Agregar.js';
import Detalles from './ciudadanos/Detalles.js';
import Editar from './ciudadanos/Editar.js';
import { PermissionsContext } from '../components/hasPermissions.js';

const Content = ({ config, apiURL }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const location = useLocation();
  const { id, type } = useParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const checkPermissions = () => {
      if (!hasPermission('m.ciudadanos') && id !== 'agregar') {
        navigate(-1); 
      } else if (!hasPermission('m.ciudadanos.agregar') && id === 'agregar') {
        navigate(-1);
      } else if (!hasPermission('m.ciudadanos.modificar') && id && type === 'editar') {
        navigate(-1);
      } else {
        setLoading(false);
      }
    };

    checkPermissions();
  }, [hasPermission, navigate, id]);

  if (loading) {
    return null;
  }

  return (
    <>
  <ul className="nav nav-bordered mb-4 mt-5">
    <li className="nav-item">
      <Link className={`nav-link ${!id ? 'active' : ''}`} aria-current="page" to={`/ciudadanos`}>
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-license me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 21h-9a3 3 0 0 1 -3 -3v-1h10v2a2 2 0 0 0 4 0v-14a2 2 0 1 1 2 2h-2m2 -4h-11a3 3 0 0 0 -3 3v11" /><path d="M9 7l4 0" /><path d="M9 11l4 0" /></svg>
        Listado
      </Link>
    </li>
    {hasPermission('m.ciudadanos.agregar') && (
    <li className="nav-item">
      <Link className={`nav-link ${id == 'agregar' ? 'active' : ''}`} to={`/ciudadanos/agregar`}>
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-users-plus me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4c.96 0 1.84 .338 2.53 .901" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M16 19h6" /><path d="M19 16v6" /></svg>
        Agregar Ciudadano
      </Link>
    </li>
    )}
  </ul>
  <div className="row">
    {!id ? 
    <Listado config={config} apiURL={apiURL} /> : 
    id == 'agregar' ? 
    <Agregar config={config} apiURL={apiURL} /> : 
    id && type == 'editar' ?
    <Editar config={config} apiURL={apiURL} details={id} page={type} /> :
    <Detalles config={config} apiURL={apiURL} details={id} />
    }
  </div>


    </>
  );
};

export default Content
