import React, { useState } from 'react';

const Buscador = ({ opciones, select }) => {
  const [searchOption, setSearchOption] = useState('');

  const filteredOptions = opciones.result?.filter((opcion) => 
    opcion.comercio.toLowerCase().includes(searchOption.toLowerCase())
  );

  return (
    <>
      <input 
        type="text"
        placeholder="Buscar comercio..."
        value={searchOption}
        onChange={(e) => setSearchOption(e.target.value)}
        className="form-control mb-3"
      />

      {filteredOptions && filteredOptions.length > 0 ? (
        filteredOptions.map((opcion, index) => {
          const checked = select && opcion.comercio === select ? true : index === 0 ? true : false;
          return (
          <label className="form-selectgroup-item" key={index}>
            <input 
              type="radio" 
              name="comercio" 
              value={opcion.comercio} 
              className="form-selectgroup-input"
              defaultChecked={checked} 
            />
            <span className="form-selectgroup-label">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon me-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /></svg>
                {opcion.comercio}
            </span>
          </label>
          );
        })
      ) : (
        <p>No se encontraron resultados.</p>
      )}
    </>
  );
};

export default Buscador;
