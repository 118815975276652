import React from "react";

const SMSSender = () => {
  // Array de números de teléfono
  const phoneNumbers = ["3804640409", "3826405201"]; // Ejemplo de números de teléfono
  const message = "Hola, este es un mensaje de prueba desde mi aplicación!";

  // Función para abrir la aplicación de SMS para cada número
  const sendMessages = () => {
    phoneNumbers.forEach((number) => {
      const url = `sms:${number}?&body=${encodeURIComponent(message)}`;
      window.open(url, "_blank");
    });
  };

  return (
    <div>
      <h1>Enviar mensajes de texto</h1>
      <button onClick={sendMessages}>Enviar Mensajes</button>
    </div>
  );
};

export default SMSSender;