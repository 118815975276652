import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

import Combustible from './hacienda/Combustible.js';
import Compra from './hacienda/Compra.js';
import Ingresos from './hacienda/Ingresos.js';
import { PermissionsContext } from '../components/hasPermissions.js';

const Content = ({ config, apiURL }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const location = useLocation();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [monto, setMonto] = useState('0.00');
  const [countdown, setCountdown] = useState(60);

  const navigate = useNavigate();

  useEffect(() => {
    const checkPermissions = () => {
      if (!hasPermission('m.hacienda') && id !== 'agregar') {
        navigate(-1); 
      } else {
        setLoading(false);
      }
    };

    checkPermissions();
  }, [hasPermission, navigate, id]);


  const fetchMonto = async () => {
    try {
      const jwt = Cookies.get('jwt');
      const response = await axios.post(apiURL, {
        type: '/munity/hacienda',
        option: '/list/monto',
      }, { headers: { Authorization: `Bearer ${jwt}` } });

      const initialData = response.data;

      if (initialData && initialData.result) {
        setMonto(initialData.result);
      } else {
        console.error("No hay datos en la respuesta de la API.");
      }
    } catch (error) {
      setMonto([]);
    }
  };

  useEffect(() => {
    fetchMonto();

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          fetchMonto(); 
          return 15;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div className='mt-5'>

              <div className="alert alert-info bg-theme" role="alert">
                <div className="d-flex">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M12 9h.01" /><path d="M11 12h1v4h1" /></svg>
                  </div>
                  <div>
                    El sistema se encuentra en la version beta(trabajandolo), Todo lo que se necesite en esta area puede comunicarla en el grupo de whatsapp.
                  </div>
                </div>
              </div>
      
<div className="card mb-1">
  <div className="card-stamp">
    <div className="card-stamp-icon bg-yellow">
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
    </div>
  </div>
  <div className="card-body">
    <h3 className="card-title mb-0">Monto Disponible: ${Number(monto).toLocaleString('es-ES')}</h3>
    <p className="text-secondary">El monto que se muestra es el ingreso actual que tiene hacienda disponible. (Comenzo el 15/11/2024)</p>
  </div>
</div>


      <div className="card" style={{ background: 'none'}}>
        <div className="card-header" style={{ background: 'none'}}>
          <ul className="nav nav-tabs card-header-tabs nav-fill" data-bs-toggle="tabs" role="tablist" style={{ background: 'none'}}>
            <li className="nav-item" role="presentation">
              <Link to="/hacienda/combustibles" className={`nav-link${!id || id == 'combustibles' ? ' active' : ''}`} >
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l-2 0l9 -9l9 9l-2 0" /><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /><path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
                Ordenes de Combustible
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link to="/hacienda/compra" className={`nav-link${id && id == 'compra' ? ' active' : ''}`} >
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
                Ordenes de Compra
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link to="/hacienda/ingresos" className={`nav-link${id && id == 'ingresos' ? ' active' : ''}`} >
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12h4l3 8l4 -16l3 8h4" /></svg>
                Ingreso desde Rentas
              </Link>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <div className="tab-content">
            <div className={`tab-pane${!id || id == 'combustibles' ? ' active show' : ''}`} id="tabs-home-7" role="tabpanel">
              <Combustible config={config} apiURL={apiURL} fetchMonto={fetchMonto} selected={!id || id == 'combustibles' ? true : false} />
            </div>
            <div className={`tab-pane${id && id == 'compra' ? ' active show' : ''}`} id="tabs-profile-7" role="tabpanel">
              <Compra config={config} apiURL={apiURL} fetchMonto={fetchMonto} selected={!id || id == 'compra' ? true : false} />
            </div>
            <div className={`tab-pane${id && id == 'ingresos' ? ' active show' : ''}`} id="tabs-activity-7" role="tabpanel">
              <Ingresos config={config} apiURL={apiURL} fetchMonto={fetchMonto} selected={!id || id == 'ingresos' ? true : false} />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Content
