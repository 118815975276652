import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { UserContext } from '../../components/UserProvider.js';
import { PermissionsContext } from '../../components/hasPermissions.js';
import CSVButton from '../../components/download/CSV';

const Content = ({ config, apiURL }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const { userLogged, logoutUser } = useContext(UserContext);

  const [ ciudadanos, setCiudadanos ] = useState({});
  const [ memory, setMemory ] = useState({});
  const [ usuarios, setUsuarios ] = useState({});
  const [ ordenes, setOrdenes ] = useState({});
  const [ cant, setCant ] = useState({});
  const [ isLoader, setIsLoading ] = useState(true);

  const fetchData = async () => {
    const jwt = Cookies.get('jwt');
    try {
      if (hasPermission('m.ciudadanos') || hasPermission('m.combustible') || hasPermission('m.compras')) {
        const ciudadanosRes = await axios.post(apiURL, {
            type: '/munity/general',
            option: '/ciudadanos/list'
        }, {
            headers: { Authorization: `Bearer ${jwt}` }
        });
        const ciudadanosResData = ciudadanosRes.data;
        setCiudadanos(ciudadanosResData);
      }

      if (hasPermission('m.memory')) {
        const memoryRes = await axios.post(apiURL, {
          type: '/munity/general',
          option: '/memory'
        }, {
          headers: { Authorization: `Bearer ${jwt}` }
        });
        const memoryResData = memoryRes.data;
        setMemory(memoryResData);
      }

      if (hasPermission('m.combustible') || hasPermission('m.compras')) {
        const usuariosRes = await axios.post(apiURL, {
          type: '/munity/general',
          option: '/usuarios/list'
        }, {
          headers: { Authorization: `Bearer ${jwt}` }
        });
        const usuariosResData = usuariosRes.data;
        setUsuarios(usuariosResData);

        const ordenesRes = await axios.post(apiURL, {
          type: '/munity/general',
          option: '/ordenes/list'
        }, {
          headers: { Authorization: `Bearer ${jwt}` }
        });
        const ordenesResData = ordenesRes.data;
        setOrdenes(ordenesResData);
      }

      if (hasPermission('m.combustible') || hasPermission('m.compras') || hasPermission('m.ciudadanos')) {
        const cantRes = await axios.post(apiURL, {
          type: '/munity/general',
          option: '/cant'
        }, {
          headers: { Authorization: `Bearer ${jwt}` }
        });
        const cantResData = cantRes.data;
        setCant(cantResData);
      }
    } catch (error) {
        setCiudadanos([]);
        setMemory([]);
        setUsuarios([]);
        setOrdenes([]);
        setCant([]);
    } finally {
        setIsLoading(false);
    }
  };

  useEffect(() => {
      fetchData();
  }, []);

  if (isLoader) {
    return (
      <div class="container container-slim py-4">
        <div class="text-center">
          <div class="mb-3">
            <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
          </div>
          <div class="text-secondary mb-3">Preparando interfaz</div>
          <div class="progress progress-sm">
            <div class="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-5">

<div className="row row-cards mb-2">
  {hasPermission('m.ciudadanos') && (
  <div className="col-md-6 col-xl-3">
    <div className="card card-sm">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-auto">
            <span className="bg-primary text-white avatar">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-category"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 4h6v6h-6z" /><path d="M14 4h6v6h-6z" /><path d="M4 14h6v6h-6z" /><path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /></svg>
            </span>
          </div>
          <div className="col">
            <div className="font-weight-medium">Ciudadanos</div>
            <div className="text-secondary">{ciudadanos.cant_ciudadanos.toLocaleString('es-ES')}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )}

  {(hasPermission('m.compras') || hasPermission('m.combustible')) && (
  <div className="col-md-6 col-xl-3">
    <div className="card card-sm">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-auto">
            <span className="bg-green text-white avatar">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-server-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 4m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v2a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z" /><path d="M3 12m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v2a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z" /><path d="M7 8l0 .01" /><path d="M7 16l0 .01" /><path d="M11 8h6" /><path d="M11 16h6" /></svg>
            </span>
          </div>
          <div className="col">
            <div className="font-weight-medium">Cantidad de Ordenes</div>
            <div className="text-secondary">{(cant.cant_combustible_ordenes + cant.cant_compras_ordenes).toLocaleString('es-ES')}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )}
  
  {hasPermission('m.intendente') && (
    <>
  <div className="col-md-6 col-xl-3">
    <div className="card card-sm">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-auto">
            <span className="bg-green-lt avatar">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 5l0 14" /><path d="M18 11l-6 -6" /><path d="M6 11l6 -6" /></svg>
            </span>
          </div>
          <div className="col">
            <div className="font-weight-medium">Pagado (Ordenes)</div>
            <div className="text-secondary">${ordenes.totalRSums.toLocaleString('es-ES', { minimumFractionDigits: 2 })}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="col-md-6 col-xl-3">
    <div className="card card-sm">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-auto">
            <span className="bg-red-lt avatar">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 5l0 14" /><path d="M18 13l-6 6" /><path d="M6 13l6 6" /></svg>
            </span>
          </div>
          <div className="col">
            <div className="font-weight-medium">Gasto Total (Ordenes)</div>
            <div className="text-secondary">${ordenes.totalSums.toLocaleString('es-ES', { minimumFractionDigits: 2 })}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )}
</div>

  
  <div class="row row-cards">
    <div class="col-xl-8 col-md-12 col-sm-12">
    {hasPermission('m.combustible') && (
      <div class="card mb-2 table-responsive">
        <div className="card-header">
          <h3 className="card-title">Informacion de Combustible</h3>
          {hasPermission('m.intendente') && (
          <div className="card-actions">
            <CSVButton apiURL={apiURL} />
          </div>
          )}
        </div>

        <table class="table card-table table-vcenter">
          <thead>
            <tr>
              <th width={5}></th>
              <th>Combustible</th>
              <th>Cantidad</th>
              <th>Costo</th>
              <th>Litros</th>
              <th>Total</th>
              <th>Pagado</th>
            </tr>
          </thead>
          <tbody>

            {ordenes.combustible_details && 
    Object.entries(ordenes.combustible_details).sort((a, b) => b.litros - a.litros).map(([combustible_part, details]) => {
              const total_gasoil = details.precio * details.litros;

              return (
                <tr key={combustible_part}>
                  <td>
                    <input class="form-check-input m-0 align-middle" type="checkbox" aria-label="Select invoice" defaultChecked={true} />
                  </td>
                  <td>{combustible_part}</td>
                  <td>{details.count_fill}</td>
                  <td>${details.precio.toLocaleString('es-ES', { minimumFractionDigits: 2 })}</td>
                  <td>{details.litros.toLocaleString('es-ES')}</td>
                  <td className="text-primary">
                    ${total_gasoil.toLocaleString('es-ES', { minimumFractionDigits: 2 })}
                  </td>
                  <td className="text-primary">${(details.recuperado || 0).toLocaleString('es-ES', { minimumFractionDigits: 2 })}</td>
                </tr>
              );
            })}

          </tbody>
        </table>
      </div>
    )}
      

    {hasPermission('m.ciudadanos') && (
      <div className="card mb-2">
        <div className="card-body">
          <h3 className="card-title">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-building-skyscraper me-2 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 21l18 0" /><path d="M5 21v-14l8 -4v18" /><path d="M19 21v-10l-6 -4" /><path d="M9 9l0 .01" /><path d="M9 12l0 .01" /><path d="M9 15l0 .01" /><path d="M9 18l0 .01" /></svg>
            Localidades con Ciudadanos
          </h3>
          <table className="table table-sm table-borderless">
            <thead>
              <tr>
                <th>Localidad</th>
                <th className="text-end">Cant. Ciudadanos</th>
              </tr>
            </thead>
            <tbody>
              {ciudadanos.result.sort((a, b) => b.count - a.count)
                .filter(user => user.count > 0) // Filtrar usuarios con combustible_count > 0
                .map(user => (
                  <tr key={user.localidad}>
                    <td>
                      <div className="progressbg">
                        <div className="progress progressbg-progress">
                          <div
                            className="progress-bar bg-primary-lt"
                            style={{ width: `${user.percent}%` }} // Usar el porcentaje del combustible
                            role="progressbar"
                            aria-valuenow={user.percent}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            aria-label={`${user.percent}% Complete`}
                          >
                            <span className="visually-hidden">{`${user.percent}% Complete`}</span>
                          </div>
                        </div>
                        <div className="progressbg-text">{user.localidad}</div>
                      </div>
                    </td>
                    <td className="w-1 fw-bold text-end">{user.count}</td> {/* Cantidad total */}
                  </tr>
              ))}
              
            </tbody>
          </table>
        </div>
      </div>
    )}

    </div>

    
    <div class="col-sm-12 col-md-12 col-xl-4">
        {hasPermission('m.intendente') && (
        <div className="alert alert-warning bg-theme" role="alert">
            <div className="d-flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
                </div>
                <div>
                    <h4 className="alert-title">¡Autorización automática activa!</h4>
                    <div className="text-secondary">La autorización automática de órdenes se encuentra activa.</div>
                </div>
            </div>
        </div>
        )}
        {hasPermission('m.memory') && (
      <div class="card mb-2">
        <div class="card-body">
          <p class="mb-3">Uso de Memoria <strong>{parseFloat(memory.memory_usage.current_memory).toFixed(2)} {memory.memory_usage.unit} </strong>de {memory.memory_usage.max_memory} {memory.memory_usage.max_unit}</p>
          <div class="progress progress-separated mb-3">
            <div className="progress-bar bg-primary" role="progressbar" aria-label="Regular" style={{width: `${memory.memory_usage.memory_percentage}%`}} />
          </div>
          <div class="row">
            <div class="col-auto d-flex align-items-center pe-2">
              <span class="legend me-2 bg-primary"></span>
              <span>Base de Datos</span>
              <span class="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-secondary">{parseFloat(memory.memory_usage.current_memory).toFixed(2)} {memory.memory_usage.unit}</span>
            </div>
          </div>
        </div>
      </div>
        )}

        {hasPermission('m.combustible') && (
      <div className="card mb-2">
        <div className="card-body">
          <h3 className="card-title">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-gas-station me-2 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 11h1a2 2 0 0 1 2 2v3a1.5 1.5 0 0 0 3 0v-7l-3 -3" /><path d="M4 20v-14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14" /><path d="M3 20l12 0" /><path d="M18 7v1a1 1 0 0 0 1 1h1" /><path d="M4 11l10 0" /></svg>
            Creadores de Combustible
          </h3>
          <table className="table table-sm table-borderless">
            <thead>
              <tr>
                <th>Usuario</th>
                <th className="text-end">Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.usuarios.sort((a, b) => b.combustible_count - a.combustible_count)
                .filter(user => user.combustible_count > 0) // Filtrar usuarios con combustible_count > 0
                .map(user => (
                  <tr key={user.user_id}>
                    <td>
                      <div className="progressbg">
                        <div className="progress progressbg-progress">
                          <div
                            className="progress-bar bg-primary-lt"
                            style={{ width: `${user.combustible.percent}%` }} // Usar el porcentaje del combustible
                            role="progressbar"
                            aria-valuenow={user.combustible.percent}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            aria-label={`${user.combustible.percent}% Complete`}
                          >
                            <span className="visually-hidden">{`${user.combustible.percent}% Complete`}</span>
                          </div>
                        </div>
                        <div className="progressbg-text">{user.name}</div>
                      </div>
                    </td>
                    <td className="w-1 fw-bold text-end">{user.combustible.total}</td> {/* Cantidad total */}
                  </tr>
              ))}
              
            </tbody>
          </table>
        </div>
      </div>
        )}
        {hasPermission('m.compras') && (
      <div className="card mb-2">
        <div className="card-body">
          <h3 className="card-title">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-shopping-bag me-2 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6.331 8h11.339a2 2 0 0 1 1.977 2.304l-1.255 8.152a3 3 0 0 1 -2.966 2.544h-6.852a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304z" /><path d="M9 11v-5a3 3 0 0 1 6 0v5" /></svg>
            Creadores de Compras
          </h3>
          <table className="table table-sm table-borderless">
            <thead>
              <tr>
                <th>Usuario</th>
                <th className="text-end">Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.usuarios.sort((a, b) => b.compras_count - a.compras_count)
                .filter(user => user.compras_count > 0) // Filtrar usuarios con combustible_count > 0
                .map(user => (
                  <tr key={user.user_id}>
                    <td>
                      <div className="progressbg">
                        <div className="progress progressbg-progress">
                          <div
                            className="progress-bar bg-primary-lt"
                            style={{ width: `${user.compras.percent}%` }} // Usar el porcentaje del combustible
                            role="progressbar"
                            aria-valuenow={user.compras.percent}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            aria-label={`${user.compras.percent}% Complete`}
                          >
                            <span className="visually-hidden">{`${user.compras.percent}% Complete`}</span>
                          </div>
                        </div>
                        <div className="progressbg-text">{user.name}</div>
                      </div>
                    </td>
                    <td className="w-1 fw-bold text-end">{user.compras.total}</td> {/* Cantidad total */}
                  </tr>
              ))}
              
            </tbody>
          </table>
        </div>
      </div>
        )}




    </div>
  </div>


    </div>
  );
};

export default Content
