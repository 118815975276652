import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import Swal from 'sweetalert2';
import { PermissionsContext } from '../../components/hasPermissions.js';
const initialCoordinates = { lat: -31.0065318997796, lng: -65.9905090928078 };

const Content = ({ config, apiURL, details }) => {
  const { hasPermission } = useContext(PermissionsContext);

  const currentYear = new Date().getFullYear();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [markerPosition, setMarkerPosition] = useState(initialCoordinates);
  const [entriesPerPage, setEntriesPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [isLoader, setIsLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'desc' });
  const [selectCiu, setSelectCiu] = useState('');

  const [ combustible, setCombustible ] = useState([]);
  const [ compras, setCompras ] = useState([]);

  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  const fetchData = async () => {
    setIsLoader(true); // Asegúrate de que estás configurando el loader al inicio.
    try {
      const jwt = Cookies.get('jwt');
      const configResponse = await axios.post(apiURL, {
        type: '/munity/ciudadanos',
        option: '/list',
      }, {
        headers: { Authorization: `Bearer ${jwt}` },
      });
  
      const configData = configResponse.data;
  
      if (configData && Array.isArray(configData.result)) {
        localStorage.setItem('ciudadanos_list', JSON.stringify(configData.result));
        Cookies.set('ciudadanos_list', true, { expires: 1 / 24 });
  
        // Asegúrate de que 'details' tiene un valor válido.
  
        const matchedData = configData.result.filter(ciudadano => String(ciudadano.id) === String(details));
  
        if (matchedData.length > 0) {
          setData(matchedData);
          setFilteredData(matchedData);
          setTotalEntries(matchedData.length);
  
          // Asegúrate de que 'latitud' y 'longitud' existan en el objeto.
          const { latitud, longitud } = matchedData[0];
          setMarkerPosition({ lat: parseFloat(latitud), lng: parseFloat(longitud) });
  
          // Suponiendo que fetchOrders es una función que necesitas llamar aquí.
          fetchOrders(matchedData[0].dni);
        } else {
          console.error("No matching ciudadano found.");
        }
      } else {
        console.error("No hay datos en la respuesta de la API o no es un array.");
      }
    } catch (error) {
      setData([]); // Asegúrate de limpiar el estado en caso de error.
    } finally {
      setIsLoader(false); // Asegúrate de detener el loader al final.
    }
  };
  

  useEffect(() => {
    const ciudadanos_list = Cookies.get('ciudadanos_list');
    const ciudadanosData = JSON.parse(localStorage.getItem('ciudadanos_list'));

    if (ciudadanos_list) {
      const matchedData = ciudadanosData.filter(ciudadano => ciudadano.id == details);
      setData(matchedData);
      setFilteredData(matchedData);
      setTotalEntries(matchedData.length);
      const { latitud, longitud } = matchedData[0];
      setMarkerPosition({ lat: parseFloat(latitud), lng: parseFloat(longitud) });
      setIsLoader(false);
      fetchOrders(matchedData[0].dni);
    } else {
      fetchData();
    }
  }, [details]); 

  const fetchOrders = async (dni) => {
    try {
      const jwt = Cookies.get('jwt');
      const configResponse = await axios.post(apiURL, {
        type: '/munity/ciudadanos',
        option: '/list/ordenes',
        dni: dni
      }, { headers: { Authorization: `Bearer ${jwt}` } });
  
      const configData = configResponse.data;

      setCombustible(configData.combustible);
      setCompras(configData.compras);
    } catch (error) {
      setCombustible([]);
      setCompras([]);
    }
  };

  
  const delCiudadano = async (e, id, confirm) => {
    e.preventDefault();
    if (confirm) {
      const jwt = Cookies.get('jwt');
  
      const formData = {
        id: id,
      };
  
      try {
        const response = await axios.post(apiURL, {
          type: '/munity/ciudadanos',
          option: '/eliminar',
          ...formData,
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });
    
        const result = response.data;
        Cookies.remove('ciudadanos_list');
        if (!result.valid) {
          Toast.fire({ icon: 'error', title: result.message });
          return;
        }
        Toast.fire({ icon: 'success', title: result.message });
        navigate('/ciudadanos');
      } catch (error) {
        Toast.fire({ icon: 'error', title: 'Error en la accion.' });
      }
    } else {
      const myModal = new window.bootstrap.Modal(document.getElementById('eliminar-ciudadano'));
      myModal.show();
      setSelectCiu(id);
    }
  };

  if (isLoader) {
    return (
      <div className="container container-slim py-4">
        <div className="mb-3">
        <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
        </div>
        <div className="text-center">
          <div className="text-secondary mb-3">Cargando ciudadano...</div>
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {filteredData.map(ciudadano => (
        <>
<div className="mb-3">
  <div className="btn-group w-100" role="group">
    <Link to={`/ciudadanos/${ciudadano.id}/editar`} htmlFor="btn-radio-toolbar-1" className="btn">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-pencil"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" /><path d="M13.5 6.5l4 4" /></svg>
      Editar
    </Link>
    <label htmlFor="btn-radio-toolbar-2" className="btn" onClick={(e) => delCiudadano(e, ciudadano.id, false)}>
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
      Eliminar
    </label>
  </div>
</div>

  <div className="col-12 mb-3">
    <div className="card">
      <div className="card-body">
        <div className="datagrid">
          <div className="datagrid-item">
            <div className="datagrid-title">Nombre</div>
            <div className="datagrid-content">{ciudadano.nombre}</div>
          </div>
          <div className="datagrid-item">
            <div className="datagrid-title">DNI</div>
            <div className="datagrid-content">{ciudadano.dni}</div>
          </div>
          <div className="datagrid-item">
            <div className="datagrid-title">Telefono</div>
            <div className="datagrid-content">{ciudadano.telefono ? ciudadano.telefono : 'N/P'}</div>
          </div>
          <div className="datagrid-item">
            <div className="datagrid-title">Fecha de Nacimiento</div>
            <div className="datagrid-content">{ciudadano.nacimiento}</div>
          </div>
          <div className="datagrid-item">
            <div className="datagrid-title">Ocupacion</div>
            <div className="datagrid-content">{ciudadano.trabajo ? ciudadano.trabajo : 'N/P'}</div>
          </div>
          <div className="datagrid-item">
            <div className="datagrid-title">Localidad</div>
            <div className="datagrid-content"><span className="status status-green">{ciudadano.localidad}</span></div>
          </div>
          <div className="datagrid-item">
            <div className="datagrid-title">Domicilio</div>
            <div className="datagrid-content">{ciudadano.domicilio ? ciudadano.domicilio : 'N/P'}</div>
          </div>
          <div className="datagrid-item">
            <div className="datagrid-title">Ingresos</div>
            <div className="datagrid-content">{ciudadano.ingresos ? ciudadano.ingresos : 'N/P'}</div>
          </div>
          <div className="datagrid-item">
            <div className="datagrid-title">ID:</div>
            <div className="datagrid-content">{ciudadano.id}</div>
          </div>
          <div className="datagrid-item">
            <div className="datagrid-title">Correo Electronico</div>
            <div className="datagrid-content">{ciudadano.email ? ciudadano.email : 'N/P'}</div>
          </div>
          <div className="datagrid-item">
            <div className="datagrid-title">Creador</div>
            <div className="datagrid-content">{ciudadano.creator}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <div className="card">
      <div className="card-body">
        <div className="col-sm-12 mb-3">
          <h3 className='mb-1'>Geo Localización</h3>
          <div style={{ width: "100%", height: "50vh" }}>
            <APIProvider apiKey="AIzaSyCtfTodmrRUsCSCzeTs2Ss2e2tNva5DTyg">
                <Map
                    defaultCenter={initialCoordinates}
                    defaultZoom={15}
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                >
                    <Marker
                    position={markerPosition}
                    />
                </Map>
            </APIProvider>
          </div>
        </div>
      </div>
    </div>
  <hr />
  <div className="col-12 mb-3">
    <h3>Ordenes de <b className="text-tabler">{ciudadano.nombre}</b></h3>
    <div className="row row-cards mb-3">
      <div className="col-sm-6 col-lg-6">
        <div className="card card-sm">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-auto">
                <span className="bg-green text-white avatar">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 17h-11v-14h-2" /><path d="M6 5l14 1l-1 7h-13" /></svg>
                </span>
              </div>
              <div className="col">
                <div className="font-weight-medium">
                  Ordenes de Compra
                </div>
                <div className="text-secondary">
                    {ciudadano.compras}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-lg-6">
        <div className="card card-sm">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-auto">
                <span className="bg-tabler text-white avatar">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 11h1a2 2 0 0 1 2 2v3a1.5 1.5 0 0 0 3 0v-7l-3 -3" /><path d="M4 20v-14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14" /><path d="M3 20l12 0" /><path d="M18 7v1a1 1 0 0 0 1 1h1" /><path d="M4 11l10 0" /></svg>
                </span>
              </div>
              <div className="col">
                <div className="font-weight-medium">
                  Ordenes de Combustible
                </div>
                <div className="text-secondary">
                    {ciudadano.combustible}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    {hasPermission('m.combustible') && (
    <div className="card mb-3 table-responsive">
      <div className="card-header">
        <h3 className="card-title">Listado (Ordenes de Combustible)</h3>
      </div>
      <table className="table card-table table-vcenter">
        <thead>
          <tr>
            <th>Combustible (Lts)</th>
            <th>Litros</th>
            <th>Razon</th>
            <th>Desde</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {combustible.length > 0 ? (
          combustible.sort((a, b) => b.id - a.id).map((item) => {
            const combustibles = item.combustible.split('#');
            const costos = item.costo.split('#');
            return (
              <tr>
                <td>{combustibles[0]} <br /><span className="text-primary">{combustibles[1]} Lts</span></td>
                <td>{item.estacion}<br /><span className="text-primary">${costos[0]}</span></td>
                <td>{item.razon} <br /><span className="text-primary">{item.tipo}</span></td>
                <td>{item.since}</td>
                <td width="10%"><Link to={`/combustible/${item.id}`}>Ver Mas</Link></td>
              </tr>
            );
          })) : (
            <tr><td scope="col" colSpan={12}>No se encontraron resultados.</td></tr>
          )}
        </tbody>
      </table>
    </div>
    )}

    {hasPermission('m.compras') && (
    <div className="card mb-3 table-responsive">
      <div className="card-header">
        <h3 className="card-title">Listado (Ordenes de Compras)</h3>
      </div>
      <table className="table card-table table-vcenter">
        <thead>
          <tr>
            <th>Comercio</th>
            <th>Cant. Articulos</th>
            <th>Observacion</th>
            <th>Desde</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {compras.length > 0 ? (
          compras.sort((a, b) => b.id - a.id).map((item, index) => {
            const articulos = item.art.split('#');
            const cant = item.cant.split('#');
            return (
              <tr>
                <td>
                {articulos.map((articulo, index) => (
                  <div key={index}>
                    {articulo} (x{cant[index]})
                  </div>
                ))}
                </td>
                <td>{item.comercio}</td>
                <td>{item.observacion} <br /><span className="text-primary">{item.tipo}</span></td>
                <td>{item.since}</td>
                <td width="10%"><a href={`/compras/${item.id}`}>Ver Mas</a></td>
              </tr>
            );
          })) : (
            <tr><td scope="col" colSpan={12}>No se encontraron resultados.</td></tr>
          )}
        </tbody>
      </table>
    </div>
    )}

  </div>
  </>
      ))}
      
<div className="modal modal-blur fade" id="eliminar-ciudadano" tabIndex={-1} role="dialog" aria-modal="true" style={{display: 'none'}}>
  <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div className="modal-content">
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
      <div className="modal-status bg-danger" />
      <div className="modal-body text-center py-4">
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon mb-2 text-danger icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
        <h3>¿Estas realmente seguro?</h3>
        <div className="text-secondary">Al continuar con esta accion se eliminara el ciudadano, Pero no sus ordenes.</div>
      </div>
      <div className="modal-footer">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <a href="#" className="btn w-100" data-bs-dismiss="modal">
                Cancelar
              </a>
            </div>
            <div className="col">
              <a href="#" className="btn btn-danger w-100" data-bs-dismiss="modal" onClick={(e) => delCiudadano(e, selectCiu, true)}>
                Confirmar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

  );
};

export default Content;
