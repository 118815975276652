import React, { useState, useEffect, useRef } from 'react';
import * as TablerIcons from '@tabler/icons-react'; // Importamos todos los íconos
import { IconSearch } from '@tabler/icons-react';

const IconBrowser = ({ onIconSelect }) => {
  // Estado de los íconos, cantidad visible y búsqueda
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleIcons, setVisibleIcons] = useState(7); // Cantidad de filas visibles (7 al inicio)
  const [filteredIcons, setFilteredIcons] = useState(Object.keys(TablerIcons)); // Listado inicial
  const svgRefs = useRef({});

  // Manejar el debounce para la búsqueda
  useEffect(() => {
    const handler = setTimeout(() => {
      const filtered = Object.keys(TablerIcons).filter(iconName =>
        iconName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredIcons(filtered);
    }, 1000); // 1 segundo de retraso

    return () => {
      clearTimeout(handler); // Limpiar el timeout si el componente se desmonta o cambia el término de búsqueda
    };
  }, [searchTerm]);

  // Manejar el scroll para cargar más íconos
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setVisibleIcons(prev => prev + 7); // Cargar 7 filas más (10 íconos por fila)
    }
  };

  // Función para manejar la selección del ícono
  const handleIconClick = (iconName) => {
    const svgElement = svgRefs.current[iconName];
    
    // Clonar el SVG y convertirlo a string
    if (svgElement) {
      const svgString = new XMLSerializer().serializeToString(svgElement);
      
      if (onIconSelect) {
        onIconSelect(svgString); // Pasar el SVG al padre
      }
      navigator.clipboard.writeText(svgString); 
    }
  };

  return (
    <>
      <div className="mb-3 m-2">
        <input
          type="text"
          className="form-control"
          placeholder="Search icon"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div
        className="icon-grid ms-1"
        onScroll={handleScroll}
        style={{ height: '400px', overflow: 'hidden auto' }} // Para que tenga scroll
      >
        <div className="row g-2">
          {filteredIcons.slice(0, visibleIcons * 10).map((iconName, index) => {
            const IconComponent = TablerIcons[iconName];
            return (
              <div
                key={index}
                className="col-auto" // 10 íconos por fila
                style={{ cursor: 'pointer' }}
                onClick={() => handleIconClick(iconName)}
              >
                <span className="avatar rounded">
                  <div ref={el => {
                    if (el) {
                      const svg = el.querySelector('svg'); 
                      svgRefs.current[iconName] = svg;
                    }
                  }}>
                    <IconComponent size={24} />
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default IconBrowser;
