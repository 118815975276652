import React, { useState } from 'react';
import QrScanner from 'react-qr-scanner';

const QRScanner = ({ onScan }) => {
  const [error, setError] = useState(null);
  const [hasPermission, setHasPermission] = useState(false); // Estado para el permiso de la cámara
  const [cameraReady, setCameraReady] = useState(false); // Estado para iniciar el escaneo una vez que la cámara esté lista

  const previewStyle = {
    height: 240,
    width: 320,
  };

  // Solicitar acceso a la cámara explícitamente al hacer clic en un botón
  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { exact: 'environment' }, // Usa 'environment' para la cámara trasera.
        },
      });
      console.log('Permiso concedido, flujo de cámara:', stream); // Verifica si se obtiene el acceso a la cámara.
      setHasPermission(true);
      setCameraReady(true);
    } catch (err) {
      console.error('No se pudo acceder a la cámara:', err);
      setError('Debes permitir el acceso a la cámara para escanear códigos QR.');
    }
  };
  const checkCameraPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'video' });
      if (permissionStatus.state === 'granted') {
        setHasPermission(true);
        setCameraReady(true);
      } else if (permissionStatus.state === 'denied') {
        setError('Acceso a la cámara denegado.');
      } else {
        requestCameraPermission();
      }
    } catch (err) {
      console.error('Error verificando los permisos de la cámara:', err);
    }
  };
  
  

  // Manejador de evento cuando se detecta un código QR
  const handleScan = (data) => {
    if (data) {
      onScan(data.text); // Llama a la función de callback pasando el texto del QR
    }
  };

  // Manejador de evento para errores de la cámara o del lector QR
  const handleError = (err) => {
    console.error(err);
    setError('Error al acceder a la cámara. Asegúrate de que esté habilitada.');
  };

  return (
    <div>
      <h2>Escanear Código QR</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      {!hasPermission ? (
        <div>
          <p>Debes permitir el acceso a la cámara para escanear códigos QR.</p>
          {/* Asegúrate de que el tipo del botón es "button" y no "submit" */}
          <button type="button" onClick={(e) => {
            e.preventDefault(); // Evita cualquier acción por defecto como un envío de formulario.
            requestCameraPermission();
          }}>
            Permitir Acceso a la Cámara
          </button>
        </div>
      ) : (
        <></>
      )}
      {cameraReady ? (
        <QrScanner
          delay={300} // Retraso entre cada escaneo (en ms)
          style={previewStyle} // Estilos del contenedor
          onError={handleError} // Manejo de errores
          onScan={handleScan} // Callback cuando se escanea un QR
        />
      ) : (
        <p>Esperando activación de la cámara...</p>
      )}

    </div>
  );
};

export default QRScanner;
