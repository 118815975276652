import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { parse, format, isThisYear } from 'date-fns';
import { es } from 'date-fns/locale';

const Content = ({ config, apiURL }) => {
  const currentYear = new Date().getFullYear();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);
  const [isLoader, setIsLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'desc' }); // Ordenación inicial por 'id' descendente
  const [selectCiu, setSelectCiu] = useState('');

  const [countdown, setCountdown] = useState(60);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  const fetchData = async (limit = 25) => {
    try {
      const jwt = Cookies.get('jwt');
      
      const initialResponse = await axios.post(apiURL, {
        type: '/munity/ciudadanos',
        option: '/list',
        limit: limit
      }, { headers: { Authorization: `Bearer ${jwt}` } });
  
      const initialData = initialResponse.data;
  
      if (initialData && initialData.result) {
        setData(initialData.result);
        setFilteredData(initialData.result);
        setTotalEntries(initialData.result.length);

        setIsLoader(false);
  
        const fullResponse = await axios.post(apiURL, {
          type: '/munity/ciudadanos',
          option: '/list'
        }, { headers: { Authorization: `Bearer ${jwt}` } });
  
        const fullData = fullResponse.data;
  
        if (fullData && fullData.result) {
          localStorage.setItem('ciudadanos_list', JSON.stringify(fullData.result));
          Cookies.set('ciudadanos_list', true, { expires: 1 / 24 });

          setData(fullData.result);
          setFilteredData(fullData.result);
          setTotalEntries(fullData.result.length);
        } else {
          console.error("No hay datos completos en la respuesta de la API.");
        }
      } else {
        console.error("No hay datos en la respuesta inicial de la API.");
      }
    } catch (error) {
      setData([]);
    }
  };

  useEffect(() => {
    const timingCookie = Cookies.get('ciudadanos_list');
    const ciudadanosData = JSON.parse(localStorage.getItem('ciudadanos_list'));

    if (timingCookie) {
      setData(ciudadanosData);
      setFilteredData(ciudadanosData);
      setTotalEntries(ciudadanosData.length);
      setIsLoader(false);
      setTimeout(() => {
        fetchData(99999);
      }, 1000);

    } else {
      fetchData();
    }

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          fetchData(99999999);
          return 60;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Ordena los datos automáticamente al cargar según 'sortConfig'
    const sortedData = [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
  }, [data, sortConfig]);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = data.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setFilteredData(filtered);
    setTotalEntries(filtered.length);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const getPaginationRange = () => {
    const maxVisiblePages = 10;
    const startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
    const adjustedStartPage = Math.max(Math.min(startPage, totalPages - maxVisiblePages + 1), 1);

    return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, i) => adjustedStartPage + i);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key !== key) {
      // Icono para columnas sin ordenar
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
          <path d="M8 9l4 -4l4 4" /><path d="M16 15l-4 4l-4 -4" />
        </svg>
      );
    } else if (sortConfig.direction === 'asc') {
      // Icono para orden ascendente
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
          <path d="M6 15l6 -6l6 6" />
        </svg>
      );
    } else {
      // Icono para orden descendente
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
          <path d="M6 9l6 6l6 -6" />
        </svg>
      );
    }
  };

  if (isLoader) {
    return (
      <div class="container container-slim py-4">
        <div class="mb-3">
        <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
        </div>
        <div class="text-center">
          <div class="text-secondary mb-3">Cargando ciudadanos...</div>
          <div class="progress progress-sm">
            <div class="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }

  
  const delCiudadano = async (e, id, confirm) => {
    e.preventDefault();
    if (confirm) {
      const jwt = Cookies.get('jwt');
  
      const formData = {
        id: id,
      };
  
      try {
        const response = await axios.post(apiURL, {
          type: '/munity/ciudadanos',
          option: '/eliminar',
          ...formData,
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });
    
        const result = response.data;
        Cookies.remove('ciudadanos_list');
        if (!result.valid) {
          Toast.fire({ icon: 'error', title: result.message });
          return;
        }
        Toast.fire({ icon: 'success', title: result.message });
        const updatedData = data.filter(ciudadano => ciudadano.id !== id);
        setData(updatedData);
        setFilteredData(updatedData);
      } catch (error) {
        Toast.fire({ icon: 'error', title: 'Error en la accion.' });
      }
    } else {
      const myModal = new window.bootstrap.Modal(document.getElementById('eliminar-ciudadano'));
      myModal.show();
      setSelectCiu(id);
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body border-bottom py-3">
          <div className="d-flex">
            <div className="text-secondary">
              Viendo
              <div className="mx-2 d-inline-block">
                <select className="form-control form-control-sm" id="total" value={entriesPerPage} onChange={handleEntriesPerPageChange}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={250}>250</option>
                  <option value={500}>500</option>
                </select>
              </div>
              filas
            </div>
            <div className="ms-auto text-secondary">
              Buscador:
              <div className="ms-2 d-inline-block">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  aria-label="Search invoice"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
        
          <table className="table card-table table-vcenter text-nowrap datatable">
            <thead>
              <tr>
                <th className="w-1"><input className="form-check-input m-0 align-middle" type="checkbox" aria-label="Select all invoices" /></th>
                <th className="w-1" onClick={() => handleSort('id')}>Nr. {renderSortIcon('id')}</th>
                <th onClick={() => handleSort('nombre')}>Titular {renderSortIcon('nombre')}</th>
                <th onClick={() => handleSort('domicilio')}>Domicilio y Teléfono {renderSortIcon('domicilio')}</th>
                <th onClick={() => handleSort('orders')}>Órdenes {renderSortIcon('orders')}</th>
                <th onClick={() => handleSort('since')}>Registrado {renderSortIcon('since')}</th>
              </tr>
            </thead>
            <tbody id="content_table">
              {currentEntries.map((item, index) => {

                const parsedDate = parse(item.since, 'yyyy-MM-dd HH:mm:ss', new Date());
                const dayOfWeek = format(parsedDate, 'EEEE', { locale: es });
                const formattedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1).toLowerCase();
                const month = format(parsedDate, 'MMMM', { locale: es });
                const formattedMonth = (month === 'enero') ? 'enero' : month.slice(0, 3).charAt(0).toUpperCase() + month.slice(1, 3);
                const includeYear = !isThisYear(parsedDate);
                const formattedYear = includeYear ? `, ${format(parsedDate, 'yyyy')}` : '';
                const dateSince = `${formattedDayOfWeek} ${format(parsedDate, 'd')} de ${formattedMonth}${formattedYear}`;

                return (
                <tr key={index}>
                  <td className="text-center dropdown">
                      <a href="#" className="btn-action" data-bs-toggle="dropdown" aria-expanded="true">
                          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /></svg>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end" data-popper-placement="bottom-end" style={{position: 'absolute', inset: '0px 0px auto auto', margin: 0, transform: 'translate(0px, 34px)'}}>
                          <Link to={`/ciudadanos/${item.id}`} className="dropdown-item">
                              Ver Más
                              <label className="m-0 ms-auto">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-history"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 8l0 4l2 2" /><path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" /></svg>
                              </label>
                          </Link>
                          <Link to={`/ciudadanos/${item.id}/editar`} className="dropdown-item">
                              Modificar
                              <label className="m-0 ms-auto">
                                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-pencil"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" /><path d="M13.5 6.5l4 4" /></svg>
                              </label>
                          </Link>
                          <a href="#" className="dropdown-item text-danger" onClick={(e) => delCiudadano(e, item.id, false)}>
                              Eliminar
                              <label className="m-0 ms-auto">
                                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                              </label>
                          </a>
                      </div>
                  </td>
                  <td>
                    <span className="text-secondary">{item.id}</span>
                  </td>
                  <td>
                    <Link to={`/ciudadanos/${item.id}`} className="text-primary">{item.nombre}</Link>
                    <br />{Number(item.dni).toLocaleString('es-ES')} ({currentYear - new Date(item.nacimiento).getFullYear()} años)
                  </td>
                  <td>
                    {item.domicilio}, {item.localidad}<br />
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-phone me-1 text-secondary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
                    {item.telefono ? item.telefono : 'N/P'}
                  </td>
                  <td>
                    <span class="badge bg-red-lt font-16 me-1">
                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-gas-station me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 11h1a2 2 0 0 1 2 2v3a1.5 1.5 0 0 0 3 0v-7l-3 -3" /><path d="M4 20v-14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14" /><path d="M3 20l12 0" /><path d="M18 7v1a1 1 0 0 0 1 1h1" /><path d="M4 11l10 0" /></svg>
                      {item.combustible}
                    </span>
                    <span class="badge bg-yellow-lt font-16">
                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-shopping-bag me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6.331 8h11.339a2 2 0 0 1 1.977 2.304l-1.255 8.152a3 3 0 0 1 -2.966 2.544h-6.852a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304z" /><path d="M9 11v-5a3 3 0 0 1 6 0v5" /></svg>
                      {item.compras}
                    </span>
                  </td>
                  <td>{dateSince}</td>
                </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="card-footer d-flex align-items-center table-responsive">
          <p className="m-0 text-secondary" id="pagination_info">
            Mostrando {indexOfFirstEntry + 1} a {Math.min(indexOfLastEntry, totalEntries)} de {totalEntries} entradas
          </p>
          <ul className="pagination m-0 ms-auto" id="pagination_list">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Prev</button>
            </li>
            {getPaginationRange().map(pageNumber => (
              <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(pageNumber)}>{pageNumber}</button>
              </li>
            ))}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
            </li>
          </ul>
        </div>
      </div>
      <p className='text-secondary'>Proxima solicitud en {countdown} segundos.</p>

<div className="modal modal-blur fade" id="eliminar-ciudadano" tabIndex={-1} role="dialog" aria-modal="true" style={{display: 'none'}}>
  <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div className="modal-content">
      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
      <div className="modal-status bg-danger" />
      <div className="modal-body text-center py-4">
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon mb-2 text-danger icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
        <h3>¿Estas realmente seguro?</h3>
        <div className="text-secondary">Al continuar con esta accion se eliminara el ciudadano, Pero no sus ordenes.</div>
      </div>
      <div className="modal-footer">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <a href="#" className="btn w-100" data-bs-dismiss="modal">
                Cancelar
              </a>
            </div>
            <div className="col">
              <a href="#" className="btn btn-danger w-100" data-bs-dismiss="modal" onClick={(e) => delCiudadano(e, selectCiu, true)}>
                Confirmar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    </div>
  );
};

export default Content;
