import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import Cookies from 'js-cookie';
import axios from 'axios';

const Content = ({ config, apiURL }) => {
  const { id } = useParams();
  const [datas, setDatas] = useState([]);
  const [items, setItems] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [members, setMembers] = useState([]);

  const fetchData = async () => {
    try {
      const jwt = Cookies.get('jwt');
      const response = await axios.post(apiURL, {
        type: '/munity/roles',
        option: '/overview',
      }, { headers: { Authorization: `Bearer ${jwt}` } });
      
      const configData = response.data;

      if (configData.result && Array.isArray(configData.result)) {
        localStorage.setItem('roles_list', JSON.stringify(configData.result));
        Cookies.set('roles_list', true, { expires: 1 / 24 });

        localStorage.setItem('members_list', JSON.stringify(configData.members));
        Cookies.set('members_list', true, { expires: 1 / 24 });

        const matchedData = configData.result.filter(role => role.id === id);
        setDatas(configData.result);
        setItems(Array.isArray(matchedData) && matchedData.length ? matchedData : configData.result);
        setMembers(configData.members);
      } else {
        console.error("No hay datos en la respuesta de la API.");
        setDatas([]);
        setItems([]);
      }
    } catch (error) {
      setDatas([]);
      setItems([]);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleEnd = (event) => {
    const { oldIndex, newIndex } = event;
    const newItems = [...items];
    const [movedItem] = newItems.splice(oldIndex, 1);
    newItems.splice(newIndex, 0, movedItem);

    setItems(newItems.map((item, index) => ({
      ...item,
      position: index + 1,
    })));
  };

  const addNewItem = async () => {
    try {
      const jwt = Cookies.get('jwt');
      const configResponse = await axios.post(apiURL, {
        type: '/munity/roles',
        option: '/crear',
      }, { headers: { Authorization: `Bearer ${jwt}` } });
  
      const configData = configResponse.data;

      if (configData && configData.result) {
        localStorage.setItem('roles_list', JSON.stringify(configData.result));
        Cookies.set('roles_list', true, { expires: 1 / 24 });

        localStorage.setItem('members_list', JSON.stringify(configData.members));
        Cookies.set('members_list', true, { expires: 1 / 24 });

        const matchedData = configData.result.filter(role => role.id === id);
        setDatas(configData.result);
        setItems(Array.isArray(matchedData) && matchedData.length ? matchedData : configData.result);
        setMembers(configData.members);
      } else {
        console.error("No hay datos en la respuesta de la API.");
      }
    } catch (error) {
      setItems([]);
    } finally {
      setIsLoader(false);
    }
  };

  if (isLoader) {
    return (
      <div className="container container-slim py-4">
        <div className="mb-3">
        <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
        </div>
        <div className="text-center">
          <div className="text-secondary mb-3">Cargando rol...</div>
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='page-body'>
      <div className="row row-cards mt-4">
        <div className="col-md-12">
          <div className="card sticky-top">
            <div className="card-header">
              <h3 className="card-title">
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon me-2 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8 9l4 -4l4 4" /><path d="M16 15l-4 4l-4 -4" /></svg>
                Roles
              </h3>
              <div className="card-actions">
                <a href="#" class="btn btn-primary btn-icon" onClick={() => addNewItem()}>
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M9 12h6" /><path d="M12 9v6" /></svg>
                </a>
              </div>
            </div>
            <div className="list-group list-group-flush">
              <ReactSortable
                list={items}
                setList={setItems}
                onEnd={handleEnd}
              >
                {Array.isArray(items) && items.map(item => (
                  <Link key={item.id} to={`/roles/${item.id}`} className={`list-group-item list-group-item-action ${item.id == id && "active"}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className={`icon me-2 text-${item.color}`}>
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" />
                    </svg>
                    {item.name}
                  </Link>
                ))}
              </ReactSortable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
