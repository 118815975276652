import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'; 
import Swal from 'sweetalert2';

const Content = ({ config, apiURL, fetchData }) => {
  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });
  
  const handleSubmit = async (e, opc) => {
    e.preventDefault();
  
    const jwt = Cookies.get('jwt');

    const formData = {
      nombre: e.target['nombre'].value,
      opc: opc
    };

    try {
      const response = await axios.post(apiURL, {
        type: '/munity/ciudadanos',
        option: '/opciones/crear',
        ...formData,
      }, { headers: { Authorization: `Bearer ${jwt}`, }, });
  
      const result = response.data;
      console.log(result);
      if (!result.valid) {
        Toast.fire({ icon: 'error', title: result.message });
        return;
      }
      Toast.fire({ icon: 'success', title: result.message });
      fetchData();
    } catch (error) {
      Toast.fire({ icon: 'error', title: 'Error en la accion.' });
    }
  };

  return (
    <>
    <div className="modal modal-blur fade" id="new-localidad" tabIndex={-1} style={{display: 'none'}} aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <form id="create_ciudadanos" method="POST" className="modal-content" onSubmit={(e) => handleSubmit(e, 'localidad')}>
          <div class="modal-header">
            <h5 class="modal-title">Nueva localidad</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="mb-3">
              <label class="form-label">Nombre</label>
              <input type="text" class="form-control" name="nombre" placeholder="Nombre de la localidad." />
            </div>

          </div>
          <div class="modal-footer">
            <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
              Cancelar
            </a>
            <button type="submit" class="btn btn-primary ms-auto" data-bs-dismiss="modal">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 5l0 14"></path><path d="M5 12l14 0"></path></svg>
              Agregar
            </button>
          </div>
        </form>
      </div>
    </div>
    <div className="modal modal-blur fade" id="new-opcion" tabIndex={-1} style={{display: 'none'}} aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <form id="create_ciudadanos" method="POST" className="modal-content" onSubmit={(e) => handleSubmit(e, 'opcion')}>
          <div class="modal-header">
            <h5 class="modal-title">Nueva opcion</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="mb-3">
              <label class="form-label">Nombre</label>
              <input type="text" class="form-control" name="nombre" placeholder="Nombre de la opcion." />
            </div>

          </div>
          <div class="modal-footer">
            <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
              Cancelar
            </a>
            <button type="submit" class="btn btn-primary ms-auto" data-bs-dismiss="modal">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 5l0 14"></path><path d="M5 12l14 0"></path></svg>
              Agregar
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
};

export default Content
