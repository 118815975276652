import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';

import { UserContext } from '../components/UserProvider.js';

import Listado from './rentas/Listado.js';
import Agregar from './rentas/Agregar.js';
import Servicios from './rentas/Servicios.js';
import Editar from './rentas/Editar.js';
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

import { PermissionsContext } from '../components/hasPermissions.js';

const Content = ({ config, apiURL }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const { userLogged, logoutUser } = useContext(UserContext);
  const location = useLocation();
  const { id, type } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [loaderHistoryIngresos, setLoaderHistoryIngresos] = useState(true);
  const [loaderHistoryEgresos, setLoaderHistoryEgresos] = useState(true);

  const [item, setItem] = useState(true);
  
  const [selectCiu, setSelectCiu] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [redirect, setRedirect] = useState(false);

  const [countdown, setCountdown] = useState(60);

  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  useEffect(() => {
    const checkPermissions = () => {
      if (!hasPermission('m.rentas') && id !== 'agregar') {
        navigate(-1); 
      } else if (!hasPermission('m.rentas.agregar') && id === 'agregar') {
        navigate(-1);
      } else if (!hasPermission('m.rentas.modificar') && id && type === 'editar') {
        navigate(-1);
      } else {
        setLoading(false);
      }
    };

    checkPermissions();
  }, [hasPermission, navigate, id]);


  const fetchData = async (limit = 25) => {
    try {
      const jwt = Cookies.get('jwt');
      
      const initialResponse = await axios.post(apiURL, {
        type: '/munity/rentas',
        option: '/list',
        limit: limit
      }, { headers: { Authorization: `Bearer ${jwt}` } });
  
      const initialData = initialResponse.data;
  
      if (initialData && initialData.result) {
        setData(initialData.result);
        setFilteredData(initialData.result);
        setTotalEntries(initialData.result.length);

        setLoaderHistoryIngresos(false);
  
        const fullResponse = await axios.post(apiURL, {
          type: '/munity/rentas',
          option: '/list'
        }, { headers: { Authorization: `Bearer ${jwt}` } });
  
        const fullData = fullResponse.data;
  
        if (fullData && fullData.result) {
          localStorage.setItem('rentas_list', JSON.stringify(fullData.result));
          Cookies.set('rentas_list', true, { expires: 1 / 24 });

          setData(fullData.result);
          setFilteredData(fullData.result);
          setTotalEntries(fullData.result.length);
        } else {
          console.error("No hay datos completos en la respuesta de la API.");
        }
      } else {
        console.error("No hay datos en la respuesta inicial de la API.");
      }
    } catch (error) {
      setData([]);
    }
  };

  useEffect(() => {
    const timingCookie = Cookies.get('rentas_list');
    const ciudadanosData = JSON.parse(localStorage.getItem('rentas_list'));

    if (timingCookie) {
      setData(ciudadanosData);
      setFilteredData(ciudadanosData);
      setTotalEntries(ciudadanosData.length);
      setLoaderHistoryIngresos(false);
      setTimeout(() => {
        fetchData(99999);
      }, 1000);

    } else {
      fetchData();
    }

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          fetchData(99999999);
          return 60;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  
  
  const handleCheckboxChange = (item, redirect = false) => {
    setOptionList((prevOptions) => {
      if (prevOptions.some(selected => selected.id === item.id)) {
        return prevOptions.filter(selected => selected.id !== item.id);
      } else {
        return [...prevOptions, item];
      }
    });

    setRedirect(redirect);
  };
  
  useEffect(() => {
    if (redirect) {
      localStorage.setItem('rentas_listed', JSON.stringify(optionList));
      
      const button = document.querySelector("#impresion");
      const originalText = button ? button.innerText : "";
      if (button) {
        button.innerText = "Cargando...";
      }
  
      const newWindow = window.open(`${config.uri}/pdf/rentas`, "_blank");
  
      if (newWindow) {
        newWindow.onload = () => {
          if (button) {
            button.innerText = "Terminado.";
          }
          setTimeout(() => {
            if (button) {
              button.innerText = originalText;
            }
            setRedirect(false);
          }, 2000);
        };
      }
    }
  }, [optionList, redirect]);

  if (loading) {
    return null;
  }

  const calculateTotal = (type) => {
    return data
      .filter((entry) => entry.type === type && entry.status === 1)
      .reduce((sum, entry) => sum + parseFloat(entry.amount), 0);
  };
  
  const groupByYearAndMonth = (type) => {
    const filteredData = data.filter((entry) => entry.type === type && entry.status === 1);
    const grouped = {};
  
    filteredData.forEach(({ since, amount }) => {
      const [year, month] = since.split(' ')[0].split('-');
      if (!grouped[year]) {
        grouped[year] = {};
      }
      if (!grouped[year][month]) {
        grouped[year][month] = 0;
      }
      grouped[year][month] += parseFloat(amount);
    });
  
    return grouped;
  };
  
  const renderTablesByYear = (groupedData) => {
    return Object.keys(groupedData).map((year) => (
      <div key={year}>
        <h3 className="text-center">{year}</h3>
        <table className="table card-table table-vcenter">
          <thead>
            <tr>
              <th>Mes</th>
              <th>Cantidad</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedData[year])
              .sort((a, b) => parseInt(b) - parseInt(a)) 
              .map((month) => (
                <tr key={`${year}-${month}`}>
                  <td>{getMonthName(month)}</td>
                  <td>${groupedData[year][month].toLocaleString('es-ES')}</td>
                  <td><a href="#" className="link-primary">Descargar</a></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    ));
  };
  
  const getMonthName = (monthNumber) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return months[parseInt(monthNumber, 10) - 1];
  };
  
  const groupedIngresos = groupByYearAndMonth('i');
  const totalIngresos = calculateTotal('i');
  const groupedEgresos = groupByYearAndMonth('e');
  const totalEgresos = calculateTotal('e');
  const totalCaja = totalIngresos - totalEgresos;

  const calculateTotalForToday = (type) => {
    const today = new Date().toISOString().split('T')[0];
  
    return data
      .filter((entry) => entry.type === type && entry.since.startsWith(today) && entry.status === 1) 
      .reduce((sum, entry) => sum + parseFloat(entry.amount), 0);
  };

  const totalIngresosHoy = calculateTotalForToday('i'); 
  const totalEgresosHoy = calculateTotalForToday('e'); 

  
  const optionUse = async (e, item) => {
    e.preventDefault();
    setItem(item);
    
    const myModal = new window.bootstrap.Modal(document.getElementById('buscador'));
    myModal.show();
  }

  
  const setPayment = async (e, item) => {
    e.preventDefault();

    const jwt = Cookies.get('jwt');
    
    try {
      const response = await axios.post(apiURL, {
        type: '/munity/rentas',
        option: '/set/payment',
        id: item
      }, { headers: { Authorization: `Bearer ${jwt}`, }, });
  
      const result = response.data;
      if (!result.valid) {
        Toast.fire({ icon: 'error', title: result.message });
        return;
      }
      Toast.fire({ icon: 'success', title: result.message });
      fetchData();
      if (!id) {
        setItem(prevItem => ({
          ...prevItem,
          ...result.updatedData 
        }));
      }
    } catch (error) {
      Toast.fire({ icon: 'error', title: 'Error en la accion.' });
    }
  }

  const delCiudadano = async (e, id, confirm) => {
    e.preventDefault();

    if (!hasPermission('m.rentas.eliminar')) {
      return null;
    }

    if (confirm) {
      const jwt = Cookies.get('jwt');
  
      const formData = {
        id: id,
      };
  
      try {
        const response = await axios.post(apiURL, {
          type: '/munity/rentas',
          option: '/eliminar',
          ...formData,
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });
    
        const result = response.data;
        Cookies.remove('rentas_list');
        if (!result.valid) {
          Toast.fire({ icon: 'error', title: result.message });
          return;
        }
        Toast.fire({ icon: 'success', title: result.message });
        const updatedData = data.filter(ciudadano => ciudadano.id !== id);
        setData(updatedData);
        setFilteredData(updatedData);
      } catch (error) {
        Toast.fire({ icon: 'error', title: 'Error en la accion.' });
      }
    } else {
      const myModal = new window.bootstrap.Modal(document.getElementById('eliminar-ciudadano'));
      myModal.show();
      setSelectCiu(id);
    }
  };

  
  const generateEgreso = async (e) => {
    e.preventDefault();

    if (!hasPermission('m.rentas.egreso')) {
      return null;
    }

      const jwt = Cookies.get('jwt');
  
      const formData = {
        monto: e.target['monto'].value,
        creator: userLogged.id,
      };
  
      try {
        const response = await axios.post(apiURL, {
          type: '/munity/rentas',
          option: '/egresar',
          ...formData,
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });
    
        const result = response.data;
        Cookies.remove('rentas_list');
        if (!result.valid) {
          Toast.fire({ icon: 'error', title: result.message });
          return;
        }
        Toast.fire({ icon: 'success', title: result.message });
        fetchData();
      } catch (error) {
        Toast.fire({ icon: 'error', title: 'Error en la accion.' });
      }
  };


  if (loaderHistoryIngresos) {
    return (
          <div className="container container-slim py-4 mt-5">
            <div className="text-center">
              <div className="mb-3">
                <Link to={`${config.uri}`} className="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
              </div>
              <div className="text-secondary mb-3">Cargando interfaz...</div>
              <div className="progress progress-sm">
                <div className="progress-bar progress-bar-indeterminate"></div>
              </div>
            </div>
          </div>
    );
  }

  return (
    <div className='mt-5'>

  <ul className="nav nav-bordered mb-4">
    <li className="nav-item d-md-none">
      <Link className={`nav-link ${id == 'servicios' ? 'active' : ''}`} aria-current="page" to={`/rentas/servicios`}>
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-license me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 21h-9a3 3 0 0 1 -3 -3v-1h10v2a2 2 0 0 0 4 0v-14a2 2 0 1 1 2 2h-2m2 -4h-11a3 3 0 0 0 -3 3v11" /><path d="M9 7l4 0" /><path d="M9 11l4 0" /></svg>
        Servicios
      </Link>
    </li>

    <li className="nav-item">
      <Link className={`nav-link ${!id ? 'active' : ''}`} aria-current="page" to={`/rentas`}>
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-license me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 21h-9a3 3 0 0 1 -3 -3v-1h10v2a2 2 0 0 0 4 0v-14a2 2 0 1 1 2 2h-2m2 -4h-11a3 3 0 0 0 -3 3v11" /><path d="M9 7l4 0" /><path d="M9 11l4 0" /></svg>
        Listado
      </Link>
    </li>

    <li className="nav-item">
      <Link className={`nav-link ${id == 'agregar' ? 'active' : ''}`} to={`/rentas/agregar`}>
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-users-plus me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4c.96 0 1.84 .338 2.53 .901" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M16 19h6" /><path d="M19 16v6" /></svg>
        Nuevo Recibo
      </Link>
    </li>
  </ul>
  {!id && id !== 'agregar' && (
  <div className="row mb-2">
    <div className="col-12 mb-2">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title mb-1">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-cash-register text-primary me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M21 15h-2.5c-.398 0 -.779 .158 -1.061 .439c-.281 .281 -.439 .663 -.439 1.061c0 .398 .158 .779 .439 1.061c.281 .281 .663 .439 1.061 .439h1c.398 0 .779 .158 1.061 .439c.281 .281 .439 .663 .439 1.061c0 .398 -.158 .779 -.439 1.061c-.281 .281 -.663 .439 -1.061 .439h-2.5" /><path d="M19 21v1m0 -8v1" /><path d="M13 21h-7c-.53 0 -1.039 -.211 -1.414 -.586c-.375 -.375 -.586 -.884 -.586 -1.414v-10c0 -.53 .211 -1.039 .586 -1.414c.375 -.375 .884 -.586 1.414 -.586h2m12 3.12v-1.12c0 -.53 -.211 -1.039 -.586 -1.414c-.375 -.375 -.884 -.586 -1.414 -.586h-2" /><path d="M16 10v-6c0 -.53 -.211 -1.039 -.586 -1.414c-.375 -.375 -.884 -.586 -1.414 -.586h-4c-.53 0 -1.039 .211 -1.414 .586c-.375 .375 -.586 .884 -.586 1.414v6m8 0h-8m8 0h1m-9 0h-1" /><path d="M8 14v.01" /><path d="M8 17v.01" /><path d="M12 13.99v.01" /><path d="M12 17v.01" /></svg>
            Total en Caja
          </h3>
          <p className="text-secondary font-18 mb-0">${totalCaja.toLocaleString('es-ES')}</p>
          <a href="#" className='text-primary' data-bs-toggle="modal" data-bs-target="#egreso">Realizar un Egreso</a>
        </div>
      </div>
    </div>

    <div className="col-sm-12 col-md-6 col-lg-6 mb-2">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title mb-1">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-cash-register text-primary me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M21 15h-2.5c-.398 0 -.779 .158 -1.061 .439c-.281 .281 -.439 .663 -.439 1.061c0 .398 .158 .779 .439 1.061c.281 .281 .663 .439 1.061 .439h1c.398 0 .779 .158 1.061 .439c.281 .281 .439 .663 .439 1.061c0 .398 -.158 .779 -.439 1.061c-.281 .281 -.663 .439 -1.061 .439h-2.5" /><path d="M19 21v1m0 -8v1" /><path d="M13 21h-7c-.53 0 -1.039 -.211 -1.414 -.586c-.375 -.375 -.586 -.884 -.586 -1.414v-10c0 -.53 .211 -1.039 .586 -1.414c.375 -.375 .884 -.586 1.414 -.586h2m12 3.12v-1.12c0 -.53 -.211 -1.039 -.586 -1.414c-.375 -.375 -.884 -.586 -1.414 -.586h-2" /><path d="M16 10v-6c0 -.53 -.211 -1.039 -.586 -1.414c-.375 -.375 -.884 -.586 -1.414 -.586h-4c-.53 0 -1.039 .211 -1.414 .586c-.375 .375 -.586 .884 -.586 1.414v6m8 0h-8m8 0h1m-9 0h-1" /><path d="M8 14v.01" /><path d="M8 17v.01" /><path d="M12 13.99v.01" /><path d="M12 17v.01" /></svg>
            Ingreso diario
            <a href="#" className='text-primary ms-2' data-bs-toggle="modal" data-bs-target="#historial-ingresos">(Ver Historial)</a>
          </h3>
          <p className="text-secondary font-18">${totalIngresosHoy.toLocaleString('es-ES')}</p>
        </div>
      </div>
    </div>
    <div className="col-sm-12 col-md-6 col-lg-6">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title mb-1">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-credit-card-pay text-primary me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 19h-6a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5" /><path d="M3 10h18" /><path d="M16 19h6" /><path d="M19 16l3 3l-3 3" /><path d="M7.005 15h.005" /><path d="M11 15h2" /></svg>
            Egreso diario
            {hasPermission('m.rentas.egresos') && (<a href="#" className='text-primary ms-2' data-bs-toggle="modal" data-bs-target="#historial-egresos">(Ver Historial)</a>)}
          </h3>
          <p className="text-secondary font-18">${totalEgresosHoy.toLocaleString('es-ES')}</p>
        </div>
      </div>
    </div>

  </div>
  )}

  <div className="row">
    
    {id == 'agregar' ? <Agregar config={config} apiURL={apiURL} setPayment={setPayment} handleCheckboxChange={handleCheckboxChange} /> : 
    id == 'servicios' ? <Servicios config={config} apiURL={apiURL} col={12} /> : 
    id == 'editar' ? <Editar config={config} apiURL={apiURL} setPayment={setPayment} handleCheckboxChange={handleCheckboxChange} /> :
      (
        <>
        <Servicios config={config} apiURL={apiURL} />
        <Listado config={config} apiURL={apiURL} countdown={countdown} optionList={optionList} handleCheckboxChange={handleCheckboxChange} setPayment={setPayment} optionUse={optionUse} data={data} setData={setData} filteredData={filteredData} setFilteredData={setFilteredData} totalEntries={totalEntries} setTotalEntries={setTotalEntries} fetchData={fetchData} delCiudadano={delCiudadano} setSelectCiu={setSelectCiu} />
        </>
      )
    }
    
  </div>


    <div className="modal modal-blur fade" id="historial-ingresos" tabIndex={-1} style={{display: 'none'}} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Historial de Ingresos</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          </div>
          <div className="modal-body">
            <div className="card mb-3">
              <div className="card-body p-4 py-5 text-center">
                <h3 className="mb-0">Ingresos totales</h3>
                <p className="text-secondary">${totalIngresos.toLocaleString('es-ES')}</p>
              </div>
            </div>
            {renderTablesByYear(groupedIngresos)}
          </div>
        </div>
      </div>
    </div>

    {hasPermission('m.rentas.egresos') && (
    <div className="modal modal-blur fade" id="historial-egresos" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Historial de egresos</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="card mb-3">
              <div className="card-body p-4 py-5 text-center">
                <h3 className="mb-0">Egreso total</h3>
                <p className="text-secondary">${totalEgresos.toLocaleString('es-ES')}</p>
              </div>
            </div>
            {renderTablesByYear(groupedEgresos)}
          </div>
        </div>
      </div>
    </div>
    )}

        {hasPermission('m.rentas.eliminar') && (
          <div className="modal modal-blur fade" id="eliminar-ciudadano" tabIndex={-1} role="dialog" aria-modal="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
              <div className="modal-content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                <div className="modal-status bg-danger" />
                <div className="modal-body text-center py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon mb-2 text-danger icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
                  <h3>¿Estas realmente seguro?</h3>
                  <div className="text-secondary">Los recibos solamente se eliminaran si no fueron pagados.</div>
                </div>
                <div className="modal-footer">
                  <div className="w-100">
                    <div className="row">
                      <div className="col">
                        <a href="#" className="btn w-100" data-bs-dismiss="modal">
                          Cancelar
                        </a>
                      </div>
                      <div className="col">
                        <a href="#" className="btn btn-danger w-100" data-bs-dismiss="modal" onClick={(e) => delCiudadano(e, selectCiu, true)}>
                          Confirmar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {hasPermission('m.rentas.egreso') && (
          <div className="modal modal-blur fade" id="egreso" tabIndex={-1} role="dialog" aria-modal="true" style={{display: 'none'}}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <form className="modal-content" method="POST" onSubmit={(e) => generateEgreso(e)} style={{background: 'transparent', boxShadow: 'none'}}>
              <div className='card mb-3'>
                <div class={`card-status-bottom bg-red`} />
                <div className="modal-body text-center py-4">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon text-red icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
                  <h3>Para realizar el egreso, Incluya el monto.</h3>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header">
                  <h5 className="modal-title">Egresar dinero.</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="card-body">
                  <span className='mb-2 required'>Monto</span>
                  <input type="number" className='form-control' defaultValue={totalCaja} max={totalCaja} placeholder='3000' name="monto" required />

                </div>
              </div>
              
              <div className="d-flex align-items-center mb-3">
                <button type="submit" className='btn btn-primary w-100' data-bs-dismiss="modal">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-credit-card-pay me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 19h-6a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5" /><path d="M3 10h18" /><path d="M16 19h6" /><path d="M19 16l3 3l-3 3" /><path d="M7.005 15h.005" /><path d="M11 15h2" /></svg>
                  Confirmar
                </button>
              </div>
              <div className="alert alert-info bg-theme" role="alert">
                <div className="d-flex">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M12 9h.01" /><path d="M11 12h1v4h1" /></svg>
                  </div>
                  <div>
                    <h4 className="alert-title">Nota</h4>
                    <div className="text-secondary">Una ves realizado el egreso, Dirijase a 'Hacienda' para que hacienda imprima y selle el recibo.</div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
        )}

    {item.id && (
    <div className="modal modal-blur fade" id="buscador" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content" style={{background: 'transparent', boxShadow: 'none'}}>
          <div className='card mb-3'>
            <div class={`card-status-bottom bg-${item.status == 1 ? 'green' : 'yellow'}`} />
            <div className="modal-body text-center py-4">
              {item.status == 1 ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon mb-2 text-green icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path><path d="M9 12l2 2l4 -4"></path></svg>
              ) : (
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon text-yellow icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
              )}
              <h3>{item.type == 'i' ? item.status == 1 ? "Recibo Pagado" : "Recibo Pendiente" : item.status == 1 ? "Egreso Validado" : "Egreso sin Validar"}</h3>
            </div>
          </div>
          <div className="card mb-2">
            <div className="card-header">
              <h5 className="modal-title">Detalles del Recibo nº{item.id}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between mb-1">
                <div className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                  <span>Ciudadano</span>
                </div>
                <span className='text-primary'>{item.titular}</span>
              </div>
              <div className="d-flex justify-content-between mb-1">
                <div className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                  <span>DNI</span>
                </div>
                <span className='text-secondary'>{item.dni}</span>
              </div>
              <div className="d-flex justify-content-between mb-1">
                <div className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                  <span>Servicio</span>
                </div>
                <span className='text-secondary'>{item.service}</span>
              </div>
              <div className="d-flex justify-content-between mb-1">
                <div className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                  <span>Monto</span>
                </div>
                <span className='text-primary'>${Number(item.amount).toLocaleString('es-ES')}</span>
              </div>
              <div className="d-flex justify-content-between mb-1">
                <div className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
                  <span>Tipo</span>
                </div>
                <span className={`text-${item.type == 'i' ? 'success' : 'danger'}`}>{item.type == 'i' ? 'Ingreso' : 'Egreso'}</span>
              </div>
              <div className="">
                <span>Observacion:</span><br />
                <span className='text-secondary'>{item.reason}</span>
              </div>
            </div>
          </div>
          
          {item.type == 'i' && (
          <div className="d-flex align-items-center mb-3">
            <button className='btn btn-primary w-100' data-bs-dismiss="modal" onClick={(e) => {handleCheckboxChange(item, false)}}>
              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-printer me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" /><path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" /><path d="M7 13m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z" /></svg>
              Imprimir
            </button>
            {item.status == 0 && item.type == 'i' && (
              <button className='btn btn-success w-100 ms-1' onClick={(e) => setPayment(e, item.id)}>
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-checklist me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8" /><path d="M14 19l2 2l4 -4" /><path d="M9 8h4" /><path d="M9 12h2" /></svg>
                Pagado
              </button>
            )}
          </div>
          )}
          {item.type == 'i' ? (
          <div className="alert alert-info bg-theme" role="alert">
            <div className="d-flex">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M12 9h.01" /><path d="M11 12h1v4h1" /></svg>
              </div>
              <div>
                <h4 className="alert-title">Nota</h4>
                <div className="text-secondary">Los recibos con el estado 'pagado', Ya no tendran las opciones de 'eliminar y modificar'.</div>
              </div>
            </div>
          </div>
          ) : (
          <div className="alert alert-info bg-theme" role="alert">
            <div className="d-flex">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M12 9h.01" /><path d="M11 12h1v4h1" /></svg>
              </div>
              <div>
                <h4 className="alert-title">Nota</h4>
                <div className="text-secondary">
                  Los egresos son gestionados por <span className="text-primary">Hacienda</span>. Debe dirigirse a <span className="text-primary">Hacienda</span> para entregar el <span className="text-primary">dinero</span> correspondiente al monto y esperar a que <span className="text-primary">Hacienda</span> le entregue un recibo confirmando que ha recibido el <span className="text-primary">dinero</span> correspondiente.
                </div>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
    )}
  </div>
  );
};

export default Content;
