import React, { useState, useEffect } from 'react';

const Formulario = ({ arts, setArts }) => {
  const [cantidad, setCantidad] = useState('');
  const [articulo, setArticulo] = useState('');

  const isFormValid = cantidad && articulo;

  const agregarNuevo = () => {
    if (isFormValid) {
        setArts([
        ...arts,
        { art: articulo, cant: cantidad }
      ]);
      setCantidad('');
      setArticulo('');

      console.log(arts);
    }
  };

  const eliminarItem = (index) => {
    const newItems = arts.filter((_, i) => i !== index);
    setArts(newItems);
  };

  return (
    <div>
      
    <div className="card card-borderless mb-3">
      <div className="card-body">
        <h3 className="card-title">Articulo</h3>
        <div>
          <div className="row g-2">
            <div className="col-auto">
            <input
              type="number"
              className="form-control"
              placeholder="5"
              min={1}
              max={999999}
              id="cantidad"
              value={cantidad}
              onChange={(e) => setCantidad(e.target.value)}
            />
            </div>
            <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="10 KG de Pan"
              id="articulo"
              value={articulo}
              onChange={(e) => setArticulo(e.target.value)}
            />
            </div>
            <div className="col-auto">
              <a href="#" className={`btn btn-icon ${!isFormValid ? 'disabled' : ''}`}
              aria-label="Button"
              onClick={agregarNuevo}>
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

      {/* Tabla para mostrar los artículos */}
      <div className="table-responsive">
        <table className="table mb-0">
          <thead>
            <tr>
              <th>Cantidad</th>
              <th>Articulo</th>
              <th>Accion</th>
            </tr>
          </thead>
          <tbody>
            {arts.length === 0 ? (
              <tr>
                <td colSpan="3">No hay artículos agregados</td>
              </tr>
            ) : (
                arts.map((item, index) => (
                <tr key={index}>
                  <td>{item.cant}</td>
                  <td>{item.art}</td>
                  <td>
                    <button type="button" className="btn-action" onClick={() => eliminarItem(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="icon">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M18 6l-12 12" />
                        <path d="M6 6l12 12" />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Formulario;
