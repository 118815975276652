import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { parse, format, isThisYear } from 'date-fns';
import { es } from 'date-fns/locale';
import Swal from 'sweetalert2';
import { PermissionsContext } from '../../components/hasPermissions.js';
import { UserContext } from '../../components/UserProvider.js';

const Content = ({ config, apiURL, fetchMonto, selected }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const { userLogged, logoutUser } = useContext(UserContext);
  const [viewModal, setViewModal] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]); // Guarda solo los IDs seleccionados
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalEntries, setTotalEntries] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [countdown, setCountdown] = useState(60);
  
  const [activeSelect, setActiveSelect] = useState([]);

  const itemsPerPage = 25;
  const maxPageButtons = 10;
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  const fetchData = async (limit = 25) => {
    try {
      const jwt = Cookies.get('jwt');
      const response = await axios.post(apiURL, {
        type: '/munity/hacienda',
        option: '/list/combustible',
        limit: limit
      }, { headers: { Authorization: `Bearer ${jwt}` } });

      const initialData = response.data;

      if (initialData && initialData.result) {
        setData(initialData.result);
        setFilteredData(initialData.result);
        setTotalEntries(initialData.result.length);
        setLoading(false);
        fetchMonto();

        setSelectedIds(selectedIds => {
          const validIds = initialData.result.map(item => item.id);
          return selectedIds.filter(id => validIds.includes(id));
        });
      } else {
        console.error("No hay datos en la respuesta de la API.");
      }
    } catch (error) {
      setData([]);
    }
  };

  useEffect(() => {
    fetchData(100); // Cargar todos los registros al iniciar
    setTimeout(() => {
        fetchData(99999999);
    }, 1000);
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          fetchData(99999999); // Recargar todos los registros cada 60 segundos
          return 60;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleCardClick = (item) => {
    let filtered = 0;
    if (selectedIds.includes(item.id)) {
      setSelectedIds(selectedIds.filter(id => id !== item.id));
    } else {
      filtered = 1;
      setActiveSelect(item);
      setSelectedIds([...selectedIds, item.id]);
    }

    //if (filtered && viewModal) {
    //    const myModal = new window.bootstrap.Modal(document.getElementById('viewinfo'));
    //    myModal.show();
    //}
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filtered = data.filter(item => item.id.toString().includes(e.target.value));
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const calculateMonto = () => {
    return filteredData
      .filter(item => selectedIds.includes(item.id))
      .reduce((acc, item) => {
        const costoArray = item.costo.includes('#') ? item.costo.split('#') : [item.costo];
        const monto = parseFloat(costoArray[0]) || 0;
        return acc + monto;
      }, 0)
      .toFixed(2);
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    const end = Math.min(totalPages, start + maxPageButtons - 1);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };
  
  const updateList = async (e) => {
    e.preventDefault();
    try {
      const jwt = Cookies.get('jwt');
      const monto = calculateMonto();
      const response = await axios.post(apiURL, {
        type: '/munity/hacienda',
        option: '/list/combustible/update',
        ids: selectedIds,
        monto: monto,
        creator: userLogged.id,
      }, { headers: { Authorization: `Bearer ${jwt}` } });

      const initialData = response.data;

      if (initialData) {
        fetchData(99999999);
        setSelectedIds([]);
        Toast.fire({ icon: 'success', title: initialData.message });
      } else {
        Toast.fire({ icon: 'error', title: initialData.message });
        console.error("No hay datos en la respuesta de la API.");
      }
    } catch (error) {
      console.error("No hay datos en la respuesta de la API..");
    }
  };

  if (loading) {
    return (
      <div className="container container-slim py-4 mt-5">
        <div className="text-center">
          <div className="mb-3">
            <Link to={`${config.uri}`} className="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
          </div>
          <div className="text-secondary mb-3">Cargando interfaz...</div>
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h4 className="mb-0">Ordenes de Combustible (Sin pagar)</h4>
      <p className="text-secondary">
        Presione la orden a pagar, Y simplemente presione el boton 'Confirmar'. ¿No la encuentras? Escribela en el buscador.
      </p>

      <div className="mb-3">
        <label className="form-label">Buscador por ID:</label>
        <input 
          type="text" 
          className="form-control" 
          placeholder="Buscar..." 
          value={searchTerm} 
          onChange={handleSearchChange} 
        />
      </div>

      <div className="alert alert-info bg-theme" role="alert">
        <div className="d-flex">
          <div>
            <h4 className="alert-title">Informacion</h4>
            <div className="text-secondary">
              Tienes {selectedIds.length} orden(es) seleccionada(s) con el monto de ${calculateMonto()} a pagar.
            </div>
            <button disabled={selectedIds.length === 0} className="btn btn-primary mt-2" onClick={(e) => updateList(e)}>Confirmar</button>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        
        <table className="table card-table table-vcenter text-nowrap datatable">
          <thead>
            <tr>
              <th className="w-1"><input className="form-check-input m-0 align-middle" type="checkbox" aria-label="Select all invoices" /></th>
              <th className="w-1">Nr.</th>
              <th>Titular</th>
              <th>Combustible</th>
              <th>Estacion</th>
              <th>Observacion</th>
              <th>Registrado</th>
            </tr>
          </thead>
          <tbody id="content_table">
        {totalEntries > 0 ? (
        filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item, index) => {
          const combustible = typeof item.combustible === 'string' ? item.combustible.split('#') : [];
          const costo = typeof item.costo === 'string' ? item.costo.split('#') : [];
          const parsedDate = parse(item.since, 'yyyy-MM-dd HH:mm:ss', new Date());
          const dayOfWeek = format(parsedDate, 'EEEE', { locale: es });
          const formattedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1).toLowerCase();
          const month = format(parsedDate, 'MMMM', { locale: es });
          const formattedMonth = (month === 'enero') ? 'enero' : month.slice(0, 3).charAt(0).toUpperCase() + month.slice(1, 3);
          const includeYear = !isThisYear(parsedDate);
          const formattedYear = includeYear ? `, ${format(parsedDate, 'yyyy')}` : '';
          const dateSince = `${formattedDayOfWeek} ${format(parsedDate, 'd')} de ${formattedMonth}${formattedYear}`;
          return (
          <tr key={index} className={selectedIds.includes(item.id) ? 'border-primary' : ''} onClick={() => handleCardClick(item)}>
                  <td className="text-center dropdown">
                    {selectedIds.includes(item.id) ? (
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-checkbox text-success"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 11l3 3l8 -8" /><path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" /></svg>
                    ) : (
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-click"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12l3 0" /><path d="M12 3l0 3" /><path d="M7.8 7.8l-2.2 -2.2" /><path d="M16.2 7.8l2.2 -2.2" /><path d="M7.8 16.2l-2.2 2.2" /><path d="M12 12l9 3l-4 2l-2 4l-3 -9" /></svg>
                    )}
                  </td>
                  <td>
                    <span className="text-secondary">{item.id}</span>
                  </td>
                  <td>
                    <span className="text-primary">{item.nombre}</span>
                    <br />{Number(item.dni).toLocaleString('es-ES')}
                  </td>
                  <td>
                    <span className="text-primary">{combustible[0]}</span><br />
                    {combustible[1]} Lts.
                  </td>
                  <td>
                    <span className="text-primary">{item.estacion}</span><br />${Number(costo[0]).toLocaleString('es-ES')}
                  </td>
                  <td>
                    <span className="text-primary">{item.tipo}</span><br />{item.razon.length > 20 ? `${item.razon.slice(0, 20)}...` : item.razon}
                  </td>
                  <td>{dateSince}</td>
                </tr>
          );
        })
        ) : (<tr><td colSpan={12}>No hay ninguna orden de combustible para confirmar los pagos.</td></tr>)}
        </tbody>
      </table>
    </div>

      <div className="d-flex align-items-center table-responsive mt-2">
        <p className="m-0 text-secondary">
          Mostrando {(currentPage - 1) * itemsPerPage + 1} a {Math.min(currentPage * itemsPerPage, totalEntries)} de {totalEntries} entradas
        </p>
        <ul className="pagination m-0 ms-auto">
          <li className={`btn-action ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" /></svg>
            </button>
          </li>
          {getPaginationRange().map(pageNumber => (
            <li key={pageNumber} className={`btn-action page-item ${currentPage === pageNumber ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(pageNumber)}>{pageNumber}</button>
            </li>
          ))}
          <li className={`btn-action  ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-forward-up"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 14l4 -4l-4 -4" /><path d="M19 10h-11a4 4 0 1 0 0 8h1" /></svg>
            </button>
          </li>
        </ul>
      </div>

      <p className='text-secondary'>Proxima solicitud en {countdown} segundos.</p>
      {activeSelect && (
        <div className="modal modal-blur fade" id="viewinfo" tabIndex={-1} role="dialog" aria-modal="true" style={{display: 'none'}}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <form className="modal-content" method="POST" onSubmit={(e) => handleCardClick(e)} style={{background: 'transparent', boxShadow: 'none'}}>
              <div className='card mb-3'>
                <div class={`card-status-bottom bg-red`} />
                <div className="modal-body text-center py-4">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon text-red icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
                  <h3>Para realizar el egreso, Incluya el monto.</h3>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header">
                  <h5 className="modal-title">Egresar dinero.</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="card-body">
                  <span className='mb-2 required'>Monto</span>
                  <input type="number" className='form-control' defaultValue={0} max={0} placeholder='3000' name="monto" required />

                </div>
              </div>
              
              <div className="d-flex align-items-center mb-3">
                <button type="submit" className='btn btn-primary w-100' data-bs-dismiss="modal">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-credit-card-pay me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 19h-6a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5" /><path d="M3 10h18" /><path d="M16 19h6" /><path d="M19 16l3 3l-3 3" /><path d="M7.005 15h.005" /><path d="M11 15h2" /></svg>
                  Confirmar
                </button>
              </div>
              <div className="alert alert-info bg-theme" role="alert">
                <div className="d-flex">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M12 9h.01" /><path d="M11 12h1v4h1" /></svg>
                  </div>
                  <div>
                    <h4 className="alert-title">Nota</h4>
                    <div className="text-secondary">Una ves realizado el egreso, Dirijase a 'Hacienda' para que hacienda imprima y selle el recibo.</div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Content;
