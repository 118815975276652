import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const CameraCapture = ({ config, apiURL }) => {
    const [image, setImage] = useState(null);
    const [alert, setAlert] = useState(2);
    const [loading, setLoading] = useState(false);
    const [subLoader, setSubLoader] = useState(true);
    const videoRef = useRef(null);
    const streamRef = useRef(null);

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    };

    const createSection = async () => {
        const jwt = Cookies.get('jwt');
        try {
            const response = await axios.post(apiURL, {
                type: '/scanner/image/create/section',
                filename: getQueryParam('q')
            }, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });

            const result = response.data;
            if (result.valid === 'success') {
                setSubLoader(false);
            } else {
                alert('Problemas con la camara, Porfavor recarge la pagina o recarge el qr code.');
            }
        } catch (error) {
            console.error("Error al crear la camara:", error);
        }
    };
    useEffect(() => {
        createSection();
    }, []);

    const handleUpload = async (file) => {
        const jwt = Cookies.get('jwt');
        const formData = new FormData();
        formData.append('image', file);
        formData.append('filename', getQueryParam('q'));
        formData.append('type', '/scanner/image/capture');

        try {
            const response = await axios.post(apiURL, formData, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            const result = response.data;
            setAlert(result.status === 'success' ? 1 : result.message);
        } catch (error) {
            setAlert(0);
        } finally {
            setLoading(false);
        }
    };

    const startCamera = async () => {
        try {
            streamRef.current = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
            videoRef.current.srcObject = streamRef.current;
            videoRef.current.play();
        } catch (error) {
            setAlert(0);
        }
    };

    const captureImage = async () => {
        setLoading(true);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (videoRef.current) {
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
            const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
            handleUpload(blob);
        }

        stopCamera();
    };

    const stopCamera = () => {
        if (streamRef.current) {
            const tracks = streamRef.current.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }
    };

    useEffect(() => {
        if (!subLoader) {
            startCamera();

            // Cleanup function to stop the camera when the component unmounts
            return () => {
                stopCamera();
            };
        }
    }, [subLoader]);

    if (subLoader) {
        return (
            <div class="container container-slim py-4">
                    <div class="text-center">
                    <div class="mb-3">
                        <a href={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></a>
                    </div>
                    <div class="text-secondary mb-3">Iniciando camara!</div>
                    <div class="progress progress-sm">
                        <div class="progress-bar progress-bar-indeterminate"></div>
                    </div>
                    </div>
                </div>
        );
    }

    return (
        <div className="mt-3">
            {alert === 1 ? (
                <div className="modal modal-blur fade show" id="modal-success" tabIndex={-1} role="dialog" aria-modal="true" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setAlert(2)} />
                            <div className="modal-status bg-success" />
                            <div className="modal-body text-center py-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon mb-2 text-green icon-lg">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                    <path d="M9 12l2 2l4 -4" />
                                </svg>
                                <h3>¡Imagen enviada!</h3>
                                <div className="text-secondary">Imagen fue enviada a su navegador y estará disponible automáticamente.</div>
                            </div>
                            <div className="modal-footer">
                                <div className="w-100">
                                    <div className="row">
                                        <div className="col">
                                            <a href="#" className="btn w-100" data-bs-dismiss="modal" onClick={() => setAlert(2)}>
                                                Cerrar
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : alert === 2 ? (
                <></>
            ) : (
                <div className="modal modal-blur fade show" id="modal-danger" style={{ display: 'block' }} tabIndex={-1} role="dialog" aria-modal="true">
                    <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setAlert(2)} />
                            <div className="modal-status bg-danger" />
                            <div className="modal-body text-center py-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon mb-2 text-danger icon-lg">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 9v4" />
                                    <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
                                    <path d="M12 16h.01" />
                                </svg>
                                <h3>Fallo al enviar la Imagen</h3>
                                <div className="text-secondary">{alert === 'existe' ? 'Ya enviaste la imagen, verifica tu navegador!' : 'Ha ocurrido un problema al intentar enviar la imagen al navegador. ¡Vuelve a intentarlo!'}</div>
                            </div>
                            <div className="modal-footer">
                                <div className="w-100">
                                    <div className="row">
                                        <div className="col">
                                            <a href="#" className="btn w-100" data-bs-dismiss="modal" onClick={() => setAlert(2)}>
                                                Cerrar
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!loading ? (
                <>
                    <div className="camera-container">
                      <div className="skeleton-frame"></div>
                      <video ref={videoRef} className='camera-feed' />
                    </div>
                </>
            ) : (
                <div class="container container-slim py-4">
                    <div class="text-center">
                    <div class="mb-3">
                        <a href={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></a>
                    </div>
                    <div class="text-secondary mb-3">Enviando Imagen...</div>
                    <div class="progress progress-sm">
                        <div class="progress-bar progress-bar-indeterminate"></div>
                    </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CameraCapture;
