import React, { useState, useRef, useEffect } from 'react';
import '../../DocumentSkeleton.css';

const DocumentSkeleton = () => {
    const [subLoader, setSubLoader] = useState(true);
    const videoRef = useRef(null);
    const streamRef = useRef(null);

    
    const startCamera = async () => {
        try {
            streamRef.current = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
            videoRef.current.srcObject = streamRef.current;
            videoRef.current.play();
        } catch (error) {
            console.error("Error al abrir la cámara:", error);
        }
    };

    const captureImage = async () => {
        //setLoading(true);
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (videoRef.current) {
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
            const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
            //handleUpload(blob);
        }

        stopCamera();
    };

    const stopCamera = () => {
        if (streamRef.current) {
            const tracks = streamRef.current.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }
    };

    useEffect(() => {
        if (!subLoader) {
            startCamera();

            // Cleanup function to stop the camera when the component unmounts
            return () => {
                stopCamera();
            };
        }
    }, [subLoader]);

  return (
    <div className="camera-container">
      <div className="skeleton-frame"></div>
      <video ref={videoRef} style={{ width: '100%' }} className="camera-feed" />
    </div>
  );
};

export default DocumentSkeleton;