import React, { useState, useEffect } from 'react';

const UnSaved = ({ code, setItemsEdit, button, guardarCambios }) => {

    return (
        <div style={styles.alert}>
            <p>Cuidado — ¡tienes cambios sin guardar!</p>
            <div>
                <button className="btn btn-primary me-2" onClick={() => guardarCambios()}>
                    {button || 'Guardar Cambios'}
                </button>
                <button className="btn btn-warning" onClick={() => setItemsEdit(code)}>
                    Restablecer
                </button>
            </div>
        </div>
    );
};

const styles = {
    alert: {
        position: 'fixed',
        bottom: '2%',
        left: '25%',
        right: '25%',
        backgroundColor: '#2C2F33', // Color oscuro como en Discord
        color: 'white',
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.5)',
        zIndex: 99999,
        borderRadius: '7px'
    },
    button: {
        backgroundColor: '#7289DA', // Color estilo Discord
        color: 'white',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        marginLeft: '10px',
    },
};

export default UnSaved;
