import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { parse, format, isThisYear } from 'date-fns';
import { es } from 'date-fns/locale';
import TimeSince from '../../components/DateFormat.js';
import { PermissionsContext } from '../../components/hasPermissions.js';

const Content = ({ config, apiURL, countdown, optionList, handleCheckboxChange, setPayment, optionUse, delCiudadano, data, setData, filteredData, setFilteredData, totalEntries, 
  setTotalEntries, fetchData, setSelectCiu }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [entriesPerPage, setEntriesPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoader, setIsLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'desc' }); // Ordenación inicial por 'id' descendente

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  useEffect(() => {
    const sortedData = [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
  }, [data, sortConfig]);

  const modifyIitem = (item) => {
    Cookies.set('rentas_id_select', item, { expires: 1 / 24 });
  }

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filtered = data.filter(item =>
      Object.values(item).some(value =>
        String(value).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setFilteredData(filtered);
    setTotalEntries(filtered.length);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const getPaginationRange = () => {
    const maxVisiblePages = 10;
    const startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
    const adjustedStartPage = Math.max(Math.min(startPage, totalPages - maxVisiblePages + 1), 1);

    return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, i) => adjustedStartPage + i);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
          <path d="M8 9l4 -4l4 4" /><path d="M16 15l-4 4l-4 -4" />
        </svg>
      );
    } else if (sortConfig.direction === 'asc') {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
          <path d="M6 15l6 -6l6 6" />
        </svg>
      );
    } else {
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
          <path d="M6 9l6 6l6 -6" />
        </svg>
      );
    }
  };


  return (
    <div className="col-lg-9 col-md-8 col-sm-12">
      {optionList.length > 0 ? (
<div className="card mb-3">
  <div className="card-header">
    <h3 className="card-title">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-box-multiple me-2 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" /><path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" /></svg>
      Imprimir multiples recibos
    </h3>
    <div className="card-actions btn-actions">
      <a href="#" className="btn btn-primary" onClick={(e) => { e.preventDefault(); localStorage.setItem('rentas_listed', JSON.stringify(optionList)); navigate('/pdf/rentas'); }}>
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-printer me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" /><path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" /><path d="M7 13m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z" /></svg>
        Imprimir
      </a>
    </div>
  </div>
  <div className="card-body">
    <p class="text-secondary">
      Ya puedes imprimir varias opciones a la vez incluso con hojas adicionales, presiona el botón 'imprimir' para continuar al apartado de imprimir.<br />
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle me-1 text-warning"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
      Hay {optionList.length > 0 ? optionList.length : 0} recibos seleccionadas para imprimir.
    </p>
  </div>
</div>

      ) : (<></>)}

      <div className="card">
        <div className="card-body border-bottom py-3">
          <div className="d-flex">
            <div className="text-secondary">
              Viendo
              <div className="mx-2 d-inline-block">
                <select className="form-control form-control-sm" id="total" value={entriesPerPage} onChange={handleEntriesPerPageChange}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={250}>250</option>
                  <option value={500}>500</option>
                </select>
              </div>
              filas
            </div>
            <div className="ms-auto text-secondary">
              Buscador:
              <div className="ms-2 d-inline-block">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  aria-label="Search invoice"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
        
          <table className="table card-table table-vcenter text-nowrap datatable">
            <thead>
              <tr>
                <th className="w-1"><input className="form-check-input m-0 align-middle" type="checkbox" aria-label="Select all invoices" /></th>
                <th className="w-1" onClick={() => handleSort('id')}>Nr. {renderSortIcon('id')}</th>
                <th onClick={() => handleSort('dni')}>Titular {renderSortIcon('dni')}</th>
                <th onClick={() => handleSort('type')}>Tipo {renderSortIcon('type')}</th>
                <th onClick={() => handleSort('service')}>Servicio {renderSortIcon('service')}</th>
                <th onClick={() => handleSort('reason')}>Observacion {renderSortIcon('reason')}</th>
                <th onClick={() => handleSort('since')}>Registrado {renderSortIcon('since')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody id="content_table">
              {currentEntries.map((item, index) => {
                const isChecked = optionList.some(selected => selected.id === item.id);
                const parsedDate = parse(item.since, 'yyyy-MM-dd HH:mm:ss', new Date());
                const dayOfWeek = format(parsedDate, 'EEEE', { locale: es });
                const formattedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1).toLowerCase();
                const month = format(parsedDate, 'MMMM', { locale: es });
                const formattedMonth = (month === 'enero') ? 'enero' : month.slice(0, 3).charAt(0).toUpperCase() + month.slice(1, 3);
                const includeYear = !isThisYear(parsedDate);
                const formattedYear = includeYear ? `, ${format(parsedDate, 'yyyy')}` : '';
                const dateSince = `${formattedDayOfWeek} ${format(parsedDate, 'd')} de ${formattedMonth}${formattedYear}`;
                
                const amount = Number(item.amount).toLocaleString('es-ES');
                return (
                <tr key={index}>
                  <td className="text-center dropdown">
                      {hasPermission('m.rentas.imprimir') && item.type !== 'e' && (
                        <input
                          className="form-check-input m-0 align-middle me-1"
                          type="checkbox"
                          aria-label="Select all invoices"
                          checked={isChecked}
                          onChange={() => handleCheckboxChange(item)}
                        />
                      )}
                      <a href="#" className="btn-action d-md-none" data-bs-toggle="dropdown" aria-expanded="true">
                          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /></svg>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end" data-popper-placement="bottom-end" style={{position: 'absolute', inset: '0px 0px auto auto', margin: 0, transform: 'translate(0px, 34px)'}}>
                          <Link to={`/rentas/${item.id}`} className="dropdown-item" onClick={(e) => optionUse(e, item)}>
                              Ver Más
                              <label className="m-0 ms-auto">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-history"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 8l0 4l2 2" /><path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" /></svg>
                              </label>
                          </Link>
                          {item.status == 0 && (
                            <Link to={`/rentas/${item.id}`} className="dropdown-item text-success" onClick={(e) => setPayment(e, item.id)}>
                                Pagado
                                <label className="m-0 ms-auto">
                                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-checkbox"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 11l3 3l8 -8" /><path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" /></svg>
                                </label>
                            </Link>
                          )}
                          {hasPermission('m.rentas.modificar') && item.status == 0 && (
                            <Link to={`/rentas/editar`} className="dropdown-item" onClick={() => modifyIitem(item.id)}>
                                Modificar
                                <label className="m-0 ms-auto">
                                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-pencil"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" /><path d="M13.5 6.5l4 4" /></svg>
                                </label>
                            </Link>
                          )}
                          {hasPermission('m.rentas.eliminar') && item.status == 0 && (
                            <a href="#" className="dropdown-item text-danger" onClick={(e) => delCiudadano(e, item.id, false)}>
                                Eliminar
                                <label className="m-0 ms-auto">
                                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                                </label>
                            </a>
                          )}
                      </div>
                  </td>
                  <td>
                    <span className="text-secondary">{item.id}</span>
                  </td>
                  <td>
                    <Link to={`/rentas/${item.id}`} className="text-primary">{item.titular}</Link>
                    <br />{Number(item.dni).toLocaleString('es-ES')}
                  </td>
                  <td>
                    {item.status && item.type == 'i' ? (<span class="badge bg-green-lt mb-1">Ingreso</span>) : 
                    item.status && item.type == 'e' ? (<span class="badge bg-red-lt mb-1">Egreso</span>) : 
                    (<span class="badge bg-yellow-lt mb-1">Pendiente</span>)}
                  </td>
                  <td>
                    {item.service}<br />${amount}
                  </td>
                  <td>
                    {item.reason.length > 20 ? `${item.reason.slice(0, 20)}...` : item.reason}
                  </td>
                  <td>{dateSince}</td>
                  <td>
                      <div className='d-none d-md-inline-block'>
                          <Link to={`/rentas/${item.id}`} className="btn-action" data-bs-toggle="tooltip" aria-label="Ver Mas" data-bs-original-title="Ver Mas" onClick={(e) => optionUse(e, item)}>
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-history"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 8l0 4l2 2" /><path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" /></svg>
                          </Link>
                          {item.status == 0 && item.type == 'i' && (
                          <Link to={`/rentas/${item.id}`} className="btn-action text-success" onClick={(e) => setPayment(e, item.id)} data-bs-toggle="tooltip" aria-label="Pagado" data-bs-original-title="Pagado">
                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-checkbox"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 11l3 3l8 -8" /><path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" /></svg>
                          </Link>
                          )}
                          {hasPermission('m.rentas.modificar') && item.status == 0 && item.type == 'i' && (
                            <Link to={`/rentas/editar`} onClick={() => modifyIitem(item.id)} className="btn-action text-warning" data-bs-toggle="tooltip" aria-label="Modificar" data-bs-original-title="Modificar">
                                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-pencil"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" /><path d="M13.5 6.5l4 4" /></svg>
                            </Link>
                          )}
                          {hasPermission('m.rentas.eliminar') && item.status == 0 && item.type == 'i' && (
                            <a href="#" className="btn-action text-danger" onClick={(e) => delCiudadano(e, item.id, false)} data-bs-toggle="tooltip" aria-label="Eliminar" data-bs-original-title="Eliminar">
                                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
                            </a>
                          )}
                      </div>
                  </td>
                </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="card-footer d-flex align-items-center table-responsive">
          <p className="m-0 text-secondary" id="pagination_info">
            Mostrando {indexOfFirstEntry + 1} a {Math.min(indexOfLastEntry, totalEntries)} de {totalEntries} entradas
          </p>
          <ul className="pagination m-0 ms-auto" id="pagination_list">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Prev</button>
            </li>
            {getPaginationRange().map(pageNumber => (
              <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(pageNumber)}>{pageNumber}</button>
              </li>
            ))}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
            </li>
          </ul>
        </div>
      </div>
      <p className='text-secondary'>Proxima solicitud en {countdown} segundos.</p>


    </div>
  );
};

export default Content;
