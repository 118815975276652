import React, { useState, useEffect, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import axios from 'axios';
import Cookies from 'js-cookie';

const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const Scanner = ({ config, apiURL, sendData, setSendData, updateInputValue }) => {
    const [image, setImage] = useState(null);
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [subLoader, setSubLoader] = useState(false);
    const [data, setData] = useState({
        dni: '',
        nombre: '',
        apellido: '',
        fechaNacimiento: '',
        sexo: '',
    });
    const [options, setOptions] = useState(null);
    const [token, setToken] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const fileInputRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(isMobileDevice());
    }, []);

    const generateToken = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 48; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    };

    const handleTakePhoto = (e) => {
        e.preventDefault();
        const newToken = generateToken();
        setToken(newToken);
        setOptions('tomar');
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => setImage(reader.result.split(',')[1]); // Guardar la imagen como base64
        reader.readAsDataURL(file);
        setImageUrl(file);
        setOptions('subir');
        setToken('');
    };

    const fetchImage = async (intervalId) => {
        const jwt = Cookies.get('jwt');
        if (!token) return;
        setImage(null);
        setText('');
        setData({
            dni: '',
            nombre: '',
            apellido: '',
            fechaNacimiento: '',
            sexo: '',
        });

        try {
            const response = await axios.post(
                apiURL,
                {
                    type: '/scanner/image/check',
                    filename: token,
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log(response.data);
            if (response.data.status === 'success') {
                let imageBase64 = response.data.image;
                let responseUsed = response.data.used;
                if (responseUsed) {
                    if (imageBase64.startsWith("data:image")) {
                        imageBase64 = imageBase64.split(",")[1];
                    }
                    setImage(imageBase64);
                    clearInterval(intervalId);
                    setSubLoader(false);
                    setOptions(null);
                } else {
                    setSubLoader(true);
                }
            }
        } catch (error) {
            console.error('Error al obtener la imagen:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let intervalId;
        if (options === 'tomar') {
            intervalId = setInterval(() => {
                fetchImage(intervalId);
            }, 3000); // Verifica cada 3 segundos
        }
        return () => clearInterval(intervalId);
    }, [options, token]);

    const handleScan = async () => {
        if (!image) return;

        setLoading(true);
        setSubLoader(true);
        setText('');
        setData({
            dni: '',
            nombre: '',
            apellido: '',
            fechaNacimiento: '',
            sexo: '',
        });

        try {
            const response = await fetch(
                `https://vision.googleapis.com/v1/images:annotate?key=AIzaSyAZ-zJ5FCVS9-6pMJX7WrN-4FHpv1eXlkk`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        requests: [
                            {
                                image: {
                                    content: image,
                                },
                                features: [
                                    {
                                        type: 'TEXT_DETECTION',
                                        maxResults: 10
                                    },
                                ],
                            },
                        ],
                    }),
                }
            );

            const result = await response.json();
            const extractedText = result.responses?.[0]?.fullTextAnnotation?.text || '';
            if (!extractedText) {
                setSubLoader(false);
                setLoading(false);
                console.error("No se pudo extraer texto de la imagen");
                return;
            }
            setText(extractedText);
            parseData(extractedText);
            setOptions('other');
            setSubLoader(false);
        } catch (error) {
            console.error("Error al escanear la imagen:", error);
        } finally {
            setSubLoader(false);
            setLoading(false);
        }
    };
    const replaceTexts = (text) => {
        const replacements = {
            "REPUBLICA ARGENTINA MERCOSUR": "",
        };
    
        let modifiedText = text;
    
        // Itera sobre las claves del objeto de reemplazos
        for (const [key, value] of Object.entries(replacements)) {
            // Usa una expresión regular para hacer el reemplazo
            const regex = new RegExp(key, 'g'); // 'g' para reemplazar todas las ocurrencias
            modifiedText = modifiedText.replace(regex, value);
        }
    
        return modifiedText;
    };

    const cleanDNI = (dni) => {
        return dni.replace(/\./g, '').split(' ')[0];
    };
    
    const cleanNombre = (nombre) => {
        const cleanedText = replaceTexts(nombre).replace(/\d+/g, '');
        return cleanedText.charAt(0).toUpperCase() + cleanedText.slice(1).toLowerCase();
    };
    
    const cleanApellido = (apellido) => {
        const cleanedText = replaceTexts(apellido).replace(/\d+/g, '');
        return cleanedText.charAt(0).toUpperCase() + cleanedText.slice(1).toLowerCase();
    };
    
    const cleanFechaNacimiento = (fecha) => {
        const monthMap = {
            'ENE': '01', 'JAN': '01',
            'FEB': '02', 
            'MAR': '03', 
            'ABR': '04', 'APR': '04',
            'MAY': '05',
            'JUN': '06', 
            'JUL': '07', 
            'AGO': '08', 'AUG': '08',
            'SEP': '09', 
            'OCT': '10', 
            'NOV': '11', 
            'DIC': '12', 'DEC': '12'
        };
        
        const formattedDate = fecha.toUpperCase().replace(
            /\b(ENE JAN|FEB|MAR|ABR APR|MAY|JUN|JUL|AGO AUG|SEP|OCT|NOV|DIC DEC)\b/g,
            (match) => {
                const month = match.split(' ')[0];
                return monthMap[month] || '';
            }
        );
    
        const numbersOnly = formattedDate.replace(/[^0-9]/g, ' ').trim().split(/\s+/);
        return numbersOnly.length === 3 
            ? `${numbersOnly[0].padStart(2, '0')}/${numbersOnly[1].padStart(2, '0')}/${numbersOnly[2]}`
            : 'Formato inválido';
    };
    
    const cleanSexo = (sexo) => {
        return sexo === 'M' || sexo === 'F' ? sexo : '';
    };
    
    const parseData = (text) => {
        const dniMatch = text.match(/Documento\/Document\s+(\d{2}\.\d{3}\.\d{3})/i);
        const nombreMatch = text.match(/Nombre\s*\/\s*Name\s*([\w\s]+)/i);
        const apellidoMatch = text.match(/Apellido\s*\/\s*Surname\s*([\w\s]+)/i);
        const fechaNacimientoMatch = text.match(/Fecha de nacimiento\s*\/\s*Date of birth\s+([\d\w\s]+)/i);
        const sexoMatch = text.match(/Sexo\s*\/\s*Sex\s*(M|F)/i);
    
        setData({
            dni: dniMatch ? cleanDNI(dniMatch[1].trim()) : 'No encontrado',
            nombre: nombreMatch ? cleanNombre(nombreMatch[1].trim()) : 'No encontrado',
            apellido: apellidoMatch ? cleanApellido(apellidoMatch[1].trim()) : 'No encontrado',
            fechaNacimiento: fechaNacimientoMatch ? cleanFechaNacimiento(fechaNacimientoMatch[1].trim()) : 'No encontrado',
            sexo: sexoMatch ? cleanSexo(sexoMatch[1].trim()) : 'No encontrado',
        });
    };
    const handleFileUploadClick = () => {
        fileInputRef.current.click(); // Programmatically click the file input
    };

    const sendDatas = () => {
        setSendData(data); // Programmatically click the file input
        updateInputValue('dni', data.dni);
        updateInputValue('nacimiento', data.fechaNacimiento);
    };
    

    return (
        <>
            <div className="modal modal-blur fade" id="scanner-document" tabIndex={-1} style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Escaner de Documento</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <input type="file" accept=".jpg, .jpeg, .png" ref={fileInputRef} onChange={handleImageChange} style={{ display: 'none' }} />
                            {subLoader ? (
                                <div class="container container-slim py-4 mb-0 pb-0">
                                    <div class="text-center">
                                    <div class="mb-3">
                                        <a href={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></a>
                                    </div>
                                    <div class="text-secondary mb-3">Esperando respuesta...</div>
                                    <div class="progress progress-sm">
                                        <div class="progress-bar progress-bar-indeterminate"></div>
                                    </div>
                                    </div>
                                </div>
                            ) : (
                            <>
                            <div className="row align-items-center mb-3">
                                <div className="col">
                                    <a href="#" className="avatar avatar-upload rounded w-100" onClick={handleFileUploadClick}>
                                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-photo-up"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 8h.01" /><path d="M12.5 21h-6.5a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6.5" /><path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l3.5 3.5" /><path d="M14 14l1 -1c.679 -.653 1.473 -.829 2.214 -.526" /><path d="M19 22v-6" /><path d="M22 19l-3 -3l-3 3" /></svg>
                                        <span className="avatar-upload-text">Subir Foto</span>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="#" className="avatar avatar-upload rounded w-100" onClick={isMobile ? handleFileUploadClick : handleTakePhoto}>
                                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-camera"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" /><path d="M9 13a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" /></svg>
                                        <span className="avatar-upload-text">Tomar Foto</span>
                                    </a>
                                </div>
                            </div>
                            {options === 'tomar' && token ? (
                                <div className="p-3 bg-light align-items-center mb-3">
                                    <QRCodeCanvas
                                        value={`https://panel.devbybit.com/camera/documentos?q=${token}`}
                                        size={256}
                                        bgColor="#ffffff"
                                        fgColor="#000000"
                                        level="H"
                                    />
                                    <p className="text-secondary">Escanea con una aplicacion 'QR Scanner' y abre el link mostrado.</p>
                                </div>
                            ) : options === 'subir' ? (
                                <></>
                            ) : options === 'other' ? (
                                <></>
                            ) : (
                                <p className="text-secondary">Seleccione una opción para comenzar</p>
                            )}
                            
                            {image && (
<div className="alert alert-success bg-theme" role="alert">
  <div className="d-flex">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
    </div>
    <div>
      <h4 className="alert-title">¡En hora buena!</h4>
      <div className="text-secondary">Hemos obtenido la foto del documento con exito, Haz click en el boton 'Escanear' para comenzar. </div>
    </div>
  </div>
</div>

                            )}

                            {text && (
                                <div>
                                    <h2>Texto extraído:</h2>
                                    <pre>{text}</pre>
                                </div>
                            )}

                            {data && text && (
                                <div>
                                    <h2 className='mb-0'>Datos del DNI:</h2>
                                    <p className='mb-0'><strong>DNI:</strong> {data.dni}</p>
                                    <p className='mb-0'><strong>Nombre:</strong> {data.nombre}</p>
                                    <p className='mb-0'><strong>Apellido:</strong> {data.apellido}</p>
                                    <p className='mb-0'><strong>Fecha de Nacimiento:</strong> {data.fechaNacimiento}</p>
                                    <p className='mb-2'><strong>Sexo:</strong> {data.sexo}</p>
                                </div>
                            )}
                            
                            <a href="#" className={`btn btn-primary ms-auto me-1 ${image ? '' : 'disabled'}`} onClick={handleScan}>
                                {!image ? (
                                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-fingerprint-off me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18.9 7a8 8 0 0 1 1.1 5v1a6 6 0 0 0 .8 3" /><path d="M8 11c0 -.848 .264 -1.634 .713 -2.28m2.4 -1.621a4 4 0 0 1 4.887 3.901l0 1" /><path d="M12 12v1a14 14 0 0 0 2.5 8" /><path d="M8 15a18 18 0 0 0 1.8 6" /><path d="M4.9 19a22 22 0 0 1 -.9 -7v-1a8 8 0 0 1 1.854 -5.143m2.176 -1.825a8 8 0 0 1 7.97 .018" /><path d="M3 3l18 18" /></svg>
                                ) : (
                                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-fingerprint me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18.9 7a8 8 0 0 1 1.1 5v1a6 6 0 0 0 .8 3" /><path d="M8 11a4 4 0 0 1 8 0v1a10 10 0 0 0 2 6" /><path d="M12 11v2a14 14 0 0 0 2.5 8" /><path d="M8 15a18 18 0 0 0 1.8 6" /><path d="M4.9 19a22 22 0 0 1 -.9 -7v-1a8 8 0 0 1 12 -6.95" /></svg>
                                )}
                                Escanear
                            </a>
                            <a href="#" className={`btn btn-success ms-auto ${image ? '' : 'disabled'}`} onClick={() => sendDatas()}>
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-checkbox me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 11l3 3l8 -8" /><path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" /></svg>
                                Utilizar
                            </a>
                            </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Scanner;
