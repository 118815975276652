import React, { useState } from 'react';

const OCRScanner = () => {
    const [result, setResult] = useState("Esperando escaneo...");

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            const imageBase64 = e.target.result.replace("data:image/jpeg;base64,", "");

            const response = await fetch(`https://vision.googleapis.com/v1/images:annotate?key=AIzaSyAZ-zJ5FCVS9-6pMJX7WrN-4FHpv1eXlkk`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    requests: [
                        {
                            image: {
                                content: imageBase64
                            },
                            features: [
                                {
                                    type: "TEXT_DETECTION"
                                }
                            ]
                        }
                    ]
                })
            });

            const data = await response.json();
            if (data.responses[0].textAnnotations) {
                setResult(data.responses[0].textAnnotations[0].description);
            } else {
                setResult("No se detectó ningún texto válido en la imagen.");
            }
        };
        reader.readAsDataURL(file);
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <h1>OCR de DNI</h1>
            <input type="file" accept="image/*" onChange={handleImageUpload} />
            <pre>{result}</pre>
        </div>
    );
};

export default OCRScanner;
