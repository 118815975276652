import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const CsvExport = ({ apiURL }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const jwt = Cookies.get('jwt');
            const configResponse = await axios.post(apiURL, {
                type: '/descargar/csv',
                option: 1,
            }, { headers: { Authorization: `Bearer ${jwt}` } });
      
            const configData = configResponse.data;
            console.log(configData);
    
            if (configData) {
                setData(configData.data);
            } else {
                console.error("No hay datos en la respuesta de la API.");
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Convertir los datos a CSV
    const convertToCSV = (data) => {
        if (!data.length) return '';

        const headers = Object.keys(data[0]);
        const rows = data.map(row => headers.map(header => row[header] ?? '').join(','));

        return [headers.join(','), ...rows].join('\n');
    };

    // Descargar el archivo CSV
    const downloadCSV = () => {
        const csv = convertToCSV(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'datos.csv';
        a.click();

        URL.revokeObjectURL(url); // Limpiar el objeto URL
    };

    if (loading) {
        return <button className="btn">Cargando...</button>;
    }

    return (
        <button className="btn" onClick={downloadCSV}>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-download me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" /><path d="M7 11l5 5l5 -5" /><path d="M12 4l0 12" /></svg>
            Descargar
        </button>
    );
};

export default CsvExport;
