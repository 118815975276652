import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

import Listado from './combustible/Listado.js';
import Agregar from './combustible/Agregar.js';
import Detalles from './combustible/Detalles.js';
import Editar from './combustible/Editar.js';
import { PermissionsContext } from '../components/hasPermissions.js';
import QRScanner from './scanner/QRCode.js';

const Content = ({ config, apiURL }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const location = useLocation();
  const { id, type } = useParams();
  const [loading, setLoading] = useState(true);
  const [scanResult, setScanResult] = useState('');
  const [results, setResults] = useState('');
  const [optionSelect, setOptionSelect] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectCiu, setSelectCiu] = useState('');
  const [optionList, setOptionList] = useState([]);

  const handleCheckboxChange = (item, redirect = false) => {
    setOptionList((prevOptions) => {
      if (prevOptions.some(selected => selected.id === item.id)) {
        return prevOptions.filter(selected => selected.id !== item.id);
      } else {
        return [...prevOptions, item];
      }
    });
    if (redirect) {
      setTimeout(() => {
        localStorage.setItem('combustible_listed', JSON.stringify(optionList));
      }, 1000);
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  useEffect(() => {
    const combList = JSON.parse(localStorage.getItem('combustible_list'));
    setResults(combList);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const checkPermissions = () => {
      if (!hasPermission('m.combustible') && id !== 'agregar' && id !== 'precios') {
        navigate(-1); 
      } else if (!hasPermission('m.combustible.agregar') && id === 'agregar') {
        navigate(-1);
      } else if (!hasPermission('m.combustible.modificar') && id && type === 'editar') {
        navigate(-1);
      } else if (!hasPermission('m.combustible.precios') && id === 'precios') {
        navigate(-1);
      } else {
        setLoading(false);
      }
    };

    checkPermissions();
  }, [hasPermission, navigate, id]);

  useEffect(() => {
    if (results && optionSelect) {
      const newFilteredResults = results.filter(item => item.id == optionSelect).slice(0, 10);

      setFilteredResults(newFilteredResults);
    } else {
      setFilteredResults([]);
    }
  }, [optionSelect, results]);

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setOptionSelect(value);
  };
  
  useEffect(() => {
    const combList = JSON.parse(localStorage.getItem('combustible_list'));

    setResults(combList);
  }, [data]);

  const delCiudadano = async (e, id, confirm) => {
    e.preventDefault();

    if (!hasPermission('m.combustible.eliminar')) {
      return null;
    }

    if (confirm) {
      const jwt = Cookies.get('jwt');
  
      const formData = {
        id: id,
      };
  
      try {
        const response = await axios.post(apiURL, {
          type: '/munity/combustible',
          option: '/eliminar',
          ...formData,
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });
    
        const result = response.data;
        Cookies.remove('combustible_list');
        if (!result.valid) {
          Toast.fire({ icon: 'error', title: result.message });
          return;
        }
        Toast.fire({ icon: 'success', title: result.message });
        const updatedData = data.filter(ciudadano => ciudadano.id !== id);
        setData(updatedData);
        setFilteredData(updatedData);
        setOptionSelect('');
      } catch (error) {
        Toast.fire({ icon: 'error', title: 'Error en la accion.' });
      }
    } else {
      const myModal = new window.bootstrap.Modal(document.getElementById('eliminar-ciudadano'));
      myModal.show();
      setSelectCiu(id);
    }
  };
  
  
  const anularOrden = async (e, id, confirm) => {
    e.preventDefault();

    if (!hasPermission('m.combustible.anular')) {
      return null;
    }

    if (confirm) {
      const jwt = Cookies.get('jwt');
  
      const formData = {
        id: id,
      };
  
      try {
        const response = await axios.post(apiURL, {
          type: '/munity/combustible',
          option: '/anular',
          ...formData,
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });
    
        const result = response.data;
        Cookies.remove('combustible_list');
        if (!result.valid) {
          Toast.fire({ icon: 'error', title: result.message });
          return;
        }
        Toast.fire({ icon: 'success', title: result.message });
        const updatedData = data.filter(ciudadano => ciudadano.id !== id);
        setData(updatedData);
        setFilteredData(updatedData);
        setOptionSelect('');
      } catch (error) {
        Toast.fire({ icon: 'error', title: 'Error en la accion.' });
      }
    } else {
      const myModal = new window.bootstrap.Modal(document.getElementById('eliminar-ciudadano'));
      myModal.show();
      setSelectCiu(id);
    }
  };

  if (loading) {
    return null;
  }
   
  return (
    <>
      <ul className="nav nav-bordered mb-4 mt-5">
        <li className="nav-item">
          <Link className={`nav-link ${!id ? 'active' : ''}`} aria-current="page" to={`/combustible`}>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-license me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 21h-9a3 3 0 0 1 -3 -3v-1h10v2a2 2 0 0 0 4 0v-14a2 2 0 1 1 2 2h-2m2 -4h-11a3 3 0 0 0 -3 3v11" /><path d="M9 7l4 0" /><path d="M9 11l4 0" /></svg>
            Listado
          </Link>
        </li>
        {hasPermission('m.combustible.agregar') && (
        <li className="nav-item">
          <Link className={`nav-link ${id == 'agregar' ? 'active' : ''}`} to={`/combustible/agregar`}>
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-copy-plus me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path stroke="none" d="M0 0h24v24H0z" /><path d="M7 9.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" /><path d="M4.012 16.737a2 2 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" /><path d="M11 14h6" /><path d="M14 11v6" /></svg>
            Nueva Orden
          </Link>
        </li>
        )}
        
        <li className="nav-item">
          <a className={`nav-link ${id == 'scanner' ? 'active' : ''}`} href={`/combustible/qrscanner`} data-bs-toggle="modal" data-bs-target="#qrscanner">
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-file-search me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M12 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v4.5" /><path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" /><path d="M18.5 19.5l2.5 2.5" /></svg>
            Buscador
          </a>
        </li>
      </ul>
      <div className="row">
        {!id ? 
        <Listado 
        config={config} 
        apiURL={apiURL} 
        delCiudadano={delCiudadano} 
        data={data} setData={setData} 
        filteredData={filteredData} setFilteredData={setFilteredData} 
        selectCiu={selectCiu} setSelectCiu={setSelectCiu} optionList={optionList} setOptionList={setOptionList} handleCheckboxChange={handleCheckboxChange} /> : 
        id == 'agregar' ? 
        <Agregar config={config} apiURL={apiURL} data={data} /> : id == 'precios' ? 
        <Agregar config={config} apiURL={apiURL} data={data} /> : 
        id && type == 'editar' ?
        <Editar config={config} apiURL={apiURL} details={id} page={type} /> :
        <Detalles config={config} apiURL={apiURL} details={id} />
        }
      </div>
      <div className="modal modal-blur fade" id="qrscanner" tabIndex={-1} style={{display: 'none'}} aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div id="create_ciudadanos" method="POST" className="modal-content">
              <ul className="nav nav-bordered modal-header-tabs mt-2 ms-2">
                  <li className="nav-item">
                    <a href="#search" className={`nav-link active`} data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex={-1}>
                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-search me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
                      Buscador
                    </a>
                  </li>
                  
                  <li className="nav-item">
                    <a href="#scanner" className={`nav-link`} data-bs-toggle="tab" aria-selected="true" role="tab">
                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-scan me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7v-1a2 2 0 0 1 2 -2h2" /><path d="M4 17v1a2 2 0 0 0 2 2h2" /><path d="M16 4h2a2 2 0 0 1 2 2v1" /><path d="M16 20h2a2 2 0 0 0 2 -2v-1" /><path d="M5 12l14 0" /></svg>
                      QR Escaner
                    </a>
                  </li>
              </ul>
              <button type="button" class="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>

              <div class="modal-body">

                <div className="tab-content">
                  <div className="tab-pane active show" id="search" role="tabpanel">
                    <h4>Busca por ID de orden:</h4>
                    <div className="input-icon mb-3">
                      <span className="input-icon-addon">
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-search"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
                      </span>
                      <input
                          type="text"
                          min={1}
                          max={99999}
                          className="form-control"
                          placeholder="3330"
                          value={optionSelect}
                          onChange={handleOptionChange}
                        />
                    </div>

                    

                    <div className="list-group list-group-flush overflow-auto" style={{maxHeight: '350px'}}>
                        {filteredResults.length > 0 ? (
                          filteredResults.slice(0, 10).map((item, index) => {
                            const combustible = item.combustible.split('#');
                            const costo = item.costo.split('#');
                            return (
                            <div
                              key={index}
                              className={`list-group-item ${optionSelect === item.id ? 'active' : ''}`}
                            >
                              <div className="card-title">Informacion de la Orden:</div>
                              <div className="mb-2">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon me-2 text-secondary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
                                Ciudadano: <strong id="nombre">{item.nombre}</strong> <span className="badge bg-primary-lt" id="dni">{item.dni}</span>
                              </div>
                              <div className="mb-2">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon me-2 text-secondary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 11h1a2 2 0 0 1 2 2v3a1.5 1.5 0 0 0 3 0v-7l-3 -3" /><path d="M4 20v-14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14" /><path d="M3 20l12 0" /><path d="M18 7v1a1 1 0 0 0 1 1h1" /><path d="M4 11l10 0" /></svg>
                                Combustible: <strong id="combustible">{combustible[0]}</strong> <span className="badge bg-pink-lt" id="litros">{combustible[1]} Lts</span>
                              </div>
                              <div className="mb-2">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon me-2 text-secondary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 13a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M15 9a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M9 5a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M4 20h14" /></svg>
                                Estado: <strong id="estado">{item.status == 1 ? (<span class="badge bg-green-lt">Pagado</span>) : (<span class="badge bg-red-lt">No Pagado</span>)}</strong>
                              </div>
                              <div className="mb-2">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon me-2 text-secondary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 9h8" /><path d="M8 13h6" /><path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" /></svg>
                                Observacion: <strong id="razon">{item.razon}</strong>
                              </div>
                              <div className="mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon me-2 text-secondary"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z" /><path d="M16 3v4" /><path d="M8 3v4" /><path d="M4 11h16" /><path d="M11 15h1" /><path d="M12 15v3" /></svg>
                                Creado el: <strong><span className="badge bg-green-lt" id="fecha">{item.since}</span></strong>
                              </div>
                              <div className="mb-2">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon me-2 text-secondary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 11l3 3l8 -8" /><path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" /></svg>
                                Autorizacion: <strong id="responsable">{item.autorizado == '' ? (<span class="badge bg-yellow-lt">Pendiente</span>) : item.autorizado ? (<span class="badge bg-green-lt">Autorizado</span>) : (<span class="badge bg-red-lt">Desautorizado</span>)}</strong>
                              </div>
                              <div className="mb-2">
                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon me-2 text-secondary"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M21 15h-2.5c-.398 0 -.779 .158 -1.061 .439c-.281 .281 -.439 .663 -.439 1.061c0 .398 .158 .779 .439 1.061c.281 .281 .663 .439 1.061 .439h1c.398 0 .779 .158 1.061 .439c.281 .281 .439 .663 .439 1.061c0 .398 -.158 .779 -.439 1.061c-.281 .281 -.663 .439 -1.061 .439h-2.5" /><path d="M19 21v1m0 -8v1" /><path d="M13 21h-7c-.53 0 -1.039 -.211 -1.414 -.586c-.375 -.375 -.586 -.884 -.586 -1.414v-10c0 -.53 .211 -1.039 .586 -1.414c.375 -.375 .884 -.586 1.414 -.586h2m12 3.12v-1.12c0 -.53 -.211 -1.039 -.586 -1.414c-.375 -.375 -.884 -.586 -1.414 -.586h-2" /><path d="M16 10v-6c0 -.53 -.211 -1.039 -.586 -1.414c-.375 -.375 -.884 -.586 -1.414 -.586h-4c-.53 0 -1.039 .211 -1.414 .586c-.375 .375 -.586 .884 -.586 1.414v6m8 0h-8m8 0h1m-9 0h-1" /><path d="M8 14v.01" /><path d="M8 17v.01" /><path d="M12 13.99v.01" /><path d="M12 17v.01" /></svg>
                                Costo: <strong id="responsable">${costo[0]}</strong> (Precio por litro: ${costo[1]})
                              </div>
                              <div className={``}>
                                <Link to={`/combustible/${item.id}`} data-bs-dismiss="modal" className="btn btn-primary me-2">Ver Mas</Link>
                                <button type="button" className="btn btn-info me-2" data-bs-dismiss="modal" onClick={() => { handleCheckboxChange(item, true); }}>Imprimir</button>
                                <Link to={`/combustible/${item.id}/editar`} data-bs-dismiss="modal" className="btn btn-warning me-2">Modificar</Link>
                                <button className="btn btn-danger me-2" data-bs-dismiss="modal" onClick={(e) => delCiudadano(e, item.id, false)}>Eliminar</button>
                              </div>
                            </div>
                            )
                          })
                        ) : (
                          <div>A medida que valla escribiendo el ID de la orden se iran mostrando opciones hasta completar o presionar la opcion que estaba buscando.</div>
                        )}
                    </div>

                  </div>
                  <div className="tab-pane" id="scanner" role="tabpanel">
                    <h4>QR Escaner</h4>
                    <div>Actualmente el escaneo de QR code no esta disponible.</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {hasPermission('m.combustible.eliminar') && (
          <div className="modal modal-blur fade" id="eliminar-ciudadano" tabIndex={-1} role="dialog" aria-modal="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
              <div className="modal-content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                <div className="modal-status bg-danger" />
                <div className="modal-body text-center py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon mb-2 text-danger icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
                  <h3>¿Estas realmente seguro?</h3>
                  <div className="text-secondary">Al borrar la orden no se puede recuperar.</div>
                </div>
                <div className="modal-footer">
                  <div className="w-100">
                    <div className="row">
                      <div className="col">
                        <a href="#" className="btn w-100" data-bs-dismiss="modal">
                          Cancelar
                        </a>
                      </div>
                      <div className="col">
                        <a href="#" className="btn btn-danger w-100" data-bs-dismiss="modal" onClick={(e) => delCiudadano(e, selectCiu, true)}>
                          Confirmar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default Content
