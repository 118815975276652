import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

import { PermissionsContext } from '../../components/hasPermissions.js';

const Content = ({ config, apiURL, col }) => {
  const { hasPermission } = useContext(PermissionsContext);

  const [ data, setData ] = useState([]);
  const [selectCiu, setSelectCiu] = useState('');
  const [modifyName, setModifyName] = useState('');
  const [modifyCosto, setModifyCosto] = useState('');

  const [isLoader2, setIsLoading2] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  const fetchData = async () => {
    const jwt = Cookies.get('jwt');
    try {
      const response = await axios.post(apiURL, {
        type: '/munity/rentas',
        option: '/servicios'
      }, {
        headers: { Authorization: `Bearer ${jwt}` }
      });
      const datas = response.data;
      if (datas && Array.isArray(datas.result)) {
        setData(datas.result);
      } else {
        setData([]); // Fallback to an empty array if result is not an array
      }

    } catch (error) {
      setData([]);
    } finally {
      setIsLoading2(false);
    }
  };

  useEffect(() => {
      fetchData();
  }, []);
  
  if (isLoader2) {
    return (
      <div className="container container-slim py-4">
        <div className="mb-3">
        <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
        </div>
        <div className="text-center">
          <div className="text-secondary mb-3">Cargando servicios...</div>
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }

  
  const createSubmit = async (e) => {
    e.preventDefault();

    if (!hasPermission('m.rentas.servicios.agregar')) {
      return null;
    }

      const jwt = Cookies.get('jwt');
  
      const formData = {
        name: e.target['name'].value,
        costo: e.target['costo'].value,
      };
  
      try {
        const response = await axios.post(apiURL, {
          type: '/munity/rentas',
          option: '/servicios/create',
          ...formData,
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });
    
        const result = response.data;

        if (!result.valid) {
          Toast.fire({ icon: 'error', title: result.message });
          return;
        }
        Toast.fire({ icon: 'success', title: result.message });
        fetchData();
      } catch (error) {
        Toast.fire({ icon: 'error', title: 'Error en la accion.' });
      }
  };

  const editSubmit = async (e, id, confirm, name, costo) => {
    e.preventDefault();

    if (!hasPermission('m.rentas.servicios.modificar')) {
      return null;
    }

    if (confirm) {
      const jwt = Cookies.get('jwt');
  
      const formData = {
        id: id,
        name: e.target['name'].value,
        costo: e.target['costo'].value,
      };
  
      try {
        const response = await axios.post(apiURL, {
          type: '/munity/rentas',
          option: '/servicios/edit',
          ...formData,
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });
    
        const result = response.data;

        if (!result.valid) {
          Toast.fire({ icon: 'error', title: result.message });
          return;
        }
        Toast.fire({ icon: 'success', title: result.message });
        fetchData();
      } catch (error) {
        Toast.fire({ icon: 'error', title: 'Error en la accion.' });
      }
    } else {
      const myModal = new window.bootstrap.Modal(document.getElementById('modificar-servicio'));
      myModal.show();
      setSelectCiu(id);
      setModifyName(name);
      setModifyCosto(costo);
    }
  };
  
  const deleteSubmit = async (e, id, confirm) => {
    e.preventDefault();

    if (!hasPermission('m.rentas.servicios.eliminar')) {
      return null;
    }

    if (confirm) {
      const jwt = Cookies.get('jwt');
  
      const formData = {
        id: id,
      };
  
      try {
        const response = await axios.post(apiURL, {
          type: '/munity/rentas',
          option: '/servicios/delete',
          ...formData,
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });
    
        const result = response.data;

        if (!result.valid) {
          Toast.fire({ icon: 'error', title: result.message });
          return;
        }
        Toast.fire({ icon: 'success', title: result.message });
        fetchData();
      } catch (error) {
        Toast.fire({ icon: 'error', title: 'Error en la accion.' });
      }
    } else {
      const myModal = new window.bootstrap.Modal(document.getElementById('eliminar-servicio'));
      myModal.show();
      setSelectCiu(id);
    }
  };


  return (
      <div className={`col-lg-3 col-md-4 col-sm-12 ${!col && ("d-none d-md-inline-block")}`}>
        <div className="list-group card-list-group">
          <div className="card mb-1">
            <div className="card-header">
              <h3 className="card-title">Servicios</h3>
              <div className="card-actions">
                <a href="#" className="btn" data-bs-toggle="modal" data-bs-target="#agregar-servicio">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-plus"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 5l0 14" /><path d="M5 12l14 0" /></svg>
                  Agregar
                </a>
              </div>
            </div>
          </div>
          <div className="card">
            {data.length > 0 &&
              data.map((item, index) => {

                return (
                    <div className="list-group-item">
                        <div className="row g-2 align-items-center">
                        <div className="col">
                            {item.nombre}
                            <div className="text-secondary">
                            ${Number(item.monto).toLocaleString('es-ES')} - {item.status === 1 ? 'Activo' : 'Inactivo'}
                            </div>
                        </div>

                        <div className="col-auto lh-1">
                            <div className="">
                              {hasPermission('m.rentas.servicios.modificar') && (
                                <a href="#" className="btn-action text-warning" onClick={(e) => editSubmit(e, item.id, false, item.nombre, item.monto)}>
                                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-pencil"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" /><path d="M13.5 6.5l4 4" /></svg>
                                </a>
                              )}
                              {hasPermission('m.rentas.servicios.eliminar') && (
                                <a href="#" className="btn-action text-danger" onClick={(e) => deleteSubmit(e, item.id, false)}>
                                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7h16" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /><path d="M10 12l4 4m0 -4l-4 4" /></svg>
                                </a>
                              )}
                            </div>
                        </div>
                        </div>
                    </div>
                );
              })
            }
          </div>
        </div>
          
        {hasPermission('m.rentas.servicios.agregar') && (
        <div className="modal modal-blur fade" id="agregar-servicio" tabIndex={-1} style={{display: 'none'}} aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <form id="agregar_vale_combustible" method="POST" className="modal-content" onSubmit={(e) => createSubmit(e)}>
              <div className="modal-header">
                <h5 className="modal-title">Agregando nuevo servicio</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">Nombre</label>
                  <input type="text" className="form-control" name="name" placeholder="Desagote" />
                </div>
                <div className="mb-0">
                  <label className="form-label">Costo del servicio</label>
                  <input type="text" className="form-control" name="costo" placeholder="3000" />
                </div>

              </div>
            
              <div className="modal-footer">
                <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                  Cancel
                </a>
                <button type="submit" className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 5l0 14" /><path d="M5 12l14 0" /></svg>
                  Crear
                </button>
              </div>
            </form>
          </div>
        </div>
        )}

        {hasPermission('m.rentas.servicios.modificar') && (
        <div className="modal modal-blur fade" id="modificar-servicio" tabIndex={-1} style={{display: 'none'}} aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <form id="agregar_vale_combustible" method="POST" className="modal-content" onSubmit={(e) => editSubmit(e, selectCiu, true, '', '')}>
              <div className="modal-header">
                <h5 className="modal-title">Servicio {modifyName}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">Nombre</label>
                  <input type="text" className="form-control" name="name" placeholder="Desagote" defaultValue={modifyName} />
                </div>
                <div className="mb-0">
                  <label className="form-label">Costo del servicio</label>
                  <input type="text" className="form-control" name="costo" placeholder="3000" defaultValue={modifyCosto} />
                </div>

              </div>
            
              <div className="modal-footer">
                <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                  Cancel
                </a>
                <button type="submit" className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-device-floppy me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M14 4l0 4l-6 0l0 -4" /></svg>
                  Guardar Cambios
                </button>
              </div>
            </form>
          </div>
        </div>
        )}

        {hasPermission('m.rentas.servicios.eliminar') && (
          <div className="modal modal-blur fade" id="eliminar-servicio" tabIndex={-1} role="dialog" aria-modal="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
              <div className="modal-content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                <div className="modal-status bg-danger" />
                <div className="modal-body text-center py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon mb-2 text-danger icon-lg"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
                  <h3>¿Estas realmente seguro?</h3>
                  <div className="text-secondary">Confirme la eliminacion definitiva del servicio.</div>
                </div>
                <div className="modal-footer">
                  <div className="w-100">
                    <div className="row">
                      <div className="col">
                        <a href="#" className="btn w-100" data-bs-dismiss="modal">
                          Cancelar
                        </a>
                      </div>
                      <div className="col">
                        <a href="#" className="btn btn-danger w-100" data-bs-dismiss="modal" onClick={(e) => deleteSubmit(e, selectCiu, true)}>
                          Confirmar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
  );
};

export default Content
