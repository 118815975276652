import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import '@tabler/core/dist/js/tabler.min.js';
import { ReactSortable } from 'react-sortablejs';
import IconBrowser from '../../components/TablerIcons';
import UnSaved from '../../components/UnSaved';
import axios from 'axios';
import Cookies from 'js-cookie';
import useNavigationBlocker from '../../components/useUnsavedPrompt';

const Content = ({ config, apiURL }) => {
  const { id } = useParams();
  const [selectedIcon, setSelectedIcon] = useState('<svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-rocket"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3" /><path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3" /><path d="M15 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /></svg>');
  const [datas, setDatas] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  const [items, setItems] = useState(datas);
  const itemSelect = items.find(item => item.id == id);
  const [itemsEdit, setItemsEdit] = useState(itemSelect);
  const [members, setMembers] = useState([]);

  const colorOptions = [
    { value: 'blue', label: 'Blue' },
    { value: 'red', label: 'Red' },
    { value: 'green', label: 'Green' },
    { value: 'pink', label: 'Pink' },
    { value: 'yellow', label: 'Yellow' },
    { value: 'azure', label: 'Azure' },
    { value: 'indigo', label: 'Indigo' },
    { value: 'purple', label: 'Purple' },
    { value: 'orange', label: 'Orange' },
    { value: 'lime', label: 'Lime' },
  ];

  const permissionsOptions = [
    { name: 'Administrador', description: 'Todos los permisos disponibles.', permission: 'm.*' },

    { name: 'Ciudadanos', description: 'Ver el listado y la informacion de los ciudadanos.', permission: 'm.ciudadanos' },
    { name: 'Ciudadanos Agregar', description: 'Crear/Agregar a un nuevo ciudadano.', permission: 'm.ciudadanos.agregar' },
    { name: 'Ciudadanos Modificar', description: 'Modificar la informacion de un ciudadano.', permission: 'm.ciudadanos.modificar' },
    { name: 'Ciudadanos Eliminar', description: 'Eliminar a un ciudadano.', permission: 'm.ciudadanos.eliminar' },

    { name: 'Compras', description: 'Ver el listado y la informacion de los compras.', permission: 'm.compras' },
    { name: 'Compras Agregar', description: 'Crear/Agregar a un nuevo compras.', permission: 'm.compras.agregar' },
    { name: 'Compras Modificar', description: 'Modificar la informacion de un compras.', permission: 'm.compras.modificar' },
    { name: 'Compras Eliminar', description: 'Eliminar a un compras.', permission: 'm.compras.eliminar' },

    { name: 'Combustible', description: 'Ver el listado y la informacion de los combustible.', permission: 'm.combustible' },
    { name: 'Combustible Agregar', description: 'Crear/Agregar a un nuevo combustible.', permission: 'm.combustible.agregar' },
    { name: 'Combustible Modificar', description: 'Modificar la informacion de un combustible.', permission: 'm.combustible.modificar' },
    { name: 'Combustible Eliminar', description: 'Eliminar a un combustible.', permission: 'm.combustible.eliminar' },
  ];

  const fetchData = async () => {
    try {
      const jwt = Cookies.get('jwt');
      const configResponse = await axios.post(apiURL, {
        type: '/munity/roles',
        option: '/overview'
      }, { headers: { Authorization: `Bearer ${jwt}` } });
  
      const configData = configResponse.data;

      if (configData && configData.result) {
        localStorage.setItem('roles_list', JSON.stringify(configData.result));
        Cookies.set('roles_list', true, { expires: 1 / 24 });

        localStorage.setItem('members_list', JSON.stringify(configData.members));
        Cookies.set('members_list', true, { expires: 1 / 24 });

        const itemSelect = configData.result.find(item => item.id == id);
        setItemsEdit(itemSelect);
        setSelectedIcon(itemSelect.icon);
        setItems(configData.result);
        setMembers(configData.members);
      } else {
        console.error("No hay datos en la respuesta de la API.");
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setItems([]);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    const timingCookie = Cookies.get('roles_list');
    const membersCookie = Cookies.get('members_list');
    const ciudadanosData = JSON.parse(localStorage.getItem('roles_list'));
    const membersData = JSON.parse(localStorage.getItem('members_list'));

    if (timingCookie && membersCookie) {
      setMembers(membersData);
      setDatas(ciudadanosData);
      setItems(ciudadanosData);
      const itemSelect = ciudadanosData.find(item => item.id == id);
      setItemsEdit(itemSelect);
      setSelectedIcon(itemSelect.icon);
      setIsLoader(false);
    } else {
      fetchData();
    }
  }, [id]); 


  const handleEnd = (event) => {
    const { oldIndex, newIndex } = event;
    let newItems = [...items];

    const [movedItem] = newItems.splice(oldIndex, 1);

    newItems.splice(newIndex, 0, movedItem);

    newItems = newItems.map((item, index) => ({
      ...item,
      position: index + 1,
    }));

    setItems(newItems);
    
  };

  const addNewItem = async () => {
    try {
      const jwt = Cookies.get('jwt');
      const configResponse = await axios.post(apiURL, {
        type: '/munity/roles',
        option: '/crear',
      }, { headers: { Authorization: `Bearer ${jwt}` } });
  
      const configData = configResponse.data;

      if (configData && configData.result) {
        localStorage.setItem('roles_list', JSON.stringify(configData.result));
        Cookies.set('roles_list', true, { expires: 1 / 24 });

        localStorage.setItem('members_list', JSON.stringify(configData.members));
        Cookies.set('members_list', true, { expires: 1 / 24 });

        setItems(configData.result);
        setMembers(configData.members);
      } else {
        console.error("No hay datos en la respuesta de la API.");
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setItems([]);
    } finally {
      setIsLoader(false);
    }
  };

  const changeIcon = (svg) => {
    setSelectedIcon(svg);
    setItemsEdit((prevItemsEdit) => ({
      ...prevItemsEdit,
      icon: svg,
    }));
  };

  const handleMemberToggle = (memberId, roleId, isChecked) => {
    setItemsEdit((prevItemsEdit) => {
      const memberExists = prevItemsEdit.members?.some((member) => member.user === memberId);
  
      if (isChecked && !memberExists) {
        return {
          ...prevItemsEdit,
          members: [
            ...(prevItemsEdit.members || []),
            { id: null, user: memberId, role: roleId, since: null },
          ],
        };
      } else if (!isChecked) {
        return {
          ...prevItemsEdit,
          members: prevItemsEdit.members.filter((member) => member.user === memberId),
        };
      }
  
      return prevItemsEdit;
    });
  };

  const guardarCambios = async () => {
    setIsLoader(true); // Activa el loader
    try {
      // Busca el índice del item con el mismo id que itemsEdit
      const itemIndex = items.findIndex((item) => item.id === itemsEdit.id);
  
      if (itemIndex !== -1) {
        // Crea una copia de items y reemplaza el item encontrado con itemsEdit
        const updatedItems = [...items];
        updatedItems[itemIndex] = itemsEdit;
  
        const jwt = Cookies.get('jwt');
        const configResponse = await axios.post(apiURL, {
          type: '/munity/roles',
          option: '/modificar',
          newdata: [itemsEdit], // Enviar solo el elemento modificado
        }, { headers: { Authorization: `Bearer ${jwt}` } });
  
        const configData = configResponse.data;
  
        if (configData.valid) {
          fetchData();
        } else {
          console.error("No hay datos en la respuesta de la API.");
        }
      } else {
        console.error("No se encontró el elemento con el mismo id en items.");
      }
    } catch (error) {
      console.error('Error al actualizar los datos:', error);
      setItems([]);
    } finally {
      setIsLoader(false); // Desactiva el loader al finalizar
    }
  };
  
  const sortedItems = [...items].sort((a, b) => a.position - b.position);
  const hasUnsavedChanges = JSON.stringify(itemSelect) !== JSON.stringify(itemsEdit) ? true : false;
  useNavigationBlocker(hasUnsavedChanges);
  
  if (isLoader) {
    return (
      <div className="container container-slim py-4">
        <div className="mb-3">
        <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
        </div>
        <div className="text-center">
          <div className="text-secondary mb-3">Cargando rol...</div>
          <div className="progress progress-sm">
            <div className="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='page-body'>
      {itemSelect != itemsEdit && <UnSaved code={itemSelect} setItemsEdit={setItemsEdit} guardarCambios={guardarCambios} />}
<div className="row row-cards mt-4">
  <div className="col-md-3">
      <div className="card sticky-top">
        <div className="card-header">
          <h3 className="card-title">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon me-2 text-primary"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8 9l4 -4l4 4" /><path d="M16 15l-4 4l-4 -4" /></svg>
            Roles
          </h3>
          <div className="card-actions">
            <a href="#" class="btn btn-primary btn-icon" onClick={() => addNewItem()}>
              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-circle-plus"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M9 12h6" /><path d="M12 9v6" /></svg>
            </a>
          </div>
        </div>
        <div className="list-group list-group-flush">
              <ReactSortable
                list={sortedItems}
                setList={setItems}
                onEnd={handleEnd}
              >
                {sortedItems.map((item) => (
                  <Link key={item.id} to={`/roles/${item.id}`} className={`list-group-item list-group-item-action ${item.id == id && "active"}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className={`icon me-2 text-${item.color}`}>
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" />
                    </svg>
                    {item.name}
                  </Link>
                ))}
              </ReactSortable>
        </div>
      </div>
  </div>

  <div className="col-md-9 animate__animated animate__fadeIn" id="tab_mark">

    <ul className="nav nav-tabs nav-bordered mb-4" role="tablist">
      <li className="nav-item" role="presentation"><a href="#display" className="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">Principal</a></li>
      <li className="nav-item" role="presentation"><a href="#permissions" className="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex={-1}>Permisos</a></li>
      <li className="nav-item" role="presentation"><a href="#manage-users" className="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex={-1}>Miembros</a></li>
    </ul>
    <div className="tab-content">
      <div id="display" className="card tab-pane active show" role="tabpanel">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Principal</h3>
              <p className="card-subtitle">Comienza modificando lo mas simple del rol.</p>
              <div className="row mb-3 align-items-end mt-3">
                <div className="col-auto dropdown">
                    <button className="avatar avatar-upload rounded" data-bs-toggle="dropdown" aria-label="Open user menu" aria-expanded="true" dangerouslySetInnerHTML={{ __html: selectedIcon }} />
                
                    <div className="dropdown-menu dropdown-menu-arrow" style={{ width: '400px', zIndex: '99999999'}}>
                    <IconBrowser onIconSelect={(svg) => changeIcon(svg)} />
                    </div>
                </div>
                <div className="col">
                  <label className="form-label">Nombre</label>
                  <input type="text" className="form-control" placeholder="DevByBit Group" value={itemsEdit ? itemsEdit.name : 'Error'} 
                  onChange={(event) => setItemsEdit({ ...itemsEdit, name: event.target.value })} />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Seleccione el color</label>
                <div className="row g-2">

                  {colorOptions.map((color) => (
                    <div className="col-auto" key={color.value}>
                      <label className="form-colorinput">
                        <input
                          name="color"
                          type="radio"
                          value={color.value}
                          className="form-colorinput-input"
                          checked={itemsEdit.color === color.value}
                          onChange={(event) => setItemsEdit({ ...itemsEdit, color: event.target.value })}
                        />
                        <span className={`form-colorinput-color bg-${color.value}`} />
                      </label>
                    </div>
                  ))}

                </div>
              </div>


            </div>
          </div>
        </div>
      </div>

      <div id="permissions" class="tab-pane" role="tabpanel">
        <div className="col-md-12 mt-2">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Permisos</h3>
              <p className="card-subtitle">¡Ya estás listo para comenzar con los permisos!</p>

              <hr />
              <div className="mt-3">
                <div className="divide-y">

                {permissionsOptions.map((perm) => (
  <div key={perm.name} for={`permission-${perm.permission}`} className="permissions-select">
    <label className="row">
      <span className="col">
        {perm.name}
        <br />
        <span className="text-secondary">{perm.description}</span>
      </span>
      <span className="col-auto">
        <label className="form-check form-check-single form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id={`permission-${perm.permission}`}
            checked={itemsEdit.permissions.some(p => p.permission === perm.permission)}
            onChange={() => {
              setItemsEdit(prevItemsEdit => {
                const permissionExists = prevItemsEdit.permissions.some(p => p.permission === perm.permission);
                const updatedPermissions = permissionExists
                  ? prevItemsEdit.permissions.filter(p => p.permission !== perm.permission)
                  : [...prevItemsEdit.permissions, { id: perm.id, permission: perm.permission, role: "1", since: new Date().toISOString() }];
                
                return { ...prevItemsEdit, permissions: updatedPermissions };
              });
            }}
          />
        </label>
      </span>
    </label>
  </div>
))}




                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      
      <div id="manage-users" class="tab-pane" role="tabpanel">
        <div className="row g-2 align-items-center mb-3">
          <div className="col">
            <h2 className="page-title">
              Manage Members
            </h2>
          </div>
        </div>


        
{members.map((member) => {
  const isChecked = itemsEdit.members?.some((editMember) => Number(editMember.user) === Number(member.id));

  return (
    <div className="card mb-1" key={member.id} htmlFor="member-select">
      <div className="memberRow_fe3229 snipcss-pOeOt" aria-expanded="false" role="button" tabIndex={0}>
        <div className="container_db5049 memberDetails_fe3229">
          <div className="wrapper_c51b4e avatar_db5049 style-RTeFd" role="img" aria-label={`${member.name}`} aria-hidden="false" id="style-RTeFd">
            <svg width={24} height={24} viewBox="0 0 24 24" className="mask_c51b4e svg_c51b4e" aria-hidden="true">
              <foreignObject x={0} y={0} width={24} height={24} mask="url(#svg-mask-avatar-default)">
                <div className="avatarStack_c51b4e">
                  <img src={config.uri + member.avatar} alt=" " className="avatar_c51b4e" aria-hidden="true" />
                </div>
              </foreignObject>
            </svg>
          </div>
          <div className="textContainer_db5049">
            <span className="text-sm/semibold_dc00ef name_db5049 style-yFx6x" data-text-variant="text-sm/semibold" id="style-yFx6x">{member.name}</span>
          </div>
        </div>
        <div className="removeButtonContainer_fe3229">
          <label className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={isChecked}
              id={`member-select ${isChecked ? true : false}`}
              onChange={() => handleMemberToggle(member.id, itemsEdit.id, !isChecked)}
            />
            <span className="form-check-label"></span>
          </label>
        </div>
      </div>
    </div>
  );
})}


      </div>
    </div>
  </div>

</div>



    </div>
  );
};

export default Content
