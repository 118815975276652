import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import axios from 'axios';
import Cookies from 'js-cookie'; 
import IMask from 'imask';
import Swal from 'sweetalert2';
import { UserContext } from '../../components/UserProvider.js';
import { PermissionsContext } from '../../components/hasPermissions.js';
import ModalOptions from './Options.js';

import ScannerDocument from '../scanner/Documento';

const initialCoordinates = { lat: -31.0065318997796, lng: -65.9905090928078 };

const Content = ({ config, apiURL, details, page }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const { userLogged, logoutUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState(initialCoordinates);
  const [ciu, setCiu] = useState({});
  const [isLoader, setIsLoader] = useState(true);
  const [markerPosition, setMarkerPosition] = useState(initialCoordinates);
  const [sendData, setSendData] = useState('');
  const [selectOpts, setSelectOpts] = useState('');
  
  const [day, setDay] = useState('01');
  const [month, setMonth] = useState('01');
  const [year, setYear] = useState(2000);
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 120;

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });

  const handleMarkerDragEnd = useCallback((e) => {
    setMarkerPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }, []);

  const fetchData = async () => {
    setIsLoader(true);
    try {
        const jwt = Cookies.get('jwt');
        const configResponse = await axios.post(apiURL, {
            type: '/munity/ciudadanos',
            option: '/opciones'
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });

        const configData = configResponse.data;
        setData(configData.result);
    } catch (error) {
        setData([]);
    } finally {
        setIsLoader(false);
    }
  }
  
  const fetchCiu = async () => {
    setIsLoader(true);
    try {
        const jwt = Cookies.get('jwt');
        const configResponse = await axios.post(apiURL, {
            type: '/munity/ciudadanos',
            option: '/verify',
            id: details,
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });

        const configData = configResponse.data;
        setCiu(configData.result);

        const nacimiento = configData.result.nacimiento.split('-');
        setDay(nacimiento[2]);
        setMonth(nacimiento[1]);
        setYear(nacimiento[0]);
        setMarkerPosition({ lat: parseFloat(configData.result.latitud), lng: parseFloat(configData.result.longitud) });
        fetchData();
    } catch (error) {
        setCiu([]);
    } finally {
        setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchCiu();
  }, []);
  
  // Formatear la fecha en `dd/mm/aaaa`
  const formattedDate = `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;

  // Opciones para los días, meses y años
  const dayOptions = Array.from({ length: 31 }, (_, i) => (
    <option key={i + 1} value={i + 1}>
      {i + 1}
    </option>
  ));

  const monthOptions = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ].map((month, index) => (
    <option key={index + 1} value={index + 1}>
      {month}
    </option>
  ));

  const yearOptions = Array.from({ length: 121 }, (_, i) => (
    <option key={startYear + i} value={startYear + i}>
      {startYear + i}
    </option>
  )).reverse();
  
  const handleOptionChange = (e) => {
    const { value, checked } = e.target;
  
    setSelectOpts((prevOpts) => {
      const options = new Set(prevOpts ? prevOpts.split(', ') : []);
    
      if (checked) {
        options.add(value);
      } else {
        options.delete(value); 
      }
    
      return Array.from(options).join(', ');
    });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const jwt = Cookies.get('jwt');
    const fechaNacimiento = e.target['nacimiento'].value;
    const [dia, mes, anio] = fechaNacimiento.split('/');
    const fechaFormateada = `${anio}-${mes}-${dia}`;

    const formData = {
      id: details,
      nombre: e.target['nombre'].value,
      dni: e.target['dni'].value,
      nacimiento: fechaFormateada,
      telefono: e.target['telefono'].value,
      domicilio: e.target['domicilio'].value,
      localidad: e.target['localidad'].value,
      email: e.target['email'].value,
      trabajo: e.target['trabajo'].value,
      ingresos: e.target['ingresos'].value,
      ingresos1: e.target['ingresos1'].value,
      opts: selectOpts,
      creator: userLogged.id,
      lat: e.target['latitud'].value,
      lng: e.target['longitud'].value,
    };

    try {
      const response = await axios.post(apiURL, {
        type: '/munity/ciudadanos',
        option: '/editar',
        ...formData,
      }, { headers: { Authorization: `Bearer ${jwt}`, }, });
  
      const result = response.data;
      if (!result.valid) {
        Toast.fire({ icon: 'error', title: result.message });
        return;
      }
      Cookies.remove('ciudadanos_list');
      Toast.fire({ icon: 'success', title: result.message });

      setTimeout(() => {
        navigate(`/ciudadanos/${details}`);
      }, 1000);
    } catch (error) {
      Toast.fire({ icon: 'error', title: 'Error en la accion.' });
    }
  };

  if (isLoader) {
    return (
      <div class="container container-slim py-4">
        <div class="text-center">
          <div class="mb-3">
            <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
          </div>
          <div class="text-secondary mb-3">Preparando formulario</div>
          <div class="progress progress-sm">
            <div class="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }


  const ingresos = ciu.ingresos ? ciu.ingresos.split('#') : '0#0';
  const selectedOptions = ciu.options ? ciu.options.split(', ') : '';

  return (
    <>
    <ModalOptions config={config} apiURL={apiURL} fetchData={fetchData} />
<form id="create_ciudadanos" method="POST" className="row" onSubmit={handleSubmit}>

  <div className="col-sm-6">
    <input type="hidden" defaultValue="create_ciudadano" name="result" />
    <label htmlFor="dni" className="form-label required">DNI:</label>
    <div className="input-icon mb-3">
      <span className="input-icon-addon">
        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
      </span>
      
      <input
        type="text"
        className="form-control"
        defaultValue={ciu.dni}
        name="dni"
        placeholder="000000000"
        pattern="\d{8,9}"            // Requiere entre 8 y 9 dígitos
        minLength="8"                 // Mínimo 8 dígitos
        maxLength="9"                 // Máximo 9 dígitos
        onInput={(e) => {
            // Limita la longitud de entrada a un máximo de 9 dígitos
            e.target.value = e.target.value.slice(0, 9);
        }}
      />
    </div>
    <label htmlFor="nombre" className="form-label required">Apellido, Nombre:</label>
    <div className="input-icon mb-3">
      <span className="input-icon-addon">
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M7.5 7.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M3 6v5.172a2 2 0 0 0 .586 1.414l7.71 7.71a2.41 2.41 0 0 0 3.408 0l5.592 -5.592a2.41 2.41 0 0 0 0 -3.408l-7.71 -7.71a2 2 0 0 0 -1.414 -.586h-5.172a3 3 0 0 0 -3 3z" /></svg>
      </span>
      <input type="text" className="form-control bg-theme"  id="nombre" placeholder="Panizzino, Juan Manuel"
          defaultValue={ciu.nombre} name="nombre" required={true} />
    </div>
    <label htmlFor="nacimiento" className="form-label required">Fecha de Nacimiento:</label>


    <div className="btn-group w-100 mb-3" role="group">
        <div className="form-floating w-100">
          <select
            className="form-select"
            id="floatingDaySelect"
            aria-label="Select Day"
            value={day}
            onChange={(e) => setDay(e.target.value)}
          >
            {dayOptions}
          </select>
          <label htmlFor="floatingDaySelect">Día</label>
        </div>
        
        <div className="form-floating w-100">
          <select
            className="form-select"
            id="floatingMonthSelect"
            aria-label="Select Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          >
            {monthOptions}
          </select>
          <label htmlFor="floatingMonthSelect">Mes</label>
        </div>
        
        <div className="form-floating w-100">
          <select
            className="form-select"
            id="floatingYearSelect"
            aria-label="Select Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            {yearOptions}
          </select>
          <label htmlFor="floatingYearSelect">Año</label>
        </div>
      </div>

    <input
        type="hidden"
        className="form-control mt-3"
        name="nacimiento"
        placeholder="dd/mm/aaaa"
        value={formattedDate}
        required={true}
      />


    <label htmlFor="telefono" className="form-label required">Telefono:</label>
    <div className="input-icon mb-3">
      <span className="input-icon-addon">
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>
      </span>
      
      <input type="number" className="form-control" defaultValue={ciu.telefono} placeholder="0000000000" name="telefono" minLength={10} required={true} pattern="\d{10}" maxLength="10" onInput={(e) => e.target.value = e.target.value.slice(0, 10)} />
    </div>
    <div className="mb-3">
      <label className="form-label">
        <div className="row align-items-center">
            <div className='col'>Localidad: <i className="text-danger">*</i></div>
            <div className='col-auto'>
                <a href="" className="link-primary" data-bs-toggle="modal" data-bs-target="#new-localidad">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-plus me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 5l0 14" /><path d="M5 12l14 0" /></svg>
                    Agregar
                </a>
            </div>
        </div>
      </label>
      <div className="form-selectgroup">
        
        {ciu.localidad && data.localidades && data.localidades.map((opcion, index) => {
            const locSelect = opcion.toLowerCase() == ciu.localidad.toLowerCase() ? true : false;
            return (
            <label className="form-selectgroup-item" key={index}>
                <input type="radio" name="localidad" defaultValue={opcion} className="form-selectgroup-input" defaultChecked={locSelect} />
                <span className="form-selectgroup-label">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon me-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                </svg>
                {opcion}
                </span>
            </label>
            );
        })}
      </div>
    </div>
  </div>
  <div className="col-sm-6">
    <label htmlFor="email" className="form-label">Direccion de Correo Electronico:</label>
    <div className="input-icon mb-0">
      <span className="input-icon-addon">
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" /><path d="M3 7l9 6l9 -6" /></svg>
      </span>
      <input type="email" className="form-control bg-theme" id="email" placeholder="soporte@devbybit.com" name="email" defaultValue={ciu.email} />
    </div>
    <p className="text-secondary mb-3">Si no posee un email el ciudadano no complete el campo.</p>
    <label htmlFor="trabajo" className="form-label">Trabajo:</label>
    <div className="input-icon mb-0">
      <span className="input-icon-addon">
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M11.414 10l-7.383 7.418a2.091 2.091 0 0 0 0 2.967a2.11 2.11 0 0 0 2.976 0l7.407 -7.385" /><path d="M18.121 15.293l2.586 -2.586a1 1 0 0 0 0 -1.414l-7.586 -7.586a1 1 0 0 0 -1.414 0l-2.586 2.586a1 1 0 0 0 0 1.414l7.586 7.586a1 1 0 0 0 1.414 0z" /></svg>
      </span>
      <input type="text" className="form-control bg-theme" id="trabajo" placeholder="Emprendedor/Estudiante/Albañil" name="trabajo" defaultValue={ciu.trabajo} />
    </div>
    <p className="text-secondary mb-3">Si no posee ningun trabajo el ciudadano no complete el campo.</p>
    <label htmlFor="ingresos" className="form-label">Ingresos:</label>
    <div className="input-group mb-2">
      <input type="text" className="form-control" placeholder={1.000} autoComplete="off" name="ingresos" id="ingresos" defaultValue={ingresos[0]} />
      <span className="input-group-text">a</span>
      <input type="text" className="form-control" placeholder={10.000} autoComplete="off" name="ingresos1" id="ingresos1" defaultValue={ingresos[1]} />
    </div>
    <input type="text" className="form-control bg-theme" hidden id="latitud" placeholder name="latitud" value={markerPosition.lat} />
    <input type="text" className="form-control bg-theme" hidden id="longitud" placeholder name="longitud" value={markerPosition.lng} />
    <label htmlFor="options" className="form-label">
        <div className="row align-items-center">
            <div className='col'>Opciones:</div>
            <div className='col-auto'>
                <a href="" className="link-primary" data-bs-toggle="modal" data-bs-target="#new-opcion">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-plus me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 5l0 14" /><path d="M5 12l14 0" /></svg>
                    Agregar
                </a>
            </div>
        </div>
    </label>
    <div className="mb-3">
      <div className="form-selectgroup">
        {data.opciones && data.opciones.map((opcion, index) => {
            const isChecked = selectedOptions.includes(opcion) ? true : false;
            return (
            <label className="form-selectgroup-item" key={index}>
              <input type="checkbox" name="options" defaultValue={opcion} className="form-selectgroup-input" onChange={handleOptionChange} defaultChecked={isChecked} />
              <span className="form-selectgroup-label">{opcion}</span>
            </label>
            );
        })}
      </div>
    </div>
    <label htmlFor="domicilio" className="form-label required">Domicilio:</label>
    <div className="input-icon mb-3">
      <span className="input-icon-addon">
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l-2 0l9 -9l9 9l-2 0" /><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" /><path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
      </span>
      <input type="text" className="form-control" placeholder="25 de Mayo" id="domicilio" name="domicilio" autoComplete="off" required={true} defaultValue={ciu.domicilio} />
    </div>
  </div>
  <div className="col-12 mb-3">
    <div className="card">
      <div className="card-body">
        <div className="col-sm-12 mb-3">
          <h3 className="mb-0">Geo Localización <i className="text-danger">*</i></h3>
          <p className="font-14">Ten en cuenta que la geolocalización en una computadora a menudo es menos precisa que en un dispositivo móvil debido a las diferencias en la disponibilidad de hardware y métodos de obtención de ubicación.</p>
          <div style={{ width: "100%", height: "50vh" }}>
            <APIProvider apiKey="AIzaSyCtfTodmrRUsCSCzeTs2Ss2e2tNva5DTyg">
                <Map
                defaultCenter={initialCoordinates} // Establece el centro solo la primera vez
                defaultZoom={15}
                mapContainerStyle={{ width: "100%", height: "100%" }}
                >
                <Marker
                    position={markerPosition}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                />
                </Map>
            </APIProvider>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="col-sm-12">
    <button type="submit" className="btn btn-success">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-device-floppy me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M14 4l0 4l-6 0l0 -4" /></svg>
      Guardar Cambios
    </button>
  </div>
</form>

    </>
  );
};

export default Content
