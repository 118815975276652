import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { UserContext } from '../../../components/UserProvider.js';
import { PermissionsContext } from '../../../components/hasPermissions.js';

const Content = ({ config, apiURL }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const { userLogged, logoutUser } = useContext(UserContext);

  const [ ciudadanos, setCiudadanos ] = useState({});
  const [ isLoader, setIsLoading ] = useState(true);

  const fetchData = async () => {
    const jwt = Cookies.get('jwt');
    try {
        const ciudadanosRes = await axios.post(apiURL, {
            type: '/munity/general',
            option: '/ciudadanos/list'
        }, {
            headers: { Authorization: `Bearer ${jwt}` }
        });
        const ciudadanosResData = ciudadanosRes.data;
        setCiudadanos(ciudadanosResData);
    } catch (error) {
        setCiudadanos([]);
    } finally {
        setIsLoading(false);
    }
  };

  useEffect(() => {
      fetchData();
  }, []);

  if (isLoader) {
    return (
      <div class="container container-slim py-4">
        <div class="text-center">
          <div class="mb-3">
            <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
          </div>
          <div class="text-secondary mb-3">Preparando interfaz</div>
          <div class="progress progress-sm">
            <div class="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-5">

    </div>
  );
};

export default Content
