import { useEffect } from "react";
import { useNavigate, useLocation, useBeforeUnload } from "react-router-dom";

const useNavigationBlocker = (hasUnsavedChanges) => {
  const message = 'Tienes cambios sin guardar. ¿Estás seguro de que quieres salir?';
  const navigate = useNavigate();
  const location = useLocation();

  // Efecto para interceptar la navegación interna
  useEffect(() => {
    const unblock = () => {
      // Prevenir navegación si hay cambios sin guardar
      if (hasUnsavedChanges) {
        const confirmLeave = window.confirm(message);
        if (!confirmLeave) {
          // Si el usuario cancela la navegación, detenerla.
          return true;
        }
      }
      return false;
    };

    // Necesitamos observar la navegación manual de tu app, pero React Router no expone un listener directo para navegaciones.
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    // Interceptar pushState (navegaciones programáticas)
    window.history.pushState = function (...args) {
      if (!unblock()) {
        originalPushState.apply(window.history, args);
      }
    };

    // Interceptar replaceState (reemplazos de rutas programáticos)
    window.history.replaceState = function (...args) {
      if (!unblock()) {
        originalReplaceState.apply(window.history, args);
      }
    };

    return () => {
      // Restaurar la funcionalidad original
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [hasUnsavedChanges, location, message, navigate]);

  // Manejar recarga o cierre del navegador
  useBeforeUnload(
    (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = message;
      }
    },
    { capture: true }
  );
};

export default useNavigationBlocker;
