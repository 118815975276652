import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import axios from 'axios';
import Cookies from 'js-cookie'; 
import IMask from 'imask';
import Swal from 'sweetalert2';
import { UserContext } from '../../components/UserProvider.js';
import { PermissionsContext } from '../../components/hasPermissions.js';

const Content = ({ config, apiURL, details, page }) => {
  const { hasPermission } = useContext(PermissionsContext);
  const { userLogged, logoutUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [opciones, setOpciones] = useState([]);
  const [item, setItem] = useState([]);
  const [expireUse, setExpireUse] = useState(false);
  const [carUse, setCarUse] = useState(false);

  const [isLoader, setIsLoader] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
      toast.onclick = () => Swal.close();
    },
  });
  
  const fetchData = async () => {
    setIsLoader(true);
    try {
        const jwt = Cookies.get('jwt');
        const configResponse = await axios.post(apiURL, {
            type: '/munity/combustible',
            option: '/details',
            id: details
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });

        const configData = configResponse.data;
        setItem(configData.result);

        const vehiculo = configData.result.vehiculo ? true : false;
        setCarUse(vehiculo);
        const expire = configData.result.vencimiento ? true : false;
        setExpireUse(expire);
    } catch (error) {
        setItem([]);
    } finally {
      setIsLoader(false);
    }
  }

  const fetchOptions = async () => {
    setIsLoader(true);
    try {
        const jwt = Cookies.get('jwt');
        const configResponse = await axios.post(apiURL, {
            type: '/munity/combustible',
            option: '/opciones',
        }, { headers: { Authorization: `Bearer ${jwt}`, }, });

        const configData = configResponse.data;
        setOpciones(configData);
    } catch (error) {
        setOpciones([]);
    } finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchData();
    fetchOptions();
  }, [details]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const jwt = Cookies.get('jwt');
    
    if (!hasPermission('m.combustible.modificar')) {
      return null;
    }

    const formData = {
      id: details,
      tipo: e.target['tipo'].value,
      estacion: e.target['estacion'].value,
      comb: e.target['combustible'].value,
      lts: e.target['litros'].value,
      obs: e.target['observaciones'].value,
      expire: e.target['expire'].value,
      car: e.target['car'].value,
      creator: userLogged.id,
    };

    try {
      const response = await axios.post(apiURL, {
        type: '/munity/combustible',
        option: '/editar',
        ...formData,
      }, { headers: { Authorization: `Bearer ${jwt}`, }, });
  
      const result = response.data;
      if (!result.valid) {
        Toast.fire({ icon: 'error', title: result.message });
        return;
      }
      Cookies.remove('combustible_list');
      Toast.fire({ icon: 'success', title: result.message });
      
      setTimeout(() => {
        navigate(`/combustible/${details}`);
      }, 1000);
    } catch (error) {
      Toast.fire({ icon: 'error', title: 'Error en la accion.' });
    }
  };

  
  if (isLoader) {
    return (
      <div class="container container-slim py-4">
        <div class="text-center">
          <div class="mb-3">
            <Link to={`${config.uri}`} class="navbar-brand"><img src={config.logo} height="36" alt={config.name} /></Link>
          </div>
          <div class="text-secondary mb-3">Preparando formulario</div>
          <div class="progress progress-sm">
            <div class="progress-bar progress-bar-indeterminate"></div>
          </div>
        </div>
      </div>
    );
  }

  

  if (item.autorizado) {
    return (
      <div className="alert alert-warning bg-theme" role="alert">
        <div className="d-flex">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v4" /><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" /><path d="M12 16h.01" /></svg>
          </div>
          <div>
            <h4 className="alert-title">La orden se encuentra autorizada.</h4>
            <div className="text-secondary">Mientras la orden se encuentre autorizada, No es posible eliminarla. Se tiene que dar de baja para poder modificarla, Cualquier de esta opción incluso darla de baja la tendra que autorizar el intendente, Una ves dada de baja se colocara inautorizada, Por lo tanto una vez completada la tendras que re enviar para autorizar.</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <form id="agregar_vale_combustible" method="POST" className="row" onSubmit={(e) => handleSubmit(e)}>
        
        <div className={`col-12`}>
          <div className="mb-3">
            <label className="form-label required" htmlFor="tipo">Tipos</label>
            <div className="form-selectgroup">
              <label className="form-selectgroup-item">
                <input type="radio" name="tipo" defaultValue="Bienes de Consumo" className="form-selectgroup-input" defaultChecked={item.tipo == 'Bienes de Consumo' ? true : false} />
                <span className="form-selectgroup-label">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon me-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /></svg>
                  Bienes de Consumo
                </span>
              </label>
              <label className="form-selectgroup-item">
                <input type="radio" name="tipo" defaultValue="Transferencia" className="form-selectgroup-input" defaultChecked={item.tipo == 'Transferencia' ? true : false} />
                <span className="form-selectgroup-label">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon me-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /></svg>
                  Transferencia
                </span>
              </label>
              <label className="form-selectgroup-item">
                <input type="radio" name="tipo" defaultValue="Ayuda" className="form-selectgroup-input" defaultChecked={item.tipo == 'Ayuda' ? true : false} />
                <span className="form-selectgroup-label">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon me-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /></svg>
                  Ayuda
                </span>
              </label>
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label required" htmlFor="combustible">Estaciones</label>
            <div className="form-selectgroup">
              {Array.isArray(opciones.comercios) && opciones.comercios.map((opcion, index) => {
                const isChecked = opcion.comercio == item.estacion ? true : false;
                return (
                  <label className="form-selectgroup-item" key={index}>
                      <input type="radio" name="estacion" defaultValue={opcion.comercio} className="form-selectgroup-input" defaultChecked={isChecked} />
                      <span className="form-selectgroup-label">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon me-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /></svg>
                          {opcion.comercio}
                      </span>
                  </label>
                );
              })}
              
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label required" htmlFor="combustible">Combustibles</label>
            <div className="form-selectgroup">
              {Array.isArray(opciones?.combustibles) && opciones.combustibles.map((opcion, index) => {
                const combustible = item?.combustible ? item.combustible.split('#') : ["", ""];
                const isChecked = opcion.combustible == combustible[0] ? true : false;
                return (
                  <label className="form-selectgroup-item" key={index}>
                      <input type="radio" name="combustible" defaultValue={opcion.combustible} className="form-selectgroup-input" defaultChecked={isChecked} />
                      <span className="form-selectgroup-label">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon me-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /></svg>
                          {opcion.combustible}
                      </span>
                  </label>
                );
              })}
            </div>
          </div>

          <label htmlFor="litros" className="col-sm-12 form-label required">Combustible Litros</label>
          <div className="mb-3 row">
            <div className="col-sm-12">
              <input type="number" className="form-control" min={1} id="litros" name="litros" defaultValue={item?.combustible ? item.combustible.split('#')[1] : ""} required={true} />
            </div>
          </div>
          
          <div className="mb-3">
            <label className="form-label required" htmlFor="observaciones">Observaciones:</label>
            <div>
              <div className="input-icon mb-0">
                <span className="input-icon-addon">
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-file"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /></svg>
                </span>
                <textarea className="form-control" placeholder="Descripcion de la orden." name="observaciones" id="observaciones" required={true}>{item.razon}</textarea>
              </div>
              <small className="form-hint pt-0" />
            </div>
          </div>


          <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column mb-2">
            <label className="form-selectgroup-item flex-fill">
              <input type="checkbox" name="s-vencimiento" defaultValue="visa" className="form-selectgroup-input" onChange={(e) => setExpireUse(e.target.checked)} />
              <div className="form-selectgroup-label d-flex align-items-center p-3">
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-stats me-2" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4" /><path d="M18 14v4h4" /><path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M15 3v4" /><path d="M7 3v4" /><path d="M3 11h16" /></svg>
                  <strong>Vencimiento</strong>
                </div>
              </div>
            </label>
          </div>

          <div className={`mb-3${expireUse ? '' : ' hidden'}`}>
            <label className="form-label required" htmlFor="expiration">Vencimiento</label>
            <div>
              <div className="row g-2">
                <div className="col input-icon">
                  <span className="input-icon-addon">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-stats" style={{marginLeft: 10}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4" /><path d="M18 14v4h4" /><path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M15 3v4" /><path d="M7 3v4" /><path d="M3 11h16" /></svg>
                  </span>
                  <input type="date" className="form-control" placeholder={30} name="expire" id="expire" defaultValue={item.vencimiento} />
                </div>
              </div>
              <small className="form-hint pt-0">Si decide colocar el vencimiento, No ponga una fecha mas de 1-2 Meses, Si no queria habilitar esta opcion desabilitela volviendo a presionar 'Vencimiento'.</small>
            </div>
          </div>


          <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column mb-2">
            <label className="form-selectgroup-item flex-fill">
              <input type="checkbox" name="s-vehiculo" defaultValue="visa" className="form-selectgroup-input" onChange={(e) => setCarUse(e.target.checked)} />
              <div className="form-selectgroup-label d-flex align-items-center p-3">
                <div>
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-car me-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" /></svg>
                  <strong>Vehiculo</strong>
                </div>
              </div>
            </label>
          </div>

          <div className={`mb-3${carUse ? '' : ' hidden'}`}>
            <label className="form-label required" htmlFor="expiration">Vehiculo</label>
            <div>
              <div className="row g-2">
                <div className="col input-icon">
                  <span className="input-icon-addon">
                    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-text-caption ms-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 15h16" /><path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M4 20h12" /></svg>
                  </span>
                  <input type="text" placeholder="IXX 999" className="form-control" name="car" id="car" defaultValue={item.vehiculo} />
                </div>
              </div>
              <small className="form-hint pt-0">Utilize siempre la pantente real del vehiculo, Es obligatorio. Coloque la patente y si en la lista le sale presionela asi se autocompletara.</small>
            </div>
          </div>

          
        </div>

        <button type='submit' className="btn btn-primary">
          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-device-floppy me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M14 4l0 4l-6 0l0 -4" /></svg>
          Guardar Cambios
        </button>
      </form>


    </>
  );
};

export default Content
